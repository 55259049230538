:where(.css-dev-only-do-not-override-ph9edi).ant-pagination .ant-pagination-item {
    background-color: #F9FAFA;
    color: $greyColor;

    &.ant-pagination-item-active {
        background: #5FBF24;
        color: white;
        border-color: #5FBF24;
    }
}

:where(.css-dev-only-do-not-override-ph9edi).ant-pagination .ant-pagination-prev,
:where(.css-dev-only-do-not-override-ph9edi).ant-pagination .ant-pagination-next,
:where(.css-dev-only-do-not-override-ph9edi).ant-pagination .ant-pagination-jump-prev,
:where(.css-dev-only-do-not-override-ph9edi).ant-pagination .ant-pagination-jump-next {
    background-color: #F9FAFA;
    color: $greyColor;
}

:where(.css-dev-only-do-not-override-ph9edi).ant-pagination .ant-pagination-prev .ant-pagination-item-link,
:where(.css-dev-only-do-not-override-ph9edi).ant-pagination .ant-pagination-next .ant-pagination-item-link {
    display: flex;
    justify-content: center;
    align-items: center;
}