.financing-card-bg {
    background: #ffffff;
    border: 1px solid #f5f1fe;
    border-radius: 20px;
    margin: auto;
    transition: 0.5s ease;
    min-height: 615px;
    max-height: 615px;

    &.smallCard {
        min-height: auto;
        max-height: auto;

        .financing-card-container {
            padding: 20px;
        }

        .plan-detail-list {
            overflow: scroll;
            height: 9.5rem;

            &::-webkit-scrollbar {
                display: none;
            }
        }
    }

    .subscriptionPrice {
        font-size: 19px;
        color: #899CA8;
        font-weight: 400;

        span {
            color: $primaryColor;
            font-size: 20px;
            font-weight: 600;
        }
    }

    .financing-card-container {
        padding: 20px 20px 80px 20px;
        transition: 0.5s ease;
    }

    .three-dots-bg {
        background: #fafafb;
        box-shadow: 0px 6px 58px rgba(196, 203, 214, 0.103611);
        border-radius: 8px;
        height: 30px;
        width: 30px;
    }

    h3 {
        /* Finance Plan*/
        font-family: "Inter";
        font-style: normal;
        font-weight: 700;
        font-size: 18px;
        line-height: 22px;
        color: #363636;
        margin: 0;
        margin-top: 10px;
    }

    p {
        font-family: "Inter";
        font-style: normal;
        font-weight: 400;
        font-size: 14px;
        line-height: 24px;
        color: #899ca8;
        margin: 0;
        margin-top: 10px;
    }

    hr {
        opacity: 0.1;
        border: 1px solid #899ca8;
    }

    .payment-source {
        /* Payment Source: Third Party */
        font-family: "Inter";
        font-style: normal;
        font-weight: 400;
        font-size: 14px;
        line-height: 24px;
        margin-left: 12px;
        color: #363636;

        span {
            color: $primaryColor;
            font-weight: 600;
        }
    }

    .third-party {
        color: #3b7afe;
        font-size: 14px;
        font-weight: 600;
        font-family: "Inter";
        line-height: 24px;
    }

    .mb_10 {
        margin-bottom: 14px;
        display: flex;

        &:last-child {
            margin: 0;
        }
    }

}

//List View
.listView {
    .financing-card-bg {
        min-height: auto;
        max-height: auto;
    }

    .financing-card-container {
        padding: 20px;
    }

    .financing-card-bg {
        &.smallCard {
            .plan-detail-list {
                height: auto;
            }
        }
    }

    .plan-detail-list {
        display: flex;
        flex-wrap: wrap;


        >* {
            width: 32%;
        }
    }
}