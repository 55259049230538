.file-upload-container {
  position: relative;
}

.file-upload-input {
  position: absolute;
  top: 0;
  left: 0;
  width: 100%;
  height: 100%;
  opacity: 0;
  cursor: pointer;
}
.upload-img-div {
  width: 236px;
  height: 106px;
  object-fit: cover;
  overflow: hidden;
  border-radius: 15px;
  img {
    width: 100%;
    height: 100%;
  }
}

.file-upload-label {
  display: flex;
  flex-direction: row-reverse;
  justify-content: flex-end;
  font-family: "Inter";
  font-style: normal;
  font-weight: 400;
  font-size: 16px !important;
  line-height: 19px;
  color: #899ca8;
  height: auto !important;
  margin-bottom: 15px;

  &::before {
    display: inline-block;
    margin-left: 4px;
    color: #f30003;
    font-size: 14px;
    font-family: SimSun, sans-serif;
    line-height: 1;
    font-weight: bold;
    content: "*";
  }
}

.file-upload-button {
  position: absolute;
  top: 0;
  left: 0;
  width: 100%;
  height: 100%;
  display: flex;
  justify-content: center;
  align-items: center;
  font-weight: bold;
  cursor: pointer;
}

.file-upload-button:hover {
}

.form-btn-div {
  display: flex;
  justify-content: end;
  gap: 10px;
  align-items: center;
}
