.settings-container {
  background: #ffffff;
  border: 1px solid #f5f1fe;
  border-radius: 20px;
}

.settings-body {
  margin: 30px 0;

  .settings-heading {
    font-family: "Inter";
    font-style: normal;
    font-weight: 700;
    font-size: 36px;
    line-height: 44px;
    color: #363636;
    margin-bottom: 30px;
  }

  .settings-bar {
    background: #ffffff;
    border: 1px solid #f5f1fe;
    border-radius: 20px;
    height: 750px;
    border: 1px solid #f5f1fe;
    border-radius: 20px;

    .settings-navigation {
      margin-top: 30px;
      ul {
        text-decoration: none;
        list-style: none;
        padding-left: 20px;

        li {
          margin-top: 22px;

          a {
            text-decoration: none;
            color: #899ca8;
            font-family: "Inter";
            font-style: normal;
            font-weight: 400;
            font-size: 14px;
            line-height: 17px;
            padding-left: 7px;

            .content-nav-bg{
              padding-left: 7px;
            }



            /* identical to box height */

            /* 3 */

            .settings-icon {
              padding-right: 10px;
            }

            &.active {
              color: #3b7afe;
              font-weight: 700;
              transition: 0.2s ease-out;
              text-align: center;


              .content-nav-bg{
                background-color: #3b79fe25;
                width: 220px;
                height: 44px;
                display: flex;
                align-items: center;
                border-radius: 10px;
                padding-left: 7px;

              }




              path {
                fill: #3b7afe;
              }
            }
          }
        }
      }
    }
  }
}

.profile-component {
  margin: 30px 35px;

  .edit-icon {
    svg {
      cursor: pointer;
    }
  }

  .profile-title {
    margin-bottom: 32px;
    .profile-name {
      /* Alex Joe */
      font-family: "Inter";
      font-style: normal;
      font-weight: 700;
      font-size: 16px;
      line-height: 19px;
      color: #121212;
      margin-left: 10px;
    }
  }

  .profile-details {
    .role {
      /* Role: */
      font-family: "Inter";
      font-style: normal;
      font-weight: 700;
      font-size: 14px;
      line-height: 17px;
      color: #899ca8;
      width: 100px;
    }

    .admin {
      /* Admin */
      font-family: "Inter";
      font-style: normal;
      font-weight: 400;
      font-size: 14px;
      line-height: 17px;
      /* identical to box height */

      /* 4 */
      color: #363636;
    }

    .mt_17 {
      margin-top: 17px;
    }
  }
}

.changepassword-component {
  margin: 30px;
  h2 {
    /* Change Password */
    font-family: "Nunito Sans", "Inter";
    font-style: normal;
    font-weight: 700;
    font-size: 22px;
    line-height: 30px;
    color: #121212;
    margin-bottom: 30px;
  }

  .input_setting {
    margin-bottom: 18px;

    .form-label {
      margin-bottom: 7px;
      font-family: "Inter";
      font-style: normal;
      font-weight: 400;
      font-size: 16px;
      color: #899ca8;
      line-height: 19px;
    }

    span#basic-addon2 {
      background: #ffffff;
      border: 1px solid #d8e0f0;
      border-radius: 7px;
      cursor: pointer;
      border-top-left-radius: 0;
      border-bottom-left-radius: 0;
    }

    input {
      box-sizing: border-box;
      background: #ffffff;
      border: 1px solid #d8e0f0;
      border-radius: 7px;
      height: 50px;
      border-right: none;

      &:focus {
        box-shadow: none;
      }
    }
  }

  .save_btn {
    width: 167px;
    height: 52px;
    background: #3b7afe;
    box-shadow: 0px 11px 20px rgba(59, 122, 254, 0.16);
    border-radius: 100px;
  }
}

.deleteaccount-component {
  margin: 30px;

  .deleteaccount-heading {
    /* Delete Account */
    font-family: "Nunito Sans", "Inter";
    font-style: normal;
    font-weight: 700;
    font-size: 22px;
    line-height: 30px;
    color: #121212;
  }

  p {
    font-family: "Nunito Sans", "Inter";
    font-style: normal;
    font-weight: 400;
    font-size: 14px;
    line-height: 24px;
    color: #989898;
    margin: 20px 0 25px 0;
  }

  .input_setting {
    margin-bottom: 18px;

    .form-label {
      margin-bottom: 7px;
      font-family: "Inter";
      font-style: normal;
      font-weight: 400;
      font-size: 16px;
      color: #899ca8;
      line-height: 19px;
    }

    span#basic-addon2 {
      background: #ffffff;
      border: 1px solid #d8e0f0;
      border-radius: 7px;
      cursor: pointer;
      border-top-left-radius: 0;
      border-bottom-left-radius: 0;
    }

    input {
      box-sizing: border-box;
      background: #ffffff;
      border: 1px solid #d8e0f0;
      border-radius: 7px;
      height: 50px;
      border-right: none;

      &:focus {
        box-shadow: none;
      }
    }
  }

  .save_btn {
    width: 167px;
    height: 52px;
    background: #3b7afe;
    box-shadow: 0px 11px 20px rgba(59, 122, 254, 0.16);
    border-radius: 100px;
  }
}

// Ez Plan Dashboard

.ez-statistics-container {
  background: #ffffff;
  border: 1px solid #f5f1fe;
  border-radius: 20px;
  padding: 22px 30px;

  img {
    min-width: 48px;
  }

  .heading {
    font-family: "Inter";
    font-style: normal;
    font-weight: 600;
    font-size: 18px;
    line-height: 22px;
    color: #363636;
    margin-bottom: 32px;
  }

  h3 {
    font-family: "Inter";
    font-style: normal;
    font-weight: 600;
    font-size: 18px;
    line-height: 22px;
    color: #363636;
    margin-bottom: 0;
  }
  p {
    font-family: "Inter";
    font-style: normal;
    font-weight: 400;
    font-size: 12px;
    line-height: 18px;
    color: #899ca8;
  }
  .ml_20 {
    margin-left: 20px;
  }
}

.ez-graph-container {
  background: #ffffff;
  border: 1px solid #f5f1fe;
  border-radius: 20px;
  padding: 30px;
  margin-top: 25px;

  h3 {
    background: #ffffff;
    border: 1px solid #f5f1fe;
    border-radius: 20px;
  }

  .graph-section {
    padding: 30px;
  }
}

//  Ez Pay Dashboard

.ez-pay-dashboard {
  button:not(:disabled) {
    cursor: pointer;
    background: #ffffff;
    border: none;
    text-align: right;
    height: 48px;
    padding: 0 10px;
    border-radius: 14px;
    font-family: "Inter";
    font-style: normal;
    font-weight: 400;
    font-size: 15px;
    line-height: 24px;
    color: #363636;
  }

  .gross-sales-bg {
    background: #ffffff;
    border: 1px solid #f5f1fe;
    border-radius: 20px;
    min-height: 217px;

    h3 {
      font-family: "Inter";
      font-style: normal;
      font-weight: 400;
      font-size: 14px;
      line-height: 23px;
      color: #899ca8;
      margin: 16px 0 7px 19px;
    }

    h2 {
      font-family: "Inter";
      font-style: normal;
      font-weight: 600;
      font-size: 20px;
      line-height: 24px;
      color: #363636;
      margin-left: 20px;
    }

    canvas {
      padding: 0 10px;
    }
  }

  .revenue-bg {
    background: #ffffff;
    border: 1px solid #f5f1fe;
    border-radius: 20px;
    min-height: 377px;
    padding: 30px;

    .form-select {
      background-color: #f6f8fa;
      box-shadow: 0px 6px 58px rgba(196, 203, 214, 0.103611);
      border-radius: 10px;
      font-family: "Inter";
      font-style: normal;
      font-weight: 600;
      font-size: 12px;
      line-height: 24px;
      color: #363636;
      margin-bottom: 10px;


      &:focus {
        border: none;
      }
    }

    h2 {
      font-family: "Inter";
      font-style: normal;
      font-weight: 700;
      font-size: 22px;
      line-height: 30px;
      color: #0a1629;
      margin-bottom: 30px;
    }

  }

  .earning-bg {
    background: #ffffff;
    border: 1px solid #f5f1fe;
    border-radius: 20px;
    min-height: 163px;
    margin-top: 32px;
    padding: 25px;


    h2 {
      font-family: "Inter";
      font-style: normal;
      font-weight: 600;
      font-size: 15.2528px;
      line-height: 24px;
      color: #363636;
      margin-bottom: 0;
    }
    p {
      font-family: "Inter";
      font-style: normal;
      font-weight: 400;
      font-size: 12.2022px;
      line-height: 18px;
      color: #899ca8;
      margin-top: 12px;
      margin-bottom: 0;
    }
  }

  .plans-graph-container {
    background: #ffffff;
    border: 1px solid #f5f1fe;
    border-radius: 20px;
    margin-top: 30px;
    min-height: 354px;
    padding: 32px;
    
    .stacked-chart{
      margin-top: 30px;
    }

    .form-select {
      background-color: #f6f8fa;
      box-shadow: 0px 6px 58px rgba(196, 203, 214, 0.103611);
      border-radius: 10px;
      font-family: "Inter";
      font-style: normal;
      font-weight: 600;
      font-size: 12px;
      line-height: 24px;
      color: #363636;
      margin-bottom: 10px;

      &:focus {
        border: none;
      }
    }
  }
  .plans {
    font-family: "Inter";
    font-style: normal;
    font-weight: 600;
    font-size: 22px;
    line-height: 27px;
    color: #363636;
    margin-bottom: 30px;
  }
  .ezpay-table {
    background: #ffffff;
    border: 1px solid #f5f1fe;
    border-radius: 20px;
    min-height: 355px;
    margin-top: 30px;
    padding-top: 30px;
    .table-padding {
      border: none;
      padding: 0 30px 0 30px;
      .membership-plan {
        font-family: "Inter";
        font-style: normal;
        font-weight: 600;
        font-size: 24px;
        line-height: 29px;
        color: #363636;
      }

      .view-membership-btn {
        background: #ffffff;
        border: 0.5px solid #5fbf24;
        box-shadow: 0px 6px 58px rgba(196, 203, 214, 0.3036);
        border-radius: 14px;
        color: #5fbf24;
        padding: 13px;

        &:focus {
          box-shadow: 0px 6px 58px rgba(196, 203, 214, 0.3036);
          background: #5fbf24;
          color: white;
        }
        &:hover {
          box-shadow: 0px 6px 58px rgba(196, 203, 214, 0.3036);
          background: #5fbf24;
          color: white;
          transition: 0.3s;
        }
      }
    }
  }
}

.proposal-profile {
  .default-heading {
    font-family: "Inter";
    font-style: normal;
    font-weight: 700;
    font-size: 20px;
    line-height: 24px;
    color: #363636;
  }

  .add-new-btn {
    padding: 15px 41px;
  }

  .contract {
    background: #ffffff;
    border: 1px solid #f5f1fe;
    border-radius: 20px;
    padding: 20px;
    min-height: 129px;
    .contract-heading {
      font-family: "Inter";
      font-style: normal;
      font-weight: 700;
      font-size: 20px;
      line-height: 24px;
      color: #363636;
    }
  }

  .status-container {
    .status {
      font-family: "Inter";
      font-style: normal;
      font-weight: 400;
      font-size: 14px;
      line-height: 17px;
      color: #899ca8;
      margin-top: 20px;
      margin-bottom: 27px;
    }
    .status-response {
      font-family: "Inter";
      font-style: normal;
      font-weight: 400;
      font-size: 14px;
      line-height: 17px;
      color: #363636;
    }
  }

  .proposal-payment-plan {
    .plan-no {
      font-family: "Inter";
      font-style: normal;
      font-weight: 500;
      font-size: 14px;
      line-height: 17px;
      color: #363636;
      margin-top: 5px;
    }
    .plan-desc {
      font-family: "Inter";
      font-style: normal;
      font-weight: 400;
      font-size: 14px;
      line-height: 24px;
      color: #899ca8;
      margin-top: 10px;
    }
    .plan-conditions {
      /* Payment Source: Split */
      font-family: "Inter";
      font-style: normal;
      font-weight: 400;
      font-size: 14px;
      line-height: 24px;
      color: #363636;
      padding-left: 12px;
    }
    .payment-condition-answer {
      font-family: "Inter";
      font-style: normal;
      font-weight: 600;
      font-size: 14px;
      line-height: 24px;
      color: #3b7afe;
      padding-left: 8px;
    }
    .mt_10 {
      margin-top: 10px;
    }
  }

  .cost-of-service {
    /* Cost of Services */
    font-family: "Inter";
    font-style: normal;
    font-weight: 700;
    font-size: 20px;
    line-height: 24px;
    color: #363636;
    margin-top: 30px;
    margin-bottom: 20px;
  }

  .cost-of-service-container {
    background: #ffffff;
    border: 1px solid #f5f1fe;
    border-radius: 20px;
    min-height: 627px;
  }

  .financing-plan-container {
    .plan-no {
      font-family: "Inter";
      font-style: normal;
      font-weight: 500;
      font-size: 14px;
      line-height: 17px;
      color: #363636;
      margin-top: 5px;
    }
    .plan-desc {
      font-family: "Inter";
      font-style: normal;
      font-weight: 400;
      font-size: 14px;
      line-height: 24px;
      color: #899ca8;
      margin-top: 10px;
    }
    .plan-conditions {
      /* Payment Source: Split */
      font-family: "Inter";
      font-style: normal;
      font-weight: 400;
      font-size: 14px;
      line-height: 24px;
      color: #363636;
    }
    .payment-condition-answer {
      font-family: "Inter";
      font-style: normal;
      font-weight: 600;
      font-size: 14px;
      line-height: 24px;
      color: #3b7afe;
      padding-left: 8px;
    }
    .mt_10 {
      margin-top: 10px;
    }
    .pt_12 {
      padding-left: 12px;
    }
  }
}

// Fee Schedule
.fee-schedule-upload-btn {
  border: 1px solid #3b7afe;
  filter: drop-shadow(0px 11px 20px rgba(59, 122, 254, 0.16));
  border-radius: 100px;
  background:#f5f1fe;
  color: #3b7afe;
  padding: 13px 19px;
  font-size: 18px;
  text-align: center;
  font-weight: 500;
  &:hover,&:focus{
    background: #3b7afe;
    border: 1px solid #f5f1fe;
    color: #ffffff;
    outline: none;
    box-shadow: none;
    path{
      fill: #f5f1fe;
    }
  }
}



