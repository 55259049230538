.ant-form {
    font-family: 'Inter' !important;
}

.ant-select-dropdown {
    z-index: 9999;
    position: relative;
    max-width: fit-content;

}

.ant-form-item {

    //Form Label Styling
    .ant-form-item-label {
        padding: 0;
        padding-bottom: 11px;

        label {
            display: flex;
            flex-direction: row-reverse;
            justify-content: flex-end;
            font-family: 'Inter';
            font-style: normal;
            font-weight: 400;
            font-size: 16px;
            line-height: 19px;
            color: #899CA8;
            height: auto !important;

        }

    }

    textarea {
        min-height: 93px;

        &::-webkit-scrollbar {
            width: 4px;
            height: 4px;
            transition: 0.5s ease;
            /* width of the entire scrollbar */
        }

        &::-webkit-scrollbar-track {
            background: #f3f5f6;
            /* color of the tracking area */
            height: 1px;
            padding: 1px;
            width: 5px;
            border-radius: 20px;
        }

        &::-webkit-scrollbar-thumb {
            background-color: #899CAB;
            /* color of the scroll thumb */
            border-radius: 20px;
            /* roundness of the scroll thumb */
            border: 1px solid #899CAB;
            height: 2px;
            width: 10px;
            /* creates padding around scroll thumb */
        }
    }

    //Form input Styling
    .ant-input {
        border-radius: 20px;
        width: 100%;
        height: 52px;
        background: #ffffff;
        border: 1px solid #f5f1fe;
        border-radius: 20px;
        font-weight: 500;
        font-size: 15px;
        color: #363636;

        &:focus {
            border: 1px solid $primaryColor !important;
            box-shadow: none !important;
            outline: none !important;
        }
    }

}

@-webkit-keyframes autofill {

    0%,
    100% {
        color: #666;
        background: transparent;
    }
}

input:-webkit-autofill {
    -webkit-animation-delay: 1s;
    /* Safari support - any positive time runs instantly */
    -webkit-animation-name: autofill;
    -webkit-animation-fill-mode: both;
}

input:-webkit-autofill {
    -webkit-box-shadow: none !important;
    -webkit-background-clip: text !important;
}

// Stylting for hasfeedback attribute
.ant-input-affix-wrapper {
    background: #ffffff;
    border: 1px solid #f5f1fe;
    border-radius: 20px;
    font-weight: 500;
    font-size: 15px;
    color: #363636;
    height: 52px;

    .ant-input {
        height: auto;

        &:focus {
            border: 0 !important;
            box-shadow: none !important;
            outline: none !important;
        }
    }

    &.ant-input-affix-wrapper-focused {
        box-shadow: none;
        border: 1px solid $primaryColor;
    }

    &.ant-input-affix-wrapper-status-error {
        border-color: #ff4d4f;
        box-shadow: none;
    }

}

.validation-error {
    font-size: 14px;
    color: red
}

.ant-input-suffix {
    .ant-form-item {
        .ant-form-item-feedback-icon {
            line-height: 1;
        }
    }

    .anticon-close-circle {
        svg {
            width: 100%;
            height: 100%;
        }
    }
}

.addNewBtn {
    display: flex;
    align-items: center;
    position: absolute;
    top: 0;
    right: 0;
    z-index: 555;

    &:hover {
        opacity: 0.9;
        cursor: pointer;
        z-index: 55;
    }

    svg {
        path {
            fill: $primaryColor;
        }
    }

    p {
        margin-left: 8px !important
    }
}



.financeDropDown {
    .ant-dropdown-menu {
        display: flex;
        flex-flow: column;
        justify-content: center;
        background: #FFFFFF;
        border: 1px solid #F5F1FE;
        border-radius: 20px;
        width: 157px;
        height: auto;
        padding: 10px 0;
        box-shadow: none;
    }
}


.form-dropdown {
    .ant-select-selector {
        border-radius: 20px !important;
    }
}

.ant-picker {
    width: 100%;
    height: 52px;
    background: #ffffff;
    border: 1px solid #f5f1fe;
    border-radius: 20px;
    font-weight: 500;
    font-size: 15px;

    &:hover {
        border: 1px solid $primaryColor !important;
        box-shadow: none !important;
        outline: none !important;
    }

    &.ant-picker-focused {
        border: 1px solid $primaryColor !important;
        box-shadow: none !important;
        outline: none !important;
    }

    .ant-picker-input>input {
        font-size: 15px;
        font-weight: 500;
    }
}

.image-holder {
    width: 64px;
    height: 64px;
    overflow: hidden;
    border-radius: 100%;

    &:hover {
        cursor: pointer;
    }
}

.profile-title {
    display: flex;
    align-items: center;
}

.upload-img-box {
    width: 64px;
    height: 64px;
    position: relative;

    &:before {
        content: '';
        position: absolute;
        top: 0;
        left: 0;
        width: 100%;
        height: 100%;
        background: rgba(0, 0, 0, 0.2);
    }

    .cameraIcon {
        position: absolute;
        top: 50%;
        left: 50%;
        transform: translate(-50%, -50%);
    }

    img {
        width: 100%;
        height: 100%;
        border-radius: 100%;
    }
}

.anticon-close-circle {
    svg {
        width: 25px;
        height: 25px;
    }
}

.ant-select-selection-placeholder {
    font-weight: 500;
    font-size: 16px;
    color: #899CA8;
}

:where(.css-dev-only-do-not-override-ph9edi)[class^="ant-form"] [class^="ant-form"]::before,
:where(.css-dev-only-do-not-override-ph9edi)[class*=" ant-form"] [class^="ant-form"]::before,
:where(.css-dev-only-do-not-override-ph9edi)[class^="ant-form"] [class*=" ant-form"]::before,
:where(.css-dev-only-do-not-override-ph9edi)[class*=" ant-form"] [class*=" ant-form"]::before,
:where(.css-dev-only-do-not-override-ph9edi)[class^="ant-form"] [class^="ant-form"]::after,
:where(.css-dev-only-do-not-override-ph9edi)[class*=" ant-form"] [class^="ant-form"]::after,
:where(.css-dev-only-do-not-override-ph9edi)[class^="ant-form"] [class*=" ant-form"]::after,
:where(.css-dev-only-do-not-override-ph9edi)[class*=" ant-form"] [class*=" ant-form"]::after {
    margin-inline-start: 6px;
    font-weight: bold;
}


:where(.css-dev-only-do-not-override-ph9edi).ant-select-multiple.ant-select-lg .ant-select-selection-item {
    font-weight: 500;
    background: rgba(151, 151, 151, 0.1);
    border: 0.5px solid #899CA8;
    border-radius: 10px;
}

:where(.css-dev-only-do-not-override-ph9edi).ant-select-multiple .ant-select-selection-item-content {
    font-size: 14px;
}

.ant-select {
    .ant-select-selection-item {
        font-weight: 500;
        font-size: 16px;
        color: #363636;
    }
}

.ant-select-multiple {
    .ant-select-selection-item-content {
        margin-right: 15px;
    }
}

:where(.css-dev-only-do-not-override-ph9edi).ant-input-lg {
    padding: 7px 17px;
}


//Add labe Icon
.addOnLabel {

    .ant-input-status-error:not(.ant-input-disabled):not(.ant-input-borderless).ant-input:focus,
    .ant-input-status-error:not(.ant-input-disabled):not(.ant-input-borderless).ant-input-focused {

        border-color: #ff4d4f !important;
        box-shadow: none !important;
    }

    .ant-input-group {
        .ant-input {
            border-right: 0 !important;
        }

        .ant-input-group-addon {
            border: 1px solid;
            border-left: 0 !important;
            border-top-right-radius: 20px;
            border-bottom-right-radius: 20px;
            color: #f5f1fe;
            background: transparent;
        }

        &:active,
        &:hover,
        &:focus {

            .ant-input {
                border-right: 0 !important;
                border-color: $primaryColor;
            }

            .ant-input-group-addon {
                border-color: $primaryColor;
                color: $primaryColor;
            }
        }
    }

    .ant-input-group-wrapper-status-success {
        .ant-input-group {
            .ant-input-group-addon {
                color: $primaryColor;
            }
        }

    }

    .ant-input-group-wrapper-status-error {
        .ant-input-group {

            &:active,
            &:hover,
            &:focus {
                .ant-input-group-addon {
                    color: #ff4d4f;
                    border-color: #ff4d4f;
                }
            }
        }

        .ant-input-group {
            .ant-input-group-addon {
                color: #ff4d4f;
            }
        }
    }

}


//Error 
.ant-form-item .ant-form-item-explain-error {
    font-size: 12px;
}

//Switch
.ant-switch {
    &.ant-switch-checked {
        background: #5FBF24;
    }
}

.ant-switch.ant-switch-checked:hover:not(.ant-switch-disabled) {
    background: #5FBF24;
}

.popup-menu {
    z-index: 55555;
}

//Group Slide bar
.ant-input-group.ant-input-group-compact {
    display: flex;
    flex-flow: column;
}

.ant-space-compact {
    display: flex;
    flex-flow: column;
}

//Radio Button
.ant-radio-wrapper {
    display: inline-flex;
    align-items: center;

    span {
        color: #363636;
        font-size: 14px;
    }

    .ant-radio-inner {
        width: 24px;
        height: 24px;
    }

    .ant-radio-checked {
        .ant-radio-inner {
            background-color: white;

            &::after {
                transform: scale(0.8);
                background-color: $primaryColor;
            }
        }
    }
}

//Slider Bar    
.ant-slider {
    .ant-slider-rail {
        background-color: #F6F6F6;
    }

    .ant-slider-track {
        background-color: transparent;
    }

    &:hover {
        .ant-slider-track {
            background-color: transparent;
        }

        .ant-slider-handle {
            box-shadow: -5px 5px 17px 3px $primaryColor;

            &:before {
                inset-inline-start: -1px;
                inset-block-start: -1px;
                width: 12px;
                height: 12px;
            }

            &:after {
                box-shadow: 0 0 0 5px #1677ff;
                width: 10px;
                height: 10px;
                inset-inline-start: -1px;
                inset-block-start: -1px;
            }

        }
    }

    .ant-slider-handle {
        box-shadow: -2.03371px 2.03371px 6.10112px rgba(59, 122, 254, 0.33);

        &:focus,
        &:active,
        &:hover {

            &:before {
                inset-inline-start: -1px;
                inset-block-start: -1px;
                width: 12px;
                height: 12px;
            }

            &:after {
                box-shadow: 0 0 0 5px #1677ff;
                width: 10px;
                height: 10px;
                inset-inline-start: -1px;
                inset-block-start: -1px;
            }

        }

        &:before {
            inset-inline-start: -1px;
            inset-block-start: -1px;
            width: 12px;
            height: 12px;
        }

        &:after {
            box-shadow: 0 0 0 5px #1677ff;
            width: 10px;
            height: 10px;
            inset-inline-start: -1px;
            inset-block-start: -1px;
        }
    }
}

.sliderMaxValue {
    font-weight: 400;
    font-size: 12px;
    line-height: 15px;
    color: #899CA8;
    text-align: right;
}

.sliderValueBar {
    display: flex;
    justify-content: space-between;

    p {
        font-weight: 500;
        font-size: 14px !important;
        line-height: 1;
        color: #899CA8;
        margin: 0;
    }
}



//Slider ToolTip
.ant-slider-tooltip {

    .ant-tooltip-arrow {

        &::before,
        &::after {
            content: normal;
        }
    }

    .ant-tooltip-content {
        background: none;
    }

    .ant-tooltip-inner {
        background: transparent;
        box-shadow: none;
        border-radius: 0;
        font-style: normal;
        font-weight: 600;
        font-size: 14px;
        text-align: center;
        color: #363636;
        padding: 0;
    }
}

//Tooltip
// .ant-slider-tooltip {
//     position: absolute;
//     transform: translate(-50%, -100%);
//   }


.custom-form-btn {
    display: flex;
    justify-content: space-between;
    align-items: center;
    margin-bottom: 7px;

    .addNewBtn {
        position: relative;
    }
}

.locationDropdownMenu {
    &.ant-select-dropdown {
        .ant-select-item-option {
            background: transparent;

            .ant-select-item-option-content {
                display: flex;
                justify-content: space-between;
                align-items: center;
                font-weight: 400;
                font-size: 14px;
                line-height: 17px;
                color: #7D8592;
            }
        }
    }
}


.locationDropdown {
    position: relative;
    width: 300px;

    &.ant-select-single {
        .ant-select-selector {
            border-radius: 14px !important;
        }

        &.ant-select-show-arrow {
            &:not(:last-child) {
                margin-inline-end: 18px;
            }

            .ant-select-selection-item {
                padding: 0 30px;
                padding-top: 2px;
            }
        }
    }



    .ant-select-arrow {
        position: unset;

        span {
            position: absolute;
            top: 45%;
            right: 10px;
            transform: translate(0, -50%);

            &.locationIconBlue {
                position: absolute;
                top: 50%;
                left: auto;
                transform: translate(0, -50%);
                right: 90%;
            }
        }
    }
}


//Input phone button
.react-tel-input {
    .flag-dropdown {
        width: 60px;
        background: transparent;
        border-radius: 10px;
        border: 1px solid #f5f1fe !important;

        .selected-flag {
            display: flex;
            justify-content: center;
            align-items: center;
            width: 100%;
            height: 100%;
            padding: 0;
            border-radius: 10px !important;

            .flag {
                position: absolute;
                top: 50%;
                margin-top: 0;
                left: 50%;
                transform: translate(-50%, -50%);
            }
        }
    }

    .form-control {
        background: #ffffff;
        border: 1px solid #f5f1fe;
        border-radius: 20px;
        font-weight: 500;
        font-size: 15px;
        color: #363636;
        height: 52px;
        width: 83%;
        padding: 10px;
        margin-left: auto;

        &:focus {
            box-shadow: none;
            outline: none;
        }
    }
}


// Theme Group form Styling


.themeInputGroup {
    .form-label {
        font-weight: 400;
        font-size: 16px;
        line-height: 19px;
        color: $greyColor;

        span {
            font-weight: 600;
            color: #E62E2E;
            margin-left: 5px;
        }
    }

    .form-control {
        padding: .815rem .75rem;
        border: 1px solid #F5F1FE;
        border-radius: 20px;

        &:focus,
        &:hover {
            border: 1px solid $primaryColor;
            transition: all 1s ease-in;
            box-shadow: none;
            outline: none;
        }

        &::placeholder {
            font-size: 12px;
        }

        &.hasError {
            border-color: $red-error !important;
            transition: all 1s ease-in;
        }
    }

    .input-group {
        align-items: stretch;
        display: flex;
        flex-wrap: wrap;
        position: relative;
        width: 100%;

    }

    /*
  * Password input field
  */
    &.passwordInput {
        .input-group {
            border: 1px solid #F5F1FE;
            border-radius: 20px;

            &.hasError {
                border-color: $red-error !important;
                transition: all 1s ease-in;
            }

            &:active,
            &:focus,
            &:hover {
                border: 1px solid $primaryColor;
                transition: all 1s ease-in;
            }

            .form-control {
                border: none;
                box-shadow: none;
                outline: none;
            }

            .btn-togglePassword {
                border: none;
                background: transparent;
                outline: none;
                box-shadow: none;

                @media (max-width: 1366px) {
                    width: 42px;
                    padding: 0;
                }

                svg {
                    width: 100%;
                    height: 100%;

                    @media (max-width: 1366px) {
                        width: 18px;
                    }
                }
            }
        }

    }
}

.form-text {
    font-weight: 400;
    font-size: 9px;
    line-height: 18px;
    color: $red-error;

    @media (max-width: 1600px) {
        font-size: 12px;
    }

    @media (max-width: 1366px) {
        font-size: 10px;
    }
}