.dashboardSidebar {
    background: #ffffff;
    border: 1px solid #f5f1fe;
    border-radius: 24px;
    height: auto;
    padding: 44px 0 60px 0;
    position: relative;

    @media (max-width: 1366px) {
        padding: 22px 0;
    }

    @media (max-width: 767px) {
        position: fixed;
        top: 0;
        left: auto;
        right: 100%;
        border-radius: 0;
        width: 100%;
        z-index: 999999;
        height: 100%;
        transition: 0.5s ease;
        overflow: scroll;

        &.active {
            right: 0;
        }
    }

    .crossMenu {
        position: absolute;
        top: 15px;
        right: 45px;
    }

    &.settingSideBar {
        padding: 30px 0;
        background-color: $theme-white;
        box-shadow: 0px 6px 58px rgba(196, 203, 214, 0.103611);
        border-radius: 24px;
        border: 0;
        z-index: 0;

        @media (max-width: 767px) {
            position: relative;
            top: auto;
            left: auto;
            right: auto;
            margin-bottom: 20px;
        }
    }

    .logo-dashboard {
        display: flex;
        padding-bottom: 40px;
        padding-left: 30px;
        width: 164.1px;

        @media (max-width: 1366px) {
            padding-bottom: 30px;
            width: 125px;
        }
    }

    ul {
        text-decoration: none;
        list-style: none;
        padding-left: 0;

        li {
            text-decoration: none;
            position: relative;
            padding: 0 16px 0 5px;

            &:hover {
                .link-item {
                    &.has-children {
                        .nav-child-list {
                            opacity: 1;
                            visibility: visible;
                            display: block;
                        }
                    }
                }

            }

            &.hasChilList {
                position: relative;

                .plusIcon {
                    display: block;
                    position: absolute;
                    top: 9px;
                    right: 30px;
                }



            }

            .plusIcon {
                width: 13%;
                display: block;
                z-index: 200;
                position: relative;
                display: none;

                svg {
                    width: 25px;
                    height: 25px;
                }
            }

            &:hover,
            &:focus {
                .link-item {
                    &.has-children {
                        &::after {
                            border-left-color: $primaryColor;
                        }
                    }
                }
            }

            &:not(:last-child) {
                margin-bottom: 2px;
            }

            .link-item {
                text-decoration: none;
                color: $greyColor;

                font-style: normal;
                font-weight: 400;
                font-size: 14px;
                line-height: 19px;
                cursor: pointer;
                padding: 11px 0 11px 15px;
                border-radius: 10px;

                // @media (max-width: 1366px) {
                //     font-size: 12px;
                //     padding: 9px;
                // }

                @media (max-width: 992px) {
                    padding: 5px;
                    font-size: 10px;
                }

                @media (max-width: 767px) {
                    font-size: 12px;
                    padding: 9px;
                }



                .nav-bg {
                    display: flex;
                    align-items: center;
                    border-radius: 10px;

                    @media (max-width: 767px) {
                        flex-wrap: wrap;

                        span {
                            &:first-child {
                                width: 7%;
                            }

                            width: 80%;
                            text-align: left;
                        }

                        //** Plus icon for mobile */

                    }


                }

                .settings-icon {
                    padding-right: 10px;
                }

                &.active,
                &:hover,
                &:focus {
                    color: $primaryColor;
                    background-color: rgba($primaryColor, 0.10);
                    font-weight: 700;
                    transition: 0.2s ease-out;



                    path {
                        fill: #3b7afe;
                    }

                    ::after {
                        content: " ";
                        position: absolute;
                        right: 0;
                        bottom: 2px;
                        margin: 0;
                        padding: 0;
                        width: 3.82px;
                        height: 44px;
                        border-radius: 2px;
                        background-color: $primaryColor;
                        border-radius: 50px;

                        @media (max-width: 767px) {
                            content: '';
                        }
                    }
                }

                &.has-children {
                    &::after {
                        content: '';
                        position: absolute;
                        top: 50%;
                        right: 30px;
                        transform: translate(0, -50%);
                        border-radius: 10px;
                        width: 0;
                        height: 0;
                        border-top: 5px solid transparent;
                        border-bottom: 5px solid transparent;
                        border-left: 5px solid transparent;
                        transition: 0.5s ease;

                        @media (max-width: 767px) {
                            content: normal;
                        }
                    }

                    .nav-child-list {
                        background: $theme-white;
                        border-radius: 10px;
                        position: absolute;
                        top: 0;
                        right: 0;
                        left: 100%;
                        opacity: 0;
                        visibility: visible;
                        width: 220px;
                        height: auto;
                        transition: 0.3s ease;
                        z-index: 100;
                        display: none;
                        padding: 22px 20px;
                        box-shadow: 0px 15px 20px 3px hsl(0deg 0% 0% / 10%), 0 4px 11px hsl(0deg 0% 0% / 10%);

                        @media (max-width: 767px) {
                            opacity: 1;
                            visibility: visible;
                            left: auto;
                            top: auto;
                            position: relative;
                            display: block;
                            padding: 0;
                            border-radius: 0;
                            box-shadow: none;
                            background: transparent;
                            list-style: disc;
                            padding-left: 15%;
                            width: 100%;
                            height: 0;
                            overflow: scroll;

                            &.active {
                                height: 85px;
                            }
                        }

                        .nav-child-item {
                            padding: 0;

                            &:last-child {
                                a {
                                    margin-bottom: 0;
                                }
                            }

                            a {


                                font-style: normal;
                                font-weight: 400;
                                font-size: 14px;
                                line-height: 17px;
                                color: $greyColor;
                                margin-bottom: 20px;
                                transition: all 0.5s ease;

                                @media (max-width: 767px) {
                                    margin-bottom: 4px;
                                    font-size: 12px;
                                }

                                &:hover,
                                &:focus,
                                &.active {
                                    color: $primaryColor;
                                    font-weight: 700;
                                    font-size: 14px;
                                    -webkit-text-wrap: none;
                                }
                            }

                        }


                        .p_28 {
                            padding-top: 28px;
                        }

                        &:after {
                            content: normal !important;
                        }

                        .nav-child-item {
                            &:after {
                                content: normal;
                            }
                        }

                        a:after {
                            content: normal !important;
                        }
                    }



                }
            }

            .non-active {
                cursor: pointer;
                background: transparent !important;

                path {
                    fill: #899ca8 !important;
                }

                ::after {
                    display: none !important;
                }
            }
        }
    }



}