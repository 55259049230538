.dashboard-body {
  margin: 30px 0;

  .dashboard-heading {
    font-family: "Inter";
    font-style: normal;
    font-weight: 700;
    font-size: 36px;
    line-height: 44px;
    color: #363636;
  }

  .statistics-section {
    background: #ffffff;
    border: 1px solid #f5f1fe;
    border-radius: 20px;
    padding: 30px 0 40px 0;

    .stats {
      font-family: "Inter";
      font-style: normal;
      font-weight: 600;
      font-size: 22px;
      line-height: 22px;
      color: #363636;
      padding: 20px 0 0 30px;
    }


  }



  .patient-section {
    background: #ffffff;
    border: 1px solid #f5f1fe;
    border-radius: 20px;
    padding: 30px 0;

    .patients {
      font-family: "Inter";
      font-style: normal;
      font-weight: 600;
      font-size: 22px;
      line-height: 22px;
      color: #363636;
      padding: 20px 0 0 30px;
    }

    canvas {
      height: 60% !important;
      width: 90% !important;
      margin: auto;
    }
  }

  // Care Providers

  .care-section {
    background: #ffffff;
    border: 1px solid #f5f1fe;
    border-radius: 20px;
    padding: 30px 10px 40px 10px;
    min-height: 430px;

    canvas {
      width: 90% !important;
      height: 200px !important;
      margin: auto;
      margin-top: 50px;
    }

    .care {
      font-family: "Inter";
      font-style: normal;
      font-weight: 600;
      font-size: 22px;
      line-height: 27px;
      color: #363636;
      padding-left: 30px;
    }

    [type="button"]:not(:disabled),
    [type="reset"]:not(:disabled),
    [type="submit"]:not(:disabled),
    button:not(:disabled) {
      cursor: pointer;
      background: #f6f8fa;
      border: none;
      color: #363636;
      font-size: 12px;
      font-weight: 600;
      background: #f6f8fa;
      box-shadow: 0px 6px 58px rgba(196, 203, 214, 0.103611);
      border-radius: 10px;
      height: 38px;
    }
  }

  // Plan Section

  .plan-section {
    background: #ffffff;
    border: 1px solid #f5f1fe;
    border-radius: 20px;
    padding: 30px 10px 40px 10px;
    min-height: 430px;

    canvas {
      max-width: 90% !important;
      max-height: 100% !important;
      margin: auto;
    }

    .care {
      font-family: "Inter";
      font-style: normal;
      font-weight: 600;
      font-size: 22px;
      line-height: 27px;
      color: #363636;
      padding-left: 30px;
    }

    [type="button"]:not(:disabled),
    [type="reset"]:not(:disabled),
    [type="submit"]:not(:disabled),
    button:not(:disabled) {
      cursor: pointer;
      background: #f6f8fa;
      border: none;
      color: #363636;
      font-size: 12px;
      font-weight: 600;
      background: #f6f8fa;
      box-shadow: 0px 6px 58px rgba(196, 203, 214, 0.103611);
      border-radius: 10px;
      height: 38px;
    }
  }
}

.dashboard-sidebar {
  background: #ffffff;
  border: 1px solid #f5f1fe;
  border-radius: 20px;
  min-height: 900px;

  .ezfi-logo-dashboard {
    margin-top: 20px;
    margin-bottom: 30px;
    margin: 20px 0 30px 24px;
    width: 122px;
  }

  ul {
    li {
      padding: 0 14px 0 7px;
      position: relative;

      a {
        padding: 11px 0 11px 15px;
        display: flex;
        border-radius: 12px;


        &.active {
          background-color: rgba(18, 106, 223, .1);
          color: #126adf;

          &::after {
            content: " ";
            position: absolute;
            background-color: #126adf;
            border-radius: 2px;
            border-radius: 50px;
            bottom: 2px;
            height: 44px;
            margin: 0;
            padding: 0;
            right: 0;
            width: 3.82px;
          }
        }


      }
    }
  }
}

.all-section-top-gap {
  margin-top: 30px;
}

// Graph Component Styling

// Dashboard Notification Popup Css Starts Here
.notification-modal {
  .modal-heading {
    font-family: "Inter";
    font-style: normal;
    font-weight: 700;
    font-size: 22px;
    line-height: 27px;
    color: #000000;
    padding: 10px !important;
  }

  .modal-content {
    border-radius: 15px;
    width: 100% !important;
  }

  .modal-dialog {
    margin-right: 250px;
    margin-top: 90px;
  }

  .modal-body {
    .header {
      h6 {
        font-weight: 500;

        span {
          font-size: 18px;
          font-weight: 500;
        }
      }

      .btns-time {
        cursor: pointer;
        border-radius: 50%;
        width: 25px;
        height: 25px;
      }
    }

    .notification-container {
      height: calc(100vh - 235px);
      overflow-y: scroll;

      &::-webkit-scrollbar {
        // width: 3px;
        display: none;
      }
    }
  }
}

.LineGraph {
  button:not(:disabled) {
    cursor: pointer;
    background: #f6f8fa;
    border: none;
    text-align: right;
    height: 48px;
    padding: 0 10px;
    border-radius: 14px;
    font-family: "Inter";
    font-style: normal;
    font-weight: 400;
    font-size: 15px;
    line-height: 24px;
    color: #363636;
  }
}

// Dashboard Notification Popup Css Ends Here

@media (max-width: 900px) {
  .dashboard-body {
    .css-o9k5xi-MuiInputBase-root-MuiOutlinedInput-root {
      width: 300px !important;
    }
  }
}

// Top Bar Styling
// .top-bar {
//   .css-o9k5xi-MuiInputBase-root-MuiOutlinedInput-root {
//     width: 400px;
//     height: 50px;
//     margin-bottom: 20px;
//     border-radius: 14px;
//     border: 1px solid #f5f1fe !important;
//     background: white;
//   }

//   .MuiOutlinedInput-notchedOutline {
//     border-color: white;
//   }

//   .search-box {
//     .Mui-focused {
//       .MuiOutlinedInput-notchedOutline {
//         border-color: #3b7afe;
//       }
//     }
//   }

//   .notification-box {
//     background: #ffffff;
//     border: 1px solid #f5f1fe;
//     border-radius: 12px;
//     width: 50px;
//     height: 50px;
//     cursor: pointer;

//     svg {
//       margin: 14px 12px 0 0;
//     }

//     &:hover {
//       background-color: #3b7afe;
//       transition: 0.5s;

//       path {
//         fill: white;
//       }
//     }
//   }

//   .profile-box {
//     background-color: white;
//     border: 1px solid #f5f1fe;
//     border-radius: 12px;
//     height: 50px;
//     width: 200px;
//     margin-left: 10px;
//     cursor: pointer;

//     .profile-img {
//       margin: 6px 7px;
//     }

//     .profile-name {
//       /* Evan Yates */

//       font-family: "Inter";
//       font-style: normal;
//       font-weight: 600;
//       font-size: 16px;
//       line-height: 24px;
//       color: #363636;
//       margin: 10px 0;
//     }

//     &:hover {
//       background-color: #3b7afe;
//       color: white;
//       transition: 0.3s ease-in;

//       path {
//         fill: white;
//         transition: 0.3s ease-in;
//       }

//       .profile-name {
//         color: white;
//         transition: 0.3s ease-out;
//       }
//     }

//     &.active {
//       background-color: #3b7afe;
//       color: white;

//       path {
//         fill: white;
//         transition: 0.3s ease-in;
//       }

//       .profile-name {
//         color: white;
//         transition: 0.3s ease-out;
//       }
//     }
//   }

//   .right-section {
//     .dropdown-toggle::after {
//       display: none !important;
//     }

//     .dropdown-toggle.btn.btn-primary {
//       background: none !important;
//       border: none;
//       padding: 0;
//       margin: 0;
//       width: 200px;
//       height: 50px;
//     }

//     button#dropdown-menu-align-end:focus {
//       /* outline: none!important; */
//       box-shadow: none !important;
//     }

//     .dropdown-link {
//       font-family: "Inter" !important;
//       font-style: normal;
//       font-weight: 400;
//       font-size: 14px;
//       line-height: 17px;
//       color: #363636 !important;
//       text-decoration: none;
//     }
//   }
// }

// User Management Filter Button
.user-filter-btn {
  color: black;

  button#dropdown-basic-button {
    background: #ffffff;
    box-shadow: 0px 6px 58px rgba(196, 203, 214, 0.103611);
    border-radius: 14px;
    min-width: 166px;
    min-height: 52px;
    border: none;
    text-align: left;
    position: relative;

  }

  .dropdown-toggle::after {
    display: inline-block;
    margin-left: 0.255em;
    vertical-align: 0.255em;
    content: "";
    background-image: url("/src/assets/images/arrow.png") !important;
    border-top: 0.3em solid;
    border-right: 0.3em solid transparent;
    border-bottom: 0;
    border-left: 0.3em solid transparent;
    color: #989898;
    position: absolute;
    right: 20px;
    top: 45%;

  }
}



// Validations

.validations {
  color: red !important;
  margin-top: 3px;
  margin-left: 5px;
  display: inherit;
}

.validation-margin form>div+div {
  margin-top: 18px;
}


/*======================  Dashboard CSS ===================*/
.stats-box {
  padding: 24px 30px;
  background-color: #fff;
  border: 1px solid #F5F1FE;
  border-radius: 20px;
  margin-bottom: 30px;
}



.dashboardLayoutContainer {
  padding: 20px 30px;
}

.icon-box {
  p {
    font-size: 12px;
    color: #899ca8;
  }
}

.treatment-wrapper {
  height: 100%;
}

.terminate-box {
  display: flex;
  align-items: center;
  justify-content: center;
  flex-flow: column;
  height: 100%;
  width: 100%;

}