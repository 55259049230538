// Content Management Scss

.content-management {
  padding: 37px 30px;
  text-align: left;
  h2 {
    font-family: "Inter";
    font-style: normal;
    font-weight: 600;
    font-size: 22px;
    line-height: 27px;
    color: #121212;
    padding-top: 10px;
  }

  .terms {
    font-family: "Inter";
    font-style: normal;
    font-weight: 400;
    font-size: 14px;
    line-height: 24px;
    color: #121212;
  }

  .mt_10 {
    margin-top: 10px;
  }

  .mt_30 {
    margin-top: 25px;
  }
}

.faq-accordion {
  margin-top: 20px;

  .accordion {
    border: none;
    button {
      background: none;
      border: none;
      font-family: "Inter";
      font-style: normal;
      font-weight: 500;
      font-size: 16px;
      line-height: 19px;
      color: #121212;
      border-bottom: 0;

      &:focus,
      .active {
        border: none;
        box-shadow: none;
        outline: none;
      }
    }
    .accordion-item {
      border: none;
    }

    .accordion-body {
      font-family: "Inter";
      font-style: normal;
      font-weight: 400;
      font-size: 14px;
      line-height: 24px;
      color: #989898;
      border: none;
    }
    .accordion-button::after {
      background-image: url("/src/assets/images/arrow.png");
      background-size: 80% !important;
    }
  }
}

// Membership Plan

.membership-bg {
  background: #ffffff;
  border: 1px solid #f5f1fe;
  border-radius: 20px;
  .membership-container {
    padding: 12px 30px;
    .membership-label {
      font-family: "Inter";
      font-style: normal;
      font-weight: 400;
      font-size: 16px;
      line-height: 19px;
      margin-top: 18px;
      margin-bottom: 8px;

      /* body copy */
      color: #899ca8;
    }
    label {
      font-family: "Inter";
      font-style: normal;
      font-weight: 400;
      font-size: 14px;
      line-height: 17px;
      color: #363636;
    }
    .form-check-inline {
      margin-right: 2rem;
      margin-top: 10px;
    }
    select.form-select {
      background: #ffffff;
      border: 1px solid #f5f1fe;
      border-radius: 20px;
      &:focus {
        box-shadow: none !important;
      }
    }

    .input-plan {
      input {
        height: 52px;
        background: #ffffff;
        border: 1px solid #f5f1fe;
        border-radius: 20px;
        padding: 15px;
        &:focus {
          box-shadow: none;
        }
      }
    }

    textarea {
      height: 83px;
      background: #ffffff;
      border: 1px solid #f5f1fe;
      border-radius: 20px;
      padding: 15px;

      &:focus {
        box-shadow: none;
      }
    }

    .appointment-btn {
      box-sizing: border-box;
      height: 52px;
      border: 1px solid #3b7afe;
      filter: drop-shadow(0px 11px 20px rgba(59, 122, 254, 0.16));
      border-radius: 100px;
      background: white;
      color: #3b7afe;
      margin-bottom: 20px;
      margin-top: 40px;
    }

    .appointment-container {
      background: #ffffff;
      border: 1px solid #f5f1fe;
      border-radius: 20px;
      padding: 29px 28px;
      width: 100%;

      h3 {
        font-family: "Inter";
        font-style: normal;
        font-weight: 600;
        font-size: 18px;
        line-height: 22px;
        color: #363636;
      }

      .week-title {
        font-family: "Inter";
        font-style: normal;
        font-weight: 400;
        font-size: 14px;
        line-height: 24px;
        color: #899ca8;
        margin-top: 20px;
        margin-bottom: 7px;
      }

      .week-description {
        /* lorem ipsum dolor sit amet, lorem ipsum dolor sit amet, consectetur */
        font-family: "Inter";
        font-style: normal;
        font-weight: 400;
        font-size: 14px;
        line-height: 17px;
        color: #899ca8;
        margin-bottom: 20px;
      }
    }

    .create-plan-btn {
      background: #3b7afe;
      box-shadow: 0px 11px 20px rgba(59, 122, 254, 0.16);
      border-radius: 100px;
      padding: 15px 50px;
      margin-bottom: 44px;
    }
  }
}

// Fees Container
.fees-container {
  background: #ffffff;
  border: 1px solid #f5f1fe;
  border-radius: 20px;
  height: 740px;

  .membership-label {
    font-family: "Inter";
    font-style: normal;
    font-weight: 400;
    font-size: 16px;
    line-height: 19px;
    color: #899ca8;
  }
  .fee-desc {
    font-family: "Inter";
    font-style: normal;
    font-weight: 400;
    font-size: 14px;
    line-height: 17px;
    /* identical to box height */

    color: #363636;
  }

  .fee-padding {
    margin: 30px 30px 0 30px;
    input {
      height: 52px;
      background: #ffffff;
      border: 1px solid #f5f1fe;
      border-radius: 20px;
      &:focus {
        box-shadow: none;
      }
    }

    .mt_plan {
      margin-top: 20px;
    }
  }

  .savings {
    border: 1px solid #97979727;
    border-radius: 10px;
    padding: 13px 18px;
    margin: 14px;
  }

  .savings-text {
    font-family: "Inter";
    font-style: normal;
    font-weight: 500;
    font-size: 16px;
    line-height: 19px;
    color: #363636;
  }

  .savings-answer {
    color: #3b7afe;
    font-family: "Inter";
    font-style: normal;
    font-weight: 500;
    font-size: 16px;
    line-height: 19px;
    padding-left: 30px;
  }
}

// Featured Plans

.featured-plans {
  font-family: "Inter";
  font-style: normal;
  font-weight: 600;
  font-size: 24px;
  line-height: 29px;
  color: #363636;
  margin-top: 10px;
}

.featured-plan-cards {
  background: #ffffff;
  border-radius: 20px;
  padding: 20px;
  margin-top: 30px;

  h3 {
    font-family: "Inter";
    font-style: normal;
    font-weight: 600;
    font-size: 18px;
    line-height: 22px;
    color: #363636;
    margin-top: 10px;
  }

  p {
    /* Retail */
    font-family: "Inter";
    font-style: normal;
    font-weight: 500;
    font-size: 14px;
    line-height: 24px;
    color: #899ca8;
  }

  .patient-saving {
    background: #3b79fe34;
    border-radius: 10px;
    padding: 7px 18px;

    .patient-saving-text {
      /* Patient Saving: $100 */
      font-family: "Inter";
      font-style: normal;
      font-weight: 500;
      font-size: 16px;
      line-height: 19px;
      align-items: center;
      color: #363636;
    }
    .hundred {
      color: #3b7afe;
      font-size: 24px;
      font-weight: 600;
      font-family: "Inter";
    }

    &:hover {
      background: #3b7afe;
      transition: 0.3s ease-in;

      .patient-saving-text {
        color: white;
      }
      .hundred {
        color: white;
      }
    }
  }

  hr {
    border: 1px solid #dadada;
    margin: 0;
    margin-bottom: 20px;
  }

  .card-data-text {
    /* Total Appointments : */
    font-family: "Inter";
    font-style: normal;
    font-weight: 400;
    font-size: 14px;
    line-height: 24px;
    color: #363636;
  }

  .card-data-ans {
    /* 12 */
    font-family: "Inter";
    font-style: normal;
    font-weight: 600;
    font-size: 14px;
    line-height: 24px;
    color: #3b7afe;
    padding-left: 5px;
  }

  .mt_15 {
    margin-top: 15px;
  }
}

.table-padding {
  padding: 30px;
  margin-top: 30px;

  .membership-plan {
    font-family: "Inter";
    font-style: normal;
    font-weight: 600;
    font-size: 24px;
    line-height: 29px;
    color: #363636;
  }

  .view-membership-btn {
    background: #ffffff;
    border: 0.5px solid #5fbf24;
    box-shadow: 0px 6px 58px rgba(196, 203, 214, 0.3036);
    border-radius: 14px;
    color: #5fbf24;
    padding: 13px;

    &:focus {
      box-shadow: 0px 6px 58px rgba(196, 203, 214, 0.3036);
      background: #5fbf24;
      color: white;
    }
    &:hover {
      box-shadow: 0px 6px 58px rgba(196, 203, 214, 0.3036);
      background: #5fbf24;
      color: white;
      transition: 0.3s;
    }
  }
}

.renewal-tracking {
  background: #ffffff;
  border: 1px solid #f5f1fe;
  border-radius: 20px;
  padding: 22px 30px 42px 30px;

  h4 {
    font-family: "Inter";
    font-style: normal;
    font-weight: 600;
    font-size: 18px;
    line-height: 22px;
    color: #363636;
    margin-bottom: 30px;
  }

  .stats-heading {
    font-family: "Inter";
    font-style: normal;
    font-weight: 600;
    font-size: 18px;
    line-height: 22px;
    color: #363636;
  }

  .stats-desc {
    width: 93px;
    font-family: "Inter";
    font-style: normal;
    font-weight: 400;
    font-size: 12px;
    line-height: 18px;
    color: #899ca8;
  }

  img{
    margin-right: 20px;
  }
}




.delete-bg{
width: 48px;
height: 48px;
background: #FFFFFF;
box-shadow: 0px 6px 58px rgba(196, 203, 214, 0.103611);
border-radius: 14px;
display: flex;
align-items: center;
justify-content: center;
cursor: pointer;

&:hover{
  background:#ff5a4e ;
  transition: 0.1s ease-in;
  path{
    fill: #f5f1fe;
  }
}

}