.stepsFormContainer {
    display: flex;
    align-items: center;
    justify-content: center;
    height: 100vh;
    background-color: #fcfcfc;

    .stepsFormWrapper {
        max-width: 95%;
        width: 95%;
        height: 95%;
    }

    .whiteLogo {
        margin-bottom: 50px;
    }

    .sidebarHeading {
        margin-bottom: 55px;
    }

    .stepsBody {
        height: 100%;
        background: white;
        display: flex;
        flex-flow: column;
        align-items: center;
        justify-content: space-between;
        border-radius: 24px;
        overflow: hidden;

        .stepsContent {
            display: flex;
            align-items: center;
            justify-content: flex-start;
            flex-flow: column;
            padding-top: 60px;
            width: 100%;
            height: 100%;

            .stepContentHead {
                display: flex;
                flex-flow: column;
                align-items: center;
            }
        }
    }

    .stepsFooter {
        width: 100%;
        border-top: 1px solid #E4E6E8;
        display: flex;
        align-items: center;
        justify-content: space-between;
        padding: 12px 50px;

        .signupHere {
            display: flex;
            align-items: center;

            p,
            h4 {
                line-height: 1;
                margin: 0;
            }
        }
    }
}


.stepsSider {
    padding: 60px 34px 60px 32%;
    background: $primaryColor;
    border-radius: 24px;
    height: 100%;

    @media (max-width: 1900px) {
        padding: 60px 34px 60px 20%
    }

    .ant-steps {
        .ant-steps-item {
            padding-bottom: 36px;
            opacity: 0.5;

            .ant-steps-item-icon {
                background-color: rgba(255, 255, 255, 0.3);
                border-color: #ffffff;
                border: 2px solid white;

                .ant-steps-icon {
                    display: none;
                }
            }

            &.ant-steps-item-wait {
                .ant-steps-item-icon {
                    background-color: transparent;
                }
            }

            &.ant-steps-item-active {
                opacity: 1;

                .ant-steps-item-title {
                    font-weight: 700;
                }
            }

            .ant-steps-item-title {
                font-weight: 400;
                font-size: 20px;
                line-height: 26px;
                color: white !important;

                @media (max-width: 1366px) {
                    font-size: 15px;
                }
            }

            .ant-steps-item-wait>.ant-steps-item-container>.ant-steps-item-content>.ant-steps-item-title {
                color: #FFFFFF;
                mix-blend-mode: normal;
                opacity: 0.6;
            }
        }

        .ant-steps-item-process>.ant-steps-item-container>.ant-steps-item-tail::after {
            background-color: white;
        }

        .ant-steps-item-finish>.ant-steps-item-container>.ant-steps-item-tail::after {
            background-color: white;
        }

        .ant-steps-vertical {
            width: max-content;
            margin-left: auto;
        }
    }
}