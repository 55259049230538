.offerList {
    p {
        color: #899CA8;
    }

    strong {
        font-weight: 600;
        font-size: 14px;
        color: #363636;
        width: 13%;
        display: block;
    }
}