* {
  padding: 0;
  margin: 0;
  box-sizing: border-box;
}

body {
  background: #fbfbfd;
  font-family: "Inter";
  font-style: normal;
  font-size: 16px;
}

ul,
li {
  margin: 0;
  padding: 0;
  list-style: none;
}

a {
  text-decoration: none;
}

.cursor-pointer:hover {
  cursor: pointer;
}

.ant-table-body {
  max-height: 709px !important;
}

.ant-table-body::-webkit-scrollbar {
  width: 4px;
  height: 4px;
  -webkit-transition: 0.5s ease;
  transition: 0.5s ease;
  display: block;
  /* width of the entire scrollbar */
}

.ant-table-body::-webkit-scrollbar-track {
  background: #f3f5f6;
  /* color of the tracking area */
  height: 1px;
  padding: 1px;
  width: 5px;
  border-radius: 20px;
}

.ant-table-body::-webkit-scrollbar-thumb {
  background-color: #899CAB;
  /* color of the scroll thumb */
  border-radius: 20px;
  /* roundness of the scroll thumb */
  border: 1px solid #899CAB;
  height: 2px;
  width: 10px;
  /* creates padding around scroll thumb */
}

/*
**********************************************
* Fullpage Layout
**********************************************
*/
.fullpageLayout .searchBarContainer {
  width: 100%;
}
.fullpageLayout .fullpageWrapper {
  margin-top: 30px;
  background: #FFFFFF;
  box-shadow: 0px 6px 58px rgba(196, 203, 214, 0.103611);
  border-radius: 24px;
  height: 88%;
  padding: 30px;
}

.fullpageWrapper {
  background: #FFFFFF;
  box-shadow: 0px 6px 58px rgba(196, 203, 214, 0.103611);
  border-radius: 24px;
  height: 88%;
  padding: 30px;
}

body::-webkit-scrollbar {
  display: none;
}

body::-webkit-scrollbar-track {
  background: #899CA8;
}

body::-webkit-scrollbar-thumb {
  background: #363636;
}

body::-webkit-scrollbar-thumb:hover {
  background: #363636;
}

.btn-close {
  background-color: #f9fafa;
  border-radius: 14px;
  color: #121212;
  padding: 12px;
  transition: 0.5s ease;
}

::-webkit-scrollbar {
  display: none;
}

.blue-para-list {
  display: flex;
  align-items: center;
  flex-wrap: wrap;
}
.blue-para-list li p {
  color: #363636;
  font-size: 14px;
}
.blue-para-list li span {
  color: #3B7AFE;
  font-weight: 600;
  font-size: 14px;
}

.pdf-download {
  display: flex;
  align-items: center;
}
.pdf-download:hover {
  cursor: pointer;
}

.decoration-underline {
  text-decoration: underline;
}

h1 {
  font-size: 36px;
}

h2 {
  font-size: 22px;
  font-weight: 600;
}

h3 {
  font-size: 18px;
  font-weight: 600;
}

p {
  font-size: 14px;
}

.greyColor {
  color: #899CA8 !important;
}

.blueColor {
  color: #3B7AFE;
}

.darkGrey {
  color: #363636;
}

.greenColor {
  color: #5FBF24;
}

.lightGrey {
  color: #899CA8;
}

.boldGrey {
  font-weight: 700;
  font-size: 14px;
  color: #899CA8;
}

.black {
  color: #000;
}
.black:hover {
  color: #000;
}

.ant-btn,
.btn-submit {
  min-width: 167px;
  min-height: 52px;
  background: #3b7afe;
  box-shadow: 0px 11px 20px rgba(59, 122, 254, 0.16);
  border-radius: 100px;
  font-weight: 500;
  font-size: 18px;
}

.filter-count {
  font-weight: 400;
  font-size: 14px;
  color: #899ca8;
}

.custom-mb-20 {
  margin-bottom: 20px;
}

.cancelBtn {
  width: auto;
  min-width: auto;
  background: transparent;
  min-height: auto;
  font-size: 18px;
  font-weight: 500;
  font-family: "Inter";
  text-align: center;
}

.dotted-btn {
  display: inline-flex;
  align-items: center;
  justify-content: center;
  padding: 0;
  background: #fafafb;
  border-radius: 8px;
  height: 30px;
  width: 30px;
  border: none;
  cursor: pointer;
  min-width: auto;
  min-height: auto;
  box-shadow: none;
}

.ant-dropdown-menu-title-content:hover {
  color: #3B7AFE;
}
.ant-dropdown-menu-title-content a:hover {
  color: #3B7AFE;
}

.border-btn {
  border-color: #3B7AFE;
  color: #3B7AFE;
  font-weight: 600;
  transition: 0.5s ease;
  background-color: transparent;
  background: transparent;
  box-shadow: none;
}
.border-btn:hover {
  background-color: #3B7AFE;
  color: white !important;
}
.border-btn:hover svg path {
  fill: white;
}

.ant-btn-sm {
  height: 32px;
  min-height: 32px;
  min-width: 133px;
}

.financing-card-bg {
  background: #ffffff;
  border: 1px solid #f5f1fe;
  border-radius: 20px;
  margin: auto;
  transition: 0.5s ease;
  min-height: 615px;
  max-height: 615px;
}
.financing-card-bg.smallCard {
  min-height: auto;
  max-height: auto;
}
.financing-card-bg.smallCard .financing-card-container {
  padding: 20px;
}
.financing-card-bg.smallCard .plan-detail-list {
  overflow: scroll;
  height: 9.5rem;
}
.financing-card-bg.smallCard .plan-detail-list::-webkit-scrollbar {
  display: none;
}
.financing-card-bg .subscriptionPrice {
  font-size: 19px;
  color: #899CA8;
  font-weight: 400;
}
.financing-card-bg .subscriptionPrice span {
  color: #3B7AFE;
  font-size: 20px;
  font-weight: 600;
}
.financing-card-bg .financing-card-container {
  padding: 20px 20px 80px 20px;
  transition: 0.5s ease;
}
.financing-card-bg .three-dots-bg {
  background: #fafafb;
  box-shadow: 0px 6px 58px rgba(196, 203, 214, 0.103611);
  border-radius: 8px;
  height: 30px;
  width: 30px;
}
.financing-card-bg h3 {
  /* Finance Plan*/
  font-family: "Inter";
  font-style: normal;
  font-weight: 700;
  font-size: 18px;
  line-height: 22px;
  color: #363636;
  margin: 0;
  margin-top: 10px;
}
.financing-card-bg p {
  font-family: "Inter";
  font-style: normal;
  font-weight: 400;
  font-size: 14px;
  line-height: 24px;
  color: #899ca8;
  margin: 0;
  margin-top: 10px;
}
.financing-card-bg hr {
  opacity: 0.1;
  border: 1px solid #899ca8;
}
.financing-card-bg .payment-source {
  /* Payment Source: Third Party */
  font-family: "Inter";
  font-style: normal;
  font-weight: 400;
  font-size: 14px;
  line-height: 24px;
  margin-left: 12px;
  color: #363636;
}
.financing-card-bg .payment-source span {
  color: #3B7AFE;
  font-weight: 600;
}
.financing-card-bg .third-party {
  color: #3b7afe;
  font-size: 14px;
  font-weight: 600;
  font-family: "Inter";
  line-height: 24px;
}
.financing-card-bg .mb_10 {
  margin-bottom: 14px;
  display: flex;
}
.financing-card-bg .mb_10:last-child {
  margin: 0;
}

.listView .financing-card-bg {
  min-height: auto;
  max-height: auto;
}
.listView .financing-card-container {
  padding: 20px;
}
.listView .financing-card-bg.smallCard .plan-detail-list {
  height: auto;
}
.listView .plan-detail-list {
  display: flex;
  flex-wrap: wrap;
}
.listView .plan-detail-list > * {
  width: 32%;
}

.filter-bar {
  margin-bottom: 40px;
}
.filter-bar h1 {
  margin: 0;
}

.ant-breadcrumb .ant-breadcrumb-separator {
  margin-inline: 2px;
  font-weight: 400;
  font-size: 16px;
  line-height: 19px;
  color: #899CA8;
}
.ant-breadcrumb .ant-breadcrumb-link {
  font-weight: 400;
  font-size: 16px;
  line-height: 19px;
  color: #899CA8;
}
.ant-breadcrumb .ant-breadcrumb-link a {
  font-weight: 400;
  font-size: 16px;
  line-height: 19px;
  color: #899CA8 !important;
}
.ant-breadcrumb .ant-breadcrumb-link a:hover {
  background-color: transparent;
  color: #899CA8;
}

.ant-table-tbody {
  padding: 0 !important;
}

thead.ant-table-thead .ant-table-cell {
  background: white !important;
}
thead.ant-table-thead .ant-table-cell::after, thead.ant-table-thead .ant-table-cell::before {
  content: normal !important;
}

.ant-table-tbody > tr[data-row-key] > td {
  padding: 10px !important;
  line-height: 1 !important;
  background: white !important;
}

.ant-table-thead > tr > th {
  padding: 24px 10px !important;
  background: white !important;
}

.ant-table-thead > tr > th > td {
  background: white !important;
}

:where(.css-dev-only-do-not-override-ph9edi).ant-table-wrapper .ant-table-thead > tr > th,
:where(.css-dev-only-do-not-override-ph9edi).ant-table-wrapper .ant-table-thead > tr > td {
  background: white !important;
}

:where(.css-dev-only-do-not-override-ph9edi).ant-table-wrapper .ant-table-thead > tr > th:not(:last-child):not(.ant-table-selection-column):not(.ant-table-row-expand-icon-cell):not([colspan])::before,
:where(.css-dev-only-do-not-override-ph9edi).ant-table-wrapper .ant-table-thead > tr > td:not(:last-child):not(.ant-table-selection-column):not(.ant-table-row-expand-icon-cell):not([colspan])::before {
  content: normal !important;
}

:where(.css-dev-only-do-not-override-ph9edi).ant-table-wrapper .ant-table-ping-right .ant-table-cell-fix-right-first::after,
:where(.css-dev-only-do-not-override-ph9edi).ant-table-wrapper .ant-table-ping-right .ant-table-cell-fix-right-last::after {
  content: normal !important;
}

:where(.css-dev-only-do-not-override-ph9edi).ant-table-wrapper .ant-table-ping-left .ant-table-cell-fix-left-first::after,
:where(.css-dev-only-do-not-override-ph9edi).ant-table-wrapper .ant-table-ping-left .ant-table-cell-fix-left-last::after {
  content: normal !important;
}

:where(.css-dev-only-do-not-override-ph9edi).ant-table-wrapper .ant-table-thead > tr > th,
:where(.css-dev-only-do-not-override-ph9edi).ant-table-wrapper .ant-table-thead > tr > td {
  font-weight: 400;
}

:where(.css-dev-only-do-not-override-ph9edi).ant-checkbox .ant-checkbox-inner {
  border: 2px solid #899CA8;
}

.ant-table-thead > tr > th::before {
  content: normal !important;
}

.ant-table-wrapper {
  background: white !important;
  padding: 0px 30px 24px 30px;
  border-radius: 20px;
}
.ant-table-wrapper .ant-table-column-sorters {
  justify-content: flex-start;
}
.ant-table-wrapper .ant-table-column-sorters .ant-table-column-title {
  flex: unset;
}

.ant-table-tbody .ant-table-cell {
  border: none !important;
}

.ant-table-thead .ant-table-column-title {
  color: #899CA8;
  font-weight: 400;
}

.ant-table-thead > tr > th {
  color: #899CA8 !important;
}

.ant-table-thead > tr {
  border-bottom: 1px solid #d9d9d9 !important;
}

.no-data-icon {
  display: flex;
  align-items: center;
  justify-content: center;
  width: 100%;
  height: 100%;
  padding: 5em;
}

.membershipPlan.ant-table-wrapper .ant-table-column-sorters {
  justify-content: center !important;
}

:where(.css-dev-only-do-not-override-ph9edi).ant-pagination .ant-pagination-item {
  background-color: #F9FAFA;
  color: #899CA8;
}
:where(.css-dev-only-do-not-override-ph9edi).ant-pagination .ant-pagination-item.ant-pagination-item-active {
  background: #5FBF24;
  color: white;
  border-color: #5FBF24;
}

:where(.css-dev-only-do-not-override-ph9edi).ant-pagination .ant-pagination-prev,
:where(.css-dev-only-do-not-override-ph9edi).ant-pagination .ant-pagination-next,
:where(.css-dev-only-do-not-override-ph9edi).ant-pagination .ant-pagination-jump-prev,
:where(.css-dev-only-do-not-override-ph9edi).ant-pagination .ant-pagination-jump-next {
  background-color: #F9FAFA;
  color: #899CA8;
}

:where(.css-dev-only-do-not-override-ph9edi).ant-pagination .ant-pagination-prev .ant-pagination-item-link,
:where(.css-dev-only-do-not-override-ph9edi).ant-pagination .ant-pagination-next .ant-pagination-item-link {
  display: flex;
  justify-content: center;
  align-items: center;
}

.dashboardSidebar {
  background: #ffffff;
  border: 1px solid #f5f1fe;
  border-radius: 24px;
  height: auto;
  padding: 44px 0 60px 0;
  position: relative;
}
@media (max-width: 1366px) {
  .dashboardSidebar {
    padding: 22px 0;
  }
}
@media (max-width: 767px) {
  .dashboardSidebar {
    position: fixed;
    top: 0;
    left: auto;
    right: 100%;
    border-radius: 0;
    width: 100%;
    z-index: 999999;
    height: 100%;
    transition: 0.5s ease;
    overflow: scroll;
  }
  .dashboardSidebar.active {
    right: 0;
  }
}
.dashboardSidebar .crossMenu {
  position: absolute;
  top: 15px;
  right: 45px;
}
.dashboardSidebar.settingSideBar {
  padding: 30px 0;
  background-color: #ffffff;
  box-shadow: 0px 6px 58px rgba(196, 203, 214, 0.103611);
  border-radius: 24px;
  border: 0;
  z-index: 0;
}
@media (max-width: 767px) {
  .dashboardSidebar.settingSideBar {
    position: relative;
    top: auto;
    left: auto;
    right: auto;
    margin-bottom: 20px;
  }
}
.dashboardSidebar .logo-dashboard {
  display: flex;
  padding-bottom: 40px;
  padding-left: 30px;
  width: 164.1px;
}
@media (max-width: 1366px) {
  .dashboardSidebar .logo-dashboard {
    padding-bottom: 30px;
    width: 125px;
  }
}
.dashboardSidebar ul {
  text-decoration: none;
  list-style: none;
  padding-left: 0;
}
.dashboardSidebar ul li {
  text-decoration: none;
  position: relative;
  padding: 0 16px 0 5px;
}
.dashboardSidebar ul li:hover .link-item.has-children .nav-child-list {
  opacity: 1;
  visibility: visible;
  display: block;
}
.dashboardSidebar ul li.hasChilList {
  position: relative;
}
.dashboardSidebar ul li.hasChilList .plusIcon {
  display: block;
  position: absolute;
  top: 9px;
  right: 30px;
}
.dashboardSidebar ul li .plusIcon {
  width: 13%;
  display: block;
  z-index: 200;
  position: relative;
  display: none;
}
.dashboardSidebar ul li .plusIcon svg {
  width: 25px;
  height: 25px;
}
.dashboardSidebar ul li:hover .link-item.has-children::after, .dashboardSidebar ul li:focus .link-item.has-children::after {
  border-left-color: #3B7AFE;
}
.dashboardSidebar ul li:not(:last-child) {
  margin-bottom: 2px;
}
.dashboardSidebar ul li .link-item {
  text-decoration: none;
  color: #899CA8;
  font-style: normal;
  font-weight: 400;
  font-size: 14px;
  line-height: 19px;
  cursor: pointer;
  padding: 11px 0 11px 15px;
  border-radius: 10px;
}
@media (max-width: 992px) {
  .dashboardSidebar ul li .link-item {
    padding: 5px;
    font-size: 10px;
  }
}
@media (max-width: 767px) {
  .dashboardSidebar ul li .link-item {
    font-size: 12px;
    padding: 9px;
  }
}
.dashboardSidebar ul li .link-item .nav-bg {
  display: flex;
  align-items: center;
  border-radius: 10px;
}
@media (max-width: 767px) {
  .dashboardSidebar ul li .link-item .nav-bg {
    flex-wrap: wrap;
  }
  .dashboardSidebar ul li .link-item .nav-bg span {
    width: 80%;
    text-align: left;
  }
  .dashboardSidebar ul li .link-item .nav-bg span:first-child {
    width: 7%;
  }
}
.dashboardSidebar ul li .link-item .settings-icon {
  padding-right: 10px;
}
.dashboardSidebar ul li .link-item.active, .dashboardSidebar ul li .link-item:hover, .dashboardSidebar ul li .link-item:focus {
  color: #3B7AFE;
  background-color: rgba(59, 122, 254, 0.1);
  font-weight: 700;
  transition: 0.2s ease-out;
}
.dashboardSidebar ul li .link-item.active path, .dashboardSidebar ul li .link-item:hover path, .dashboardSidebar ul li .link-item:focus path {
  fill: #3b7afe;
}
.dashboardSidebar ul li .link-item.active ::after, .dashboardSidebar ul li .link-item:hover ::after, .dashboardSidebar ul li .link-item:focus ::after {
  content: " ";
  position: absolute;
  right: 0;
  bottom: 2px;
  margin: 0;
  padding: 0;
  width: 3.82px;
  height: 44px;
  border-radius: 2px;
  background-color: #3B7AFE;
  border-radius: 50px;
}
@media (max-width: 767px) {
  .dashboardSidebar ul li .link-item.active ::after, .dashboardSidebar ul li .link-item:hover ::after, .dashboardSidebar ul li .link-item:focus ::after {
    content: "";
  }
}
.dashboardSidebar ul li .link-item.has-children::after {
  content: "";
  position: absolute;
  top: 50%;
  right: 30px;
  transform: translate(0, -50%);
  border-radius: 10px;
  width: 0;
  height: 0;
  border-top: 5px solid transparent;
  border-bottom: 5px solid transparent;
  border-left: 5px solid transparent;
  transition: 0.5s ease;
}
@media (max-width: 767px) {
  .dashboardSidebar ul li .link-item.has-children::after {
    content: normal;
  }
}
.dashboardSidebar ul li .link-item.has-children .nav-child-list {
  background: #ffffff;
  border-radius: 10px;
  position: absolute;
  top: 0;
  right: 0;
  left: 100%;
  opacity: 0;
  visibility: visible;
  width: 220px;
  height: auto;
  transition: 0.3s ease;
  z-index: 100;
  display: none;
  padding: 22px 20px;
  box-shadow: 0px 15px 20px 3px hsla(0, 0%, 0%, 0.1), 0 4px 11px hsla(0, 0%, 0%, 0.1);
}
@media (max-width: 767px) {
  .dashboardSidebar ul li .link-item.has-children .nav-child-list {
    opacity: 1;
    visibility: visible;
    left: auto;
    top: auto;
    position: relative;
    display: block;
    padding: 0;
    border-radius: 0;
    box-shadow: none;
    background: transparent;
    list-style: disc;
    padding-left: 15%;
    width: 100%;
    height: 0;
    overflow: scroll;
  }
  .dashboardSidebar ul li .link-item.has-children .nav-child-list.active {
    height: 85px;
  }
}
.dashboardSidebar ul li .link-item.has-children .nav-child-list .nav-child-item {
  padding: 0;
}
.dashboardSidebar ul li .link-item.has-children .nav-child-list .nav-child-item:last-child a {
  margin-bottom: 0;
}
.dashboardSidebar ul li .link-item.has-children .nav-child-list .nav-child-item a {
  font-style: normal;
  font-weight: 400;
  font-size: 14px;
  line-height: 17px;
  color: #899CA8;
  margin-bottom: 20px;
  transition: all 0.5s ease;
}
@media (max-width: 767px) {
  .dashboardSidebar ul li .link-item.has-children .nav-child-list .nav-child-item a {
    margin-bottom: 4px;
    font-size: 12px;
  }
}
.dashboardSidebar ul li .link-item.has-children .nav-child-list .nav-child-item a:hover, .dashboardSidebar ul li .link-item.has-children .nav-child-list .nav-child-item a:focus, .dashboardSidebar ul li .link-item.has-children .nav-child-list .nav-child-item a.active {
  color: #3B7AFE;
  font-weight: 700;
  font-size: 14px;
  -webkit-text-wrap: none;
}
.dashboardSidebar ul li .link-item.has-children .nav-child-list .p_28 {
  padding-top: 28px;
}
.dashboardSidebar ul li .link-item.has-children .nav-child-list:after {
  content: normal !important;
}
.dashboardSidebar ul li .link-item.has-children .nav-child-list .nav-child-item:after {
  content: normal;
}
.dashboardSidebar ul li .link-item.has-children .nav-child-list a:after {
  content: normal !important;
}
.dashboardSidebar ul li .non-active {
  cursor: pointer;
  background: transparent !important;
}
.dashboardSidebar ul li .non-active path {
  fill: #899ca8 !important;
}
.dashboardSidebar ul li .non-active ::after {
  display: none !important;
}

/**********************************
* Search bar container - top bar
***********************************/
@media (max-width: 767px) {
  .topBarWrapper {
    display: flex;
    justify-content: space-between;
    margin-bottom: 15px;
  }
}

@media (max-width: 767px) {
  .topBar-left {
    display: flex;
    align-content: center;
    flex-wrap: nowrap;
    justify-content: space-evenly;
  }
}
.topBar-left .list-inline-item {
  margin-right: 15px;
}
.topBar-left .list-inline-item:last-child {
  margin-right: 0;
}

.searchBarContainer {
  display: flex;
  align-items: center;
  border-radius: 10px;
  box-shadow: 2px -3px 12px 3px rgba(196, 203, 214, 0.1);
  position: relative;
  z-index: 20;
  width: -moz-max-content;
  width: max-content;
}
@media (max-width: 1024px) {
  .searchBarContainer {
    width: auto;
  }
}
@media (max-width: 767px) {
  .searchBarContainer {
    width: 90%;
  }
}
.searchBarContainer .crossIcon {
  position: absolute;
  top: 50%;
  right: 12px;
  transform: translate(0, -50%);
}
.searchBarContainer .searchBar .searchBar__menu {
  border: 0;
  border-radius: 14px;
  box-shadow: 0px 15px 20px 3px hsla(0, 0%, 0%, 0.1), 0 4px 11px hsla(0, 0%, 0%, 0.1);
}
.searchBarContainer .searchBar .menuSearchHead {
  display: flex;
  justify-content: space-between;
  align-items: center;
}
.searchBarContainer .searchBar .menuSearchHead .menu-heading {
  color: #000;
  font-weight: 700;
  font-size: 16px;
  letter-spacing: -0.02em;
}
@media (max-width: 992px) {
  .searchBarContainer .searchBar .menuSearchHead .menu-heading {
    font-size: 14px;
  }
}
.searchBarContainer .searchBar .menuSearchHead .clearText {
  color: #363636;
  font-weight: 600;
  font-size: 14px;
}
.searchBarContainer .searchBar .menuSearchHead .clearText:hover {
  cursor: pointer;
  color: #3B7AFE;
}
@media (max-width: 992px) {
  .searchBarContainer .searchBar .menuSearchHead .clearText {
    font-size: 12px;
  }
}
.searchBarContainer .searchBar .search-wrapper {
  margin-bottom: 16px;
}
.searchBarContainer .searchBar .search-wrapper svg {
  width: 20px;
  height: 20px;
  margin-right: 10px;
}
@media (max-width: 992px) {
  .searchBarContainer .searchBar .search-wrapper svg {
    width: 15px;
    height: 15px;
  }
}
@media (max-width: 992px) {
  .searchBarContainer .searchBar .search-wrapper {
    margin-bottom: 14px;
  }
}
.searchBarContainer .searchBar .searchBar__menu-list {
  padding: 24px 39px;
  border: 0;
  border-radius: 14px;
}
@media (max-width: 992px) {
  .searchBarContainer .searchBar .searchBar__menu-list {
    padding: 16px;
  }
}
.searchBarContainer .searchBar .searchBar__control {
  height: 48px;
  width: 300px;
  background-color: #ffffff;
  border-color: #ffffff;
  border-radius: 14px;
  transition: all 0.5s ease;
  padding-left: 19px;
  text-align: left;
  min-width: 300px;
  max-width: 300px;
  width: 25em;
  display: flex;
  align-items: center;
}
@media (max-width: 992px) {
  .searchBarContainer .searchBar .searchBar__control {
    width: 18em;
  }
}
@media (max-width: 767px) {
  .searchBarContainer .searchBar .searchBar__control {
    width: 14em;
    padding-left: 5px;
  }
}
@media (max-width: 575px) {
  .searchBarContainer .searchBar .searchBar__control {
    width: 11em;
  }
}
@media (max-width: 375px) {
  .searchBarContainer .searchBar .searchBar__control {
    width: 10em;
  }
}
.searchBarContainer .searchBar .searchBar__control:focus {
  border-color: #3B7AFE;
  width: 100%;
}
.searchBarContainer .searchBar .searchBar__option {
  font-weight: 400;
  font-size: 16px;
  line-height: 1;
  display: flex;
  align-items: center;
  padding: 0;
}
@media (max-width: 992px) {
  .searchBarContainer .searchBar .searchBar__option {
    font-size: 12px;
  }
}
.searchBarContainer .searchBar .searchBar__option:active, .searchBarContainer .searchBar .searchBar__option:focus {
  background-color: white !important;
  box-shadow: unset !important;
}
.searchBarContainer .searchBar .searchBar__option:hover {
  color: #3B7AFE;
}
.searchBarContainer .crossIcon {
  display: none;
}
.searchBarContainer .searchBar__input:focus .searchBarContainer .crossIcon, .searchBarContainer .searchBar__input:active .searchBarContainer .crossIcon {
  display: block;
}

.nosearchResult {
  display: flex;
  flex-flow: column;
  align-items: center;
  justify-content: center;
  width: 100%;
  height: 100%;
}
.nosearchResult .noSearchImg {
  width: 250px;
  height: auto;
}
@media (max-width: 1366px) {
  .nosearchResult .noSearchImg {
    width: 170px;
  }
}
.nosearchResult .noSearchImg svg,
.nosearchResult .noSearchImg img {
  height: 100%;
  width: 100%;
}

/**************************************
  * notification Icon - Top bar
  ****************************************/
.notificationContainer {
  background: #ffffff;
  border: 1px solid #ffffff;
  box-shadow: 0px 6px 58px rgba(196, 203, 214, 0.103611);
  padding: 12px;
  border-radius: 14px;
  transition: all 0.5s ease;
  cursor: pointer;
}
.notificationContainer:hover, .notificationContainer:focus {
  border: 1px solid #3B7AFE;
}
.notificationContainer:hover svg path, .notificationContainer:focus svg path {
  fill: #3B7AFE;
}
@media (max-width: 1366px) {
  .notificationContainer {
    width: 40px;
    height: 38px;
    display: flex;
    align-items: center;
    justify-content: center;
  }
}
@media (max-width: 1024px) {
  .notificationContainer {
    padding: 9px;
  }
}
@media (max-width: 767px) {
  .notificationContainer {
    width: 35px;
    height: 35px;
    padding: 6px;
  }
}

/**************************************
  * profile box dropdown - Top bar
  ****************************************/
.topBar {
  margin-bottom: 25px;
}
.topBar .list-inline {
  display: flex;
  justify-content: flex-end;
  align-items: center;
  margin: 0;
}

.profileBtn .dropdown-toggle {
  background: #ffffff;
  border: 1px solid #ffffff;
  padding: 9px 18px 9px 11px;
  border-radius: 14px;
}
@media (max-width: 1366px) {
  .profileBtn .dropdown-toggle {
    padding: 4px 11px;
  }
}
@media (max-width: 767px) {
  .profileBtn .dropdown-toggle {
    padding: 0;
  }
}
.profileBtn .dropdown-toggle:after {
  content: unset;
}
.profileBtn .dropdown-toggle:focus {
  box-shadow: none;
  outline: none;
}
.profileBtn .dropdown-toggle:hover {
  background: #ffffff;
  border: 1px solid #3B7AFE;
}
.profileBtn .dropdown-toggle:hover svg path {
  fill: #3B7AFE;
}
.profileBtn.show .profileBox {
  transition: all 0.5s ease;
}
.profileBtn.show .profileBox svg {
  transform: rotate(180deg);
  transform-origin: center;
}
.profileBtn .dropdown-menu {
  padding: 15px 12px;
  border: 1px solid #ffffff;
  box-shadow: 0px 15px 20px 3px hsla(0, 0%, 0%, 0.1), 0 4px 11px hsla(0, 0%, 0%, 0.1);
  margin-top: 8px;
}
.profileBtn .dropdown-menu .dropdown-item {
  text-decoration: none;
  font-family: "Inter";
  font-style: normal;
  font-weight: 400;
  font-size: 14px;
  line-height: 19px;
  padding: 0;
  background: #ffffff;
  color: #363636;
  transition: font-weight 0.1s ease-in-out;
}
.profileBtn .dropdown-menu .dropdown-item:not(:last-child) {
  margin-bottom: 20px;
}
.profileBtn .dropdown-menu .dropdown-item:hover, .profileBtn .dropdown-menu .dropdown-item:focus {
  color: #3B7AFE;
  font-weight: 700;
}
.profileBtn .dropdown-menu .dropdown-item:hover svg path, .profileBtn .dropdown-menu .dropdown-item:focus svg path {
  fill: #3B7AFE;
}

.profileBox {
  cursor: pointer;
}
.profileBox .profileImg {
  width: 30px;
  height: 30px;
  -o-object-fit: cover;
     object-fit: cover;
  -o-object-position: center top;
     object-position: center top;
  margin-right: 11px;
}
.profileBox .profileName {
  font-family: "Inter";
  font-style: normal;
  font-weight: 700;
  font-size: 16px;
  line-height: 24px;
  color: #000;
  margin-right: 18px;
}
@media (max-width: 1366px) {
  .profileBox .profileName {
    font-size: 14px;
    line-height: 1;
  }
}
@media (max-width: 767px) {
  .profileBox .profileName {
    display: none;
  }
}

/**************************************
* Humburger
****************************************/
.hamburger-menu {
  display: none;
}
@media (max-width: 767px) {
  .hamburger-menu {
    display: flex;
    position: relative;
    flex-flow: column;
    justify-content: center;
  }
}

#menu__toggle {
  opacity: 0;
}

.menu__btn {
  display: flex;
  align-items: center;
  position: relative;
  width: 26px;
  height: auto;
  cursor: pointer;
  z-index: 1;
}
@media (max-width: 767px) {
  .menu__btn {
    width: 19px;
  }
}

.menu__btn > span,
.menu__btn > span::before,
.menu__btn > span::after {
  display: block;
  position: absolute;
  width: 100%;
  height: 2px;
  background-color: #616161;
  transition-duration: 0.25s;
}

.menu__btn > span::before {
  content: "";
  top: -8px;
}

.menu__btn > span::after {
  content: "";
  top: 8px;
}

.rightTopbarHolder {
  display: flex;
  align-items: center;
  justify-content: space-evenly;
}

.menu-search-bar {
  display: flex;
  align-items: center;
  overflow: hidden;
  padding: 10px 14px;
  border: 1px solid #F5F1FE;
  border-radius: 14px;
}
.menu-search-bar .ant-input {
  border-radius: 0;
  border: 0;
}
.menu-search-bar .ant-input::-moz-placeholder {
  font-weight: 400;
  font-size: 16px;
  color: #7D8592;
}
.menu-search-bar .ant-input::placeholder {
  font-weight: 400;
  font-size: 16px;
  color: #7D8592;
}
.menu-search-bar .ant-input:focus, .menu-search-bar .ant-input:active {
  outline: none;
  box-shadow: none;
}

.search-menu {
  min-width: 370px !important;
}
.search-menu .ant-dropdown .ant-dropdown-menu .ant-dropdown-menu-item:hover,
.search-menu .ant-dropdown-menu-submenu .ant-dropdown-menu .ant-dropdown-menu-item:hover,
.search-menu .ant-dropdown .ant-dropdown-menu .ant-dropdown-menu-submenu-title:hover,
.search-menu .ant-dropdown-menu-submenu .ant-dropdown-menu .ant-dropdown-menu-submenu-title:hover,
.search-menu .ant-dropdown .ant-dropdown-menu .ant-dropdown-menu-item-active,
.search-menu .ant-dropdown-menu-submenu .ant-dropdown-menu .ant-dropdown-menu-item-active,
.search-menu .ant-dropdown .ant-dropdown-menu .ant-dropdown-menu-submenu-title-active,
.search-menu .ant-dropdown-menu-submenu .ant-dropdown-menu .ant-dropdown-menu-submenu-title-active {
  background-color: transparent !important;
}
.search-menu .ant-dropdown-menu .ant-dropdown-menu-item {
  margin-bottom: 14px;
}
.search-menu .ant-dropdown-menu .ant-dropdown-menu-item:first-child {
  margin-bottom: 24px;
}

.withSearchBar .ant-dropdown-menu-item:hover {
  background-color: transparent !important;
}
.withSearchBar .ant-dropdown-menu-item.ant-dropdown-menu-item-active {
  background-color: transparent;
}

.no-hover-effect {
  margin-bottom: 24px;
  background-color: white !important;
}
.no-hover-effect:hover {
  background-color: transparent !important;
}

:where(.css-dev-only-do-not-override-ph9edi).ant-dropdown .ant-dropdown-menu .ant-dropdown-menu-item:focus-visible,
:where(.css-dev-only-do-not-override-ph9edi).ant-dropdown-menu-submenu .ant-dropdown-menu .ant-dropdown-menu-item:focus-visible,
:where(.css-dev-only-do-not-override-ph9edi).ant-dropdown .ant-dropdown-menu .ant-dropdown-menu-submenu-title:focus-visible,
:where(.css-dev-only-do-not-override-ph9edi).ant-dropdown-menu-submenu .ant-dropdown-menu .ant-dropdown-menu-submenu-title:focus-visible {
  outline: none;
}

.searchFoundWrapper {
  height: 555px;
  overflow: scroll;
}
.searchFoundWrapper .SearchFoundList .searchFounditem {
  border-bottom: 1px solid #CFCFCF;
  padding: 15px 0;
}
.searchFoundWrapper .SearchFoundList .searchFounditem .searhPageTitle {
  font-weight: 600;
  font-size: 16px;
  color: #000;
  padding: 8px 0 12px 0;
  transition: 0.5s ease;
}
.searchFoundWrapper .SearchFoundList .searchFounditem .searchPagePara {
  font-weight: 400;
  font-size: 12px;
  line-height: 16px;
  color: #363636;
}
.searchFoundWrapper .SearchFoundList .searchFounditem a {
  position: relative;
}
.searchFoundWrapper .SearchFoundList .searchFounditem a:hover {
  text-decoration: none;
}
.searchFoundWrapper .SearchFoundList .searchFounditem a:hover .arrowRightSvg {
  margin-right: 10px;
}
.searchFoundWrapper .SearchFoundList .searchFounditem a:hover .searhPageTitle {
  color: #3B7AFE;
}
.searchFoundWrapper .SearchFoundList .searchFounditem a .arrowRightSvg {
  position: absolute;
  top: 50%;
  right: 0;
  transform: translate(0, -50%);
  transition: 0.5s ease;
  transition-property: margin;
}
.searchFoundWrapper .SearchFoundList .searchFounditem:first-child {
  padding-top: 0;
}
.searchFoundWrapper .SearchFoundList .searchFounditem:last-child {
  border-bottom: 0;
}
.searchFoundWrapper .SearchFoundList .searchFounditem p {
  margin: 0;
  padding: 0;
}
.searchFoundWrapper .SearchFoundList .searchFounditem .breadcrumb {
  margin: 0;
}
.searchFoundWrapper .SearchFoundList .searchFounditem .breadcrumb .breadcrumb-item {
  color: #363636;
  font-size: 14px;
  padding-left: 3px;
}
.searchFoundWrapper .SearchFoundList .searchFounditem .breadcrumb .breadcrumb-item::before {
  padding-right: 3px;
}
.searchFoundWrapper .nosearchResult {
  height: 100%;
  display: flex;
  align-items: center;
}

.searchOverlay {
  position: absolute;
  top: 0;
  left: 0;
  width: 100%;
  height: 100%;
  z-index: 11;
  background: rgba(0, 0, 0, 0.2);
}

/** Notification Modal CSS **/
.notification-modal .modal-header {
  padding: 37px 30px 34px 30px;
  border-bottom: 1px solid #E4E6E8;
}
@media (max-width: 767px) {
  .notification-modal .modal-header {
    padding: 20px;
  }
}
.notification-modal .modal-body {
  padding: 0;
}
.notification-modal .notication-card-container {
  height: calc(100vh - 220px);
  overflow: scroll;
}

/** Notification Card **/
.notificationCard {
  padding: 15px 80px 14px 30px;
  border-bottom: 1px solid #E4E6E8;
  transition: 0.5s ease;
}
.notificationCard:hover {
  background: #E4E6E8;
  cursor: pointer;
}
@media (max-width: 767px) {
  .notificationCard {
    padding: 10px;
  }
}
.notificationCard:last-child {
  border-bottom: 0;
}
.notificationCard .notification-img {
  width: 50px;
  height: 50px;
  border-radius: 100%;
  overflow: hidden;
  margin-right: 18px;
}
.notificationCard .notification-img img {
  width: 100%;
  height: auto;
  -o-object-fit: cover;
     object-fit: cover;
}
.notificationCard p {
  color: #0A1629;
  font-weight: 400;
  font-size: 14px;
  line-height: 24px;
  margin: 0;
}
.notificationCard small {
  font-weight: 600;
  font-size: 14px;
  color: #363636;
}

.noDetailFoundBox {
  display: flex;
  flex-flow: column;
  justify-content: center;
  align-items: center;
  height: 100%;
  width: 100%;
}
.noDetailFoundBox .noDetailFoundImage {
  width: 250px;
}
.noDetailFoundBox .noDetailFoundImage img {
  width: 100%;
  height: auto;
}

/**************************************
* Responsive
****************************************/
@media (min-width: 576px) {
  .modal-dialog {
    max-width: 437px;
  }
}
.notification-modal .modal-dialog {
  margin-right: 2rem;
}
@media (max-width: 767px) {
  .notification-modal .modal-dialog {
    margin-right: 0.5rem;
  }
}

.modal-header .btn-close {
  padding: 12px;
}

.uploadModal .modal-content,
.deleteModal .modal-content,
.editModal .modal-content {
  width: 460px;
  padding: 43px 30px 50px 38px;
  border-radius: 24px;
  border: 0;
}
.uploadModal .modal-content p,
.deleteModal .modal-content p,
.editModal .modal-content p {
  margin-top: 20px;
  margin-bottom: 41px;
  font-size: 20px;
  line-height: 29px;
  color: #363636;
}

.modal-body .rumSelect.normal .rumSelect__placeholder {
  font-size: 14px;
  color: #363636;
}

.employeeDetail p.columnHeading {
  font-size: 14px;
  line-height: 1;
  font-weight: bold;
}
.employeeDetail p.columnpara {
  font-size: 14px;
  display: inline-block;
  color: #000;
  margin-bottom: 0;
  padding-right: 4px;
  line-height: 1;
}
.employeeDetail p.columnpara.lineHeight-1p1 {
  line-height: 1.2;
}

.uploadPopup .uploaded-file-container h4 {
  font-size: 14px;
  font-weight: 700;
  line-height: 18px;
  letter-spacing: 0em;
}
.uploadPopup .uploaded-file-container .uploading-wrapper .icon-wrapper {
  position: relative;
}
.uploadPopup .uploaded-file-container .uploading-wrapper .icon-wrapper svg {
  position: absolute;
  right: 10px;
  top: 14px;
  cursor: pointer;
}
.uploadPopup .uploaded-file-container .uploaded-wrapper .icon-wrapper {
  position: relative;
}
.uploadPopup .uploaded-file-container .uploaded-wrapper .icon-wrapper svg {
  position: absolute;
  right: 10px;
  top: 9px;
  cursor: pointer;
}
.uploadPopup .glowBtn:disabled {
  background: #363636;
  border: none;
  box-shadow: none;
}

.contentModal button.ant-btn {
  width: 167px;
  height: 52px;
  min-width: 167px;
  max-width: 167px;
  font-size: 18px;
  color: white;
  font-weight: 500;
}

.ant-select.ant-select-multiple .ant-select-selector {
  height: auto !important;
  padding: 6px 12px;
}
.ant-select.ant-select-multiple .ant-select-selection-item-remove {
  display: flex;
}
.ant-select.ant-select-focused .ant-select-selector {
  border-color: #3B7AFE !important;
  box-shadow: none !important;
}
.ant-select .ant-select-selector {
  height: 52px !important;
  background: #FFFFFF;
  border: 1px solid #F5F1FE !important;
  border-radius: 20px !important;
  display: flex !important;
  align-items: center !important;
  box-shadow: none;
}
.ant-select .ant-select-selector:hover {
  border-color: #3B7AFE !important;
}

.ant-form {
  font-family: "Inter" !important;
}

.ant-select-dropdown {
  z-index: 9999;
  position: relative;
  max-width: -moz-fit-content;
  max-width: fit-content;
}

.ant-form-item .ant-form-item-label {
  padding: 0;
  padding-bottom: 11px;
}
.ant-form-item .ant-form-item-label label {
  display: flex;
  flex-direction: row-reverse;
  justify-content: flex-end;
  font-family: "Inter";
  font-style: normal;
  font-weight: 400;
  font-size: 16px;
  line-height: 19px;
  color: #899CA8;
  height: auto !important;
}
.ant-form-item textarea {
  min-height: 93px;
}
.ant-form-item textarea::-webkit-scrollbar {
  width: 4px;
  height: 4px;
  -webkit-transition: 0.5s ease;
  transition: 0.5s ease;
  /* width of the entire scrollbar */
}
.ant-form-item textarea::-webkit-scrollbar-track {
  background: #f3f5f6;
  /* color of the tracking area */
  height: 1px;
  padding: 1px;
  width: 5px;
  border-radius: 20px;
}
.ant-form-item textarea::-webkit-scrollbar-thumb {
  background-color: #899CAB;
  /* color of the scroll thumb */
  border-radius: 20px;
  /* roundness of the scroll thumb */
  border: 1px solid #899CAB;
  height: 2px;
  width: 10px;
  /* creates padding around scroll thumb */
}
.ant-form-item .ant-input {
  border-radius: 20px;
  width: 100%;
  height: 52px;
  background: #ffffff;
  border: 1px solid #f5f1fe;
  border-radius: 20px;
  font-weight: 500;
  font-size: 15px;
  color: #363636;
}
.ant-form-item .ant-input:focus {
  border: 1px solid #3B7AFE !important;
  box-shadow: none !important;
  outline: none !important;
}
input:-webkit-autofill {
  -webkit-animation-delay: 1s;
  /* Safari support - any positive time runs instantly */
  -webkit-animation-name: autofill;
  -webkit-animation-fill-mode: both;
}

input:-webkit-autofill {
  -webkit-box-shadow: none !important;
  -webkit-background-clip: text !important;
}

.ant-input-affix-wrapper {
  background: #ffffff;
  border: 1px solid #f5f1fe;
  border-radius: 20px;
  font-weight: 500;
  font-size: 15px;
  color: #363636;
  height: 52px;
}
.ant-input-affix-wrapper .ant-input {
  height: auto;
}
.ant-input-affix-wrapper .ant-input:focus {
  border: 0 !important;
  box-shadow: none !important;
  outline: none !important;
}
.ant-input-affix-wrapper.ant-input-affix-wrapper-focused {
  box-shadow: none;
  border: 1px solid #3B7AFE;
}
.ant-input-affix-wrapper.ant-input-affix-wrapper-status-error {
  border-color: #ff4d4f;
  box-shadow: none;
}

.validation-error {
  font-size: 14px;
  color: red;
}

.ant-input-suffix .ant-form-item .ant-form-item-feedback-icon {
  line-height: 1;
}
.ant-input-suffix .anticon-close-circle svg {
  width: 100%;
  height: 100%;
}

.addNewBtn {
  display: flex;
  align-items: center;
  position: absolute;
  top: 0;
  right: 0;
  z-index: 555;
}
.addNewBtn:hover {
  opacity: 0.9;
  cursor: pointer;
  z-index: 55;
}
.addNewBtn svg path {
  fill: #3B7AFE;
}
.addNewBtn p {
  margin-left: 8px !important;
}

.financeDropDown .ant-dropdown-menu {
  display: flex;
  flex-flow: column;
  justify-content: center;
  background: #FFFFFF;
  border: 1px solid #F5F1FE;
  border-radius: 20px;
  width: 157px;
  height: auto;
  padding: 10px 0;
  box-shadow: none;
}

.form-dropdown .ant-select-selector {
  border-radius: 20px !important;
}

.ant-picker {
  width: 100%;
  height: 52px;
  background: #ffffff;
  border: 1px solid #f5f1fe;
  border-radius: 20px;
  font-weight: 500;
  font-size: 15px;
}
.ant-picker:hover {
  border: 1px solid #3B7AFE !important;
  box-shadow: none !important;
  outline: none !important;
}
.ant-picker.ant-picker-focused {
  border: 1px solid #3B7AFE !important;
  box-shadow: none !important;
  outline: none !important;
}
.ant-picker .ant-picker-input > input {
  font-size: 15px;
  font-weight: 500;
}

.image-holder {
  width: 64px;
  height: 64px;
  overflow: hidden;
  border-radius: 100%;
}
.image-holder:hover {
  cursor: pointer;
}

.profile-title {
  display: flex;
  align-items: center;
}

.upload-img-box {
  width: 64px;
  height: 64px;
  position: relative;
}
.upload-img-box:before {
  content: "";
  position: absolute;
  top: 0;
  left: 0;
  width: 100%;
  height: 100%;
  background: rgba(0, 0, 0, 0.2);
}
.upload-img-box .cameraIcon {
  position: absolute;
  top: 50%;
  left: 50%;
  transform: translate(-50%, -50%);
}
.upload-img-box img {
  width: 100%;
  height: 100%;
  border-radius: 100%;
}

.anticon-close-circle svg {
  width: 25px;
  height: 25px;
}

.ant-select-selection-placeholder {
  font-weight: 500;
  font-size: 16px;
  color: #899CA8;
}

:where(.css-dev-only-do-not-override-ph9edi)[class^=ant-form] [class^=ant-form]::before,
:where(.css-dev-only-do-not-override-ph9edi)[class*=" ant-form"] [class^=ant-form]::before,
:where(.css-dev-only-do-not-override-ph9edi)[class^=ant-form] [class*=" ant-form"]::before,
:where(.css-dev-only-do-not-override-ph9edi)[class*=" ant-form"] [class*=" ant-form"]::before,
:where(.css-dev-only-do-not-override-ph9edi)[class^=ant-form] [class^=ant-form]::after,
:where(.css-dev-only-do-not-override-ph9edi)[class*=" ant-form"] [class^=ant-form]::after,
:where(.css-dev-only-do-not-override-ph9edi)[class^=ant-form] [class*=" ant-form"]::after,
:where(.css-dev-only-do-not-override-ph9edi)[class*=" ant-form"] [class*=" ant-form"]::after {
  -webkit-margin-start: 6px;
          margin-inline-start: 6px;
  font-weight: bold;
}

:where(.css-dev-only-do-not-override-ph9edi).ant-select-multiple.ant-select-lg .ant-select-selection-item {
  font-weight: 500;
  background: rgba(151, 151, 151, 0.1);
  border: 0.5px solid #899CA8;
  border-radius: 10px;
}

:where(.css-dev-only-do-not-override-ph9edi).ant-select-multiple .ant-select-selection-item-content {
  font-size: 14px;
}

.ant-select .ant-select-selection-item {
  font-weight: 500;
  font-size: 16px;
  color: #363636;
}

.ant-select-multiple .ant-select-selection-item-content {
  margin-right: 15px;
}

:where(.css-dev-only-do-not-override-ph9edi).ant-input-lg {
  padding: 7px 17px;
}

.addOnLabel .ant-input-status-error:not(.ant-input-disabled):not(.ant-input-borderless).ant-input:focus,
.addOnLabel .ant-input-status-error:not(.ant-input-disabled):not(.ant-input-borderless).ant-input-focused {
  border-color: #ff4d4f !important;
  box-shadow: none !important;
}
.addOnLabel .ant-input-group .ant-input {
  border-right: 0 !important;
}
.addOnLabel .ant-input-group .ant-input-group-addon {
  border: 1px solid;
  border-left: 0 !important;
  border-top-right-radius: 20px;
  border-bottom-right-radius: 20px;
  color: #f5f1fe;
  background: transparent;
}
.addOnLabel .ant-input-group:active .ant-input, .addOnLabel .ant-input-group:hover .ant-input, .addOnLabel .ant-input-group:focus .ant-input {
  border-right: 0 !important;
  border-color: #3B7AFE;
}
.addOnLabel .ant-input-group:active .ant-input-group-addon, .addOnLabel .ant-input-group:hover .ant-input-group-addon, .addOnLabel .ant-input-group:focus .ant-input-group-addon {
  border-color: #3B7AFE;
  color: #3B7AFE;
}
.addOnLabel .ant-input-group-wrapper-status-success .ant-input-group .ant-input-group-addon {
  color: #3B7AFE;
}
.addOnLabel .ant-input-group-wrapper-status-error .ant-input-group:active .ant-input-group-addon, .addOnLabel .ant-input-group-wrapper-status-error .ant-input-group:hover .ant-input-group-addon, .addOnLabel .ant-input-group-wrapper-status-error .ant-input-group:focus .ant-input-group-addon {
  color: #ff4d4f;
  border-color: #ff4d4f;
}
.addOnLabel .ant-input-group-wrapper-status-error .ant-input-group .ant-input-group-addon {
  color: #ff4d4f;
}

.ant-form-item .ant-form-item-explain-error {
  font-size: 12px;
}

.ant-switch.ant-switch-checked {
  background: #5FBF24;
}

.ant-switch.ant-switch-checked:hover:not(.ant-switch-disabled) {
  background: #5FBF24;
}

.popup-menu {
  z-index: 55555;
}

.ant-input-group.ant-input-group-compact {
  display: flex;
  flex-flow: column;
}

.ant-space-compact {
  display: flex;
  flex-flow: column;
}

.ant-radio-wrapper {
  display: inline-flex;
  align-items: center;
}
.ant-radio-wrapper span {
  color: #363636;
  font-size: 14px;
}
.ant-radio-wrapper .ant-radio-inner {
  width: 24px;
  height: 24px;
}
.ant-radio-wrapper .ant-radio-checked .ant-radio-inner {
  background-color: white;
}
.ant-radio-wrapper .ant-radio-checked .ant-radio-inner::after {
  transform: scale(0.8);
  background-color: #3B7AFE;
}

.ant-slider .ant-slider-rail {
  background-color: #F6F6F6;
}
.ant-slider .ant-slider-track {
  background-color: transparent;
}
.ant-slider:hover .ant-slider-track {
  background-color: transparent;
}
.ant-slider:hover .ant-slider-handle {
  box-shadow: -5px 5px 17px 3px #3B7AFE;
}
.ant-slider:hover .ant-slider-handle:before {
  inset-inline-start: -1px;
  inset-block-start: -1px;
  width: 12px;
  height: 12px;
}
.ant-slider:hover .ant-slider-handle:after {
  box-shadow: 0 0 0 5px #1677ff;
  width: 10px;
  height: 10px;
  inset-inline-start: -1px;
  inset-block-start: -1px;
}
.ant-slider .ant-slider-handle {
  box-shadow: -2.03371px 2.03371px 6.10112px rgba(59, 122, 254, 0.33);
}
.ant-slider .ant-slider-handle:focus:before, .ant-slider .ant-slider-handle:active:before, .ant-slider .ant-slider-handle:hover:before {
  inset-inline-start: -1px;
  inset-block-start: -1px;
  width: 12px;
  height: 12px;
}
.ant-slider .ant-slider-handle:focus:after, .ant-slider .ant-slider-handle:active:after, .ant-slider .ant-slider-handle:hover:after {
  box-shadow: 0 0 0 5px #1677ff;
  width: 10px;
  height: 10px;
  inset-inline-start: -1px;
  inset-block-start: -1px;
}
.ant-slider .ant-slider-handle:before {
  inset-inline-start: -1px;
  inset-block-start: -1px;
  width: 12px;
  height: 12px;
}
.ant-slider .ant-slider-handle:after {
  box-shadow: 0 0 0 5px #1677ff;
  width: 10px;
  height: 10px;
  inset-inline-start: -1px;
  inset-block-start: -1px;
}

.sliderMaxValue {
  font-weight: 400;
  font-size: 12px;
  line-height: 15px;
  color: #899CA8;
  text-align: right;
}

.sliderValueBar {
  display: flex;
  justify-content: space-between;
}
.sliderValueBar p {
  font-weight: 500;
  font-size: 14px !important;
  line-height: 1;
  color: #899CA8;
  margin: 0;
}

.ant-slider-tooltip .ant-tooltip-arrow::before, .ant-slider-tooltip .ant-tooltip-arrow::after {
  content: normal;
}
.ant-slider-tooltip .ant-tooltip-content {
  background: none;
}
.ant-slider-tooltip .ant-tooltip-inner {
  background: transparent;
  box-shadow: none;
  border-radius: 0;
  font-style: normal;
  font-weight: 600;
  font-size: 14px;
  text-align: center;
  color: #363636;
  padding: 0;
}

.custom-form-btn {
  display: flex;
  justify-content: space-between;
  align-items: center;
  margin-bottom: 7px;
}
.custom-form-btn .addNewBtn {
  position: relative;
}

.locationDropdownMenu.ant-select-dropdown .ant-select-item-option {
  background: transparent;
}
.locationDropdownMenu.ant-select-dropdown .ant-select-item-option .ant-select-item-option-content {
  display: flex;
  justify-content: space-between;
  align-items: center;
  font-weight: 400;
  font-size: 14px;
  line-height: 17px;
  color: #7D8592;
}

.locationDropdown {
  position: relative;
  width: 300px;
}
.locationDropdown.ant-select-single .ant-select-selector {
  border-radius: 14px !important;
}
.locationDropdown.ant-select-single.ant-select-show-arrow:not(:last-child) {
  -webkit-margin-end: 18px;
          margin-inline-end: 18px;
}
.locationDropdown.ant-select-single.ant-select-show-arrow .ant-select-selection-item {
  padding: 0 30px;
  padding-top: 2px;
}
.locationDropdown .ant-select-arrow {
  position: unset;
}
.locationDropdown .ant-select-arrow span {
  position: absolute;
  top: 45%;
  right: 10px;
  transform: translate(0, -50%);
}
.locationDropdown .ant-select-arrow span.locationIconBlue {
  position: absolute;
  top: 50%;
  left: auto;
  transform: translate(0, -50%);
  right: 90%;
}

.react-tel-input .flag-dropdown {
  width: 60px;
  background: transparent;
  border-radius: 10px;
  border: 1px solid #f5f1fe !important;
}
.react-tel-input .flag-dropdown .selected-flag {
  display: flex;
  justify-content: center;
  align-items: center;
  width: 100%;
  height: 100%;
  padding: 0;
  border-radius: 10px !important;
}
.react-tel-input .flag-dropdown .selected-flag .flag {
  position: absolute;
  top: 50%;
  margin-top: 0;
  left: 50%;
  transform: translate(-50%, -50%);
}
.react-tel-input .form-control {
  background: #ffffff;
  border: 1px solid #f5f1fe;
  border-radius: 20px;
  font-weight: 500;
  font-size: 15px;
  color: #363636;
  height: 52px;
  width: 83%;
  padding: 10px;
  margin-left: auto;
}
.react-tel-input .form-control:focus {
  box-shadow: none;
  outline: none;
}

.themeInputGroup {
  /*
  * Password input field
  */
}
.themeInputGroup .form-label {
  font-weight: 400;
  font-size: 16px;
  line-height: 19px;
  color: #899CA8;
}
.themeInputGroup .form-label span {
  font-weight: 600;
  color: #E62E2E;
  margin-left: 5px;
}
.themeInputGroup .form-control {
  padding: 0.815rem 0.75rem;
  border: 1px solid #F5F1FE;
  border-radius: 20px;
}
.themeInputGroup .form-control:focus, .themeInputGroup .form-control:hover {
  border: 1px solid #3B7AFE;
  transition: all 1s ease-in;
  box-shadow: none;
  outline: none;
}
.themeInputGroup .form-control::-moz-placeholder {
  font-size: 12px;
}
.themeInputGroup .form-control::placeholder {
  font-size: 12px;
}
.themeInputGroup .form-control.hasError {
  border-color: red !important;
  transition: all 1s ease-in;
}
.themeInputGroup .input-group {
  align-items: stretch;
  display: flex;
  flex-wrap: wrap;
  position: relative;
  width: 100%;
}
.themeInputGroup.passwordInput .input-group {
  border: 1px solid #F5F1FE;
  border-radius: 20px;
}
.themeInputGroup.passwordInput .input-group.hasError {
  border-color: red !important;
  transition: all 1s ease-in;
}
.themeInputGroup.passwordInput .input-group:active, .themeInputGroup.passwordInput .input-group:focus, .themeInputGroup.passwordInput .input-group:hover {
  border: 1px solid #3B7AFE;
  transition: all 1s ease-in;
}
.themeInputGroup.passwordInput .input-group .form-control {
  border: none;
  box-shadow: none;
  outline: none;
}
.themeInputGroup.passwordInput .input-group .btn-togglePassword {
  border: none;
  background: transparent;
  outline: none;
  box-shadow: none;
}
@media (max-width: 1366px) {
  .themeInputGroup.passwordInput .input-group .btn-togglePassword {
    width: 42px;
    padding: 0;
  }
}
.themeInputGroup.passwordInput .input-group .btn-togglePassword svg {
  width: 100%;
  height: 100%;
}
@media (max-width: 1366px) {
  .themeInputGroup.passwordInput .input-group .btn-togglePassword svg {
    width: 18px;
  }
}

.form-text {
  font-weight: 400;
  font-size: 9px;
  line-height: 18px;
  color: red;
}
@media (max-width: 1600px) {
  .form-text {
    font-size: 12px;
  }
}
@media (max-width: 1366px) {
  .form-text {
    font-size: 10px;
  }
}

:where(.css-dev-only-do-not-override-ph9edi).ant-badge.ant-badge-status .ant-badge-status-text {
  color: #899CA8;
}

.badges-list {
  display: flex;
  align-items: center;
}
.badges-list .badges-item {
  padding-right: 30px;
}
.badges-list .badges-item:last-child {
  padding-right: 0;
}

.dropdownboxed {
  background-color: white;
  border-radius: 12px;
  min-width: 166px;
  height: 52px;
  padding: 10px;
  display: flex;
  align-items: center;
  justify-content: space-between;
  border: 1px solid transparent;
}
.dropdownboxed:hover {
  border-color: #3B7AFE;
  cursor: pointer;
}
.dropdownboxed:focus, .dropdownboxed:active, .dropdownboxed:focus-visible {
  border-color: #3B7AFE;
}

.general-menu {
  width: 150px;
}

.ant-tabs-nav {
  padding: 6px;
  border-radius: 20px;
  background: white;
}
.ant-tabs-nav:before {
  content: normal !important;
}
.ant-tabs-nav .ant-tabs-tab {
  display: flex;
  flex-direction: row;
  justify-content: center;
  align-items: center;
  padding: 0px 26px;
  gap: 10px;
  width: auto;
  height: 45px;
  background: white;
  border-radius: 20px;
  flex: none;
  order: 0;
  flex-grow: 0;
  color: #899CA8;
}
.ant-tabs-nav .ant-tabs-tab.ant-tabs-tab-active {
  background: #EBF1FF;
  font-weight: 600;
  color: #3B7AFE;
}
.ant-tabs-nav .ant-tabs-ink-bar {
  display: none;
}

:where(.css-dev-only-do-not-override-ph9edi).ant-tabs .ant-tabs-tab-btn:focus:not(:focus-visible),
:where(.css-dev-only-do-not-override-ph9edi).ant-tabs .ant-tabs-tab-remove:focus:not(:focus-visible),
:where(.css-dev-only-do-not-override-ph9edi).ant-tabs .ant-tabs-tab-btn:active,
:where(.css-dev-only-do-not-override-ph9edi).ant-tabs .ant-tabs-tab-remove:active {
  color: #363636;
}

:where(.css-dev-only-do-not-override-ph9edi).ant-tabs .ant-tabs-tab-btn:focus:not(:focus-visible),
:where(.css-dev-only-do-not-override-ph9edi).ant-tabs .ant-tabs-tab-remove:focus:not(:focus-visible),
:where(.css-dev-only-do-not-override-ph9edi).ant-tabs .ant-tabs-tab-btn:active,
:where(.css-dev-only-do-not-override-ph9edi).ant-tabs .ant-tabs-tab-remove:active {
  color: #3B7AFE;
}

.ant-drawer-header {
  border: 0;
  border-bottom: 0 !important;
}
.ant-drawer-header .ant-drawer-header-title {
  flex-flow: row-reverse;
}

.greenTag {
  background: #E4F9E0;
  color: #5FBF24;
  border-color: #E4F9E0;
}

.yellowTag {
  background: #FFF6E8;
  color: #FF9900;
  border-color: #FFF6E8;
}

.ant-tag {
  padding: 5px 16px;
  border-radius: 8px;
  min-width: 80px;
  min-height: 30px;
  font-weight: 700;
  text-align: center;
}

.dashboard-body {
  margin: 30px 0;
}
.dashboard-body .dashboard-heading {
  font-family: "Inter";
  font-style: normal;
  font-weight: 700;
  font-size: 36px;
  line-height: 44px;
  color: #363636;
}
.dashboard-body .statistics-section {
  background: #ffffff;
  border: 1px solid #f5f1fe;
  border-radius: 20px;
  padding: 30px 0 40px 0;
}
.dashboard-body .statistics-section .stats {
  font-family: "Inter";
  font-style: normal;
  font-weight: 600;
  font-size: 22px;
  line-height: 22px;
  color: #363636;
  padding: 20px 0 0 30px;
}
.dashboard-body .patient-section {
  background: #ffffff;
  border: 1px solid #f5f1fe;
  border-radius: 20px;
  padding: 30px 0;
}
.dashboard-body .patient-section .patients {
  font-family: "Inter";
  font-style: normal;
  font-weight: 600;
  font-size: 22px;
  line-height: 22px;
  color: #363636;
  padding: 20px 0 0 30px;
}
.dashboard-body .patient-section canvas {
  height: 60% !important;
  width: 90% !important;
  margin: auto;
}
.dashboard-body .care-section {
  background: #ffffff;
  border: 1px solid #f5f1fe;
  border-radius: 20px;
  padding: 30px 10px 40px 10px;
  min-height: 430px;
}
.dashboard-body .care-section canvas {
  width: 90% !important;
  height: 200px !important;
  margin: auto;
  margin-top: 50px;
}
.dashboard-body .care-section .care {
  font-family: "Inter";
  font-style: normal;
  font-weight: 600;
  font-size: 22px;
  line-height: 27px;
  color: #363636;
  padding-left: 30px;
}
.dashboard-body .care-section [type=button]:not(:disabled),
.dashboard-body .care-section [type=reset]:not(:disabled),
.dashboard-body .care-section [type=submit]:not(:disabled),
.dashboard-body .care-section button:not(:disabled) {
  cursor: pointer;
  background: #f6f8fa;
  border: none;
  color: #363636;
  font-size: 12px;
  font-weight: 600;
  background: #f6f8fa;
  box-shadow: 0px 6px 58px rgba(196, 203, 214, 0.103611);
  border-radius: 10px;
  height: 38px;
}
.dashboard-body .plan-section {
  background: #ffffff;
  border: 1px solid #f5f1fe;
  border-radius: 20px;
  padding: 30px 10px 40px 10px;
  min-height: 430px;
}
.dashboard-body .plan-section canvas {
  max-width: 90% !important;
  max-height: 100% !important;
  margin: auto;
}
.dashboard-body .plan-section .care {
  font-family: "Inter";
  font-style: normal;
  font-weight: 600;
  font-size: 22px;
  line-height: 27px;
  color: #363636;
  padding-left: 30px;
}
.dashboard-body .plan-section [type=button]:not(:disabled),
.dashboard-body .plan-section [type=reset]:not(:disabled),
.dashboard-body .plan-section [type=submit]:not(:disabled),
.dashboard-body .plan-section button:not(:disabled) {
  cursor: pointer;
  background: #f6f8fa;
  border: none;
  color: #363636;
  font-size: 12px;
  font-weight: 600;
  background: #f6f8fa;
  box-shadow: 0px 6px 58px rgba(196, 203, 214, 0.103611);
  border-radius: 10px;
  height: 38px;
}

.dashboard-sidebar {
  background: #ffffff;
  border: 1px solid #f5f1fe;
  border-radius: 20px;
  min-height: 900px;
}
.dashboard-sidebar .ezfi-logo-dashboard {
  margin-top: 20px;
  margin-bottom: 30px;
  margin: 20px 0 30px 24px;
  width: 122px;
}
.dashboard-sidebar ul li {
  padding: 0 14px 0 7px;
  position: relative;
}
.dashboard-sidebar ul li a {
  padding: 11px 0 11px 15px;
  display: flex;
  border-radius: 12px;
}
.dashboard-sidebar ul li a.active {
  background-color: rgba(18, 106, 223, 0.1);
  color: #126adf;
}
.dashboard-sidebar ul li a.active::after {
  content: " ";
  position: absolute;
  background-color: #126adf;
  border-radius: 2px;
  border-radius: 50px;
  bottom: 2px;
  height: 44px;
  margin: 0;
  padding: 0;
  right: 0;
  width: 3.82px;
}

.all-section-top-gap {
  margin-top: 30px;
}

.notification-modal .modal-heading {
  font-family: "Inter";
  font-style: normal;
  font-weight: 700;
  font-size: 22px;
  line-height: 27px;
  color: #000000;
  padding: 10px !important;
}
.notification-modal .modal-content {
  border-radius: 15px;
  width: 100% !important;
}
.notification-modal .modal-dialog {
  margin-right: 250px;
  margin-top: 90px;
}
.notification-modal .modal-body .header h6 {
  font-weight: 500;
}
.notification-modal .modal-body .header h6 span {
  font-size: 18px;
  font-weight: 500;
}
.notification-modal .modal-body .header .btns-time {
  cursor: pointer;
  border-radius: 50%;
  width: 25px;
  height: 25px;
}
.notification-modal .modal-body .notification-container {
  height: calc(100vh - 235px);
  overflow-y: scroll;
}
.notification-modal .modal-body .notification-container::-webkit-scrollbar {
  display: none;
}

.LineGraph button:not(:disabled) {
  cursor: pointer;
  background: #f6f8fa;
  border: none;
  text-align: right;
  height: 48px;
  padding: 0 10px;
  border-radius: 14px;
  font-family: "Inter";
  font-style: normal;
  font-weight: 400;
  font-size: 15px;
  line-height: 24px;
  color: #363636;
}

@media (max-width: 900px) {
  .dashboard-body .css-o9k5xi-MuiInputBase-root-MuiOutlinedInput-root {
    width: 300px !important;
  }
}
.user-filter-btn {
  color: black;
}
.user-filter-btn button#dropdown-basic-button {
  background: #ffffff;
  box-shadow: 0px 6px 58px rgba(196, 203, 214, 0.103611);
  border-radius: 14px;
  min-width: 166px;
  min-height: 52px;
  border: none;
  text-align: left;
  position: relative;
}
.user-filter-btn .dropdown-toggle::after {
  display: inline-block;
  margin-left: 0.255em;
  vertical-align: 0.255em;
  content: "";
  background-image: url("/src/assets/images/arrow.png") !important;
  border-top: 0.3em solid;
  border-right: 0.3em solid transparent;
  border-bottom: 0;
  border-left: 0.3em solid transparent;
  color: #989898;
  position: absolute;
  right: 20px;
  top: 45%;
}

.validations {
  color: red !important;
  margin-top: 3px;
  margin-left: 5px;
  display: inherit;
}

.validation-margin form > div + div {
  margin-top: 18px;
}

/*======================  Dashboard CSS ===================*/
.stats-box {
  padding: 24px 30px;
  background-color: #fff;
  border: 1px solid #F5F1FE;
  border-radius: 20px;
  margin-bottom: 30px;
}

.dashboardLayoutContainer {
  padding: 20px 30px;
}

.icon-box p {
  font-size: 12px;
  color: #899ca8;
}

.treatment-wrapper {
  height: 100%;
}

.terminate-box {
  display: flex;
  align-items: center;
  justify-content: center;
  flex-flow: column;
  height: 100%;
  width: 100%;
}

.container,
.container-fluid {
  max-width: 1366px;
}

.Links {
  text-decoration: none !important;
}

.onboarding-container {
  background: #ffffff;
  border: 1px solid #f5f1fe;
  border-radius: 24px;
  margin: 20px 40px;
  overflow: hidden !important;
}
.onboarding-container .signin-container {
  padding-left: 95px;
}
.onboarding-container .signin-container .ezfi-logo {
  padding: 50px 0 60px 0;
}
.onboarding-container .signin-container .onboarding-heading {
  font-family: "Inter";
  font-style: normal;
  font-weight: 600;
  font-size: 24px;
  line-height: 29px;
  color: #363636;
}
.onboarding-container .signin-container .onboarding-text {
  font-family: "Inter";
  font-style: normal;
  font-weight: 500;
  font-size: 16px;
  line-height: 19px;
  color: #899ca8;
  padding-top: 10px;
}
.onboarding-container .signin-container .signinform {
  padding: 30px 0 0 0;
}
.onboarding-container .signin-container .onboarding-placeholders {
  width: 70%;
  height: 52px;
  background: #ffffff;
  border: 1px solid #f5f1fe;
  border-radius: 20px;
}
.onboarding-container .signin-container .onboarding-placeholders:focus {
  box-shadow: none !important;
  border: 1px solid #3b7afe;
}
.onboarding-container .signin-container .onboarding-placeholders:hover {
  border: 1px solid #3b7afe;
  transition: ease-in 0.3s;
}
.onboarding-container .signin-container .form-labels {
  font-family: "Inter";
  font-style: normal;
  font-weight: 400;
  font-size: 16px;
  line-height: 19px;
  color: #899ca8;
}
.onboarding-container .signin-container .form-labels span {
  color: red;
}
.onboarding-container .signin-container .forgot-password {
  /* Forgot Password? */
  font-family: "Inter";
  font-style: normal;
  font-weight: 500;
  font-size: 16px;
  line-height: 19px;
  color: #363636;
  padding-bottom: 25px;
}
.onboarding-container .signin-container .onboarding-btn {
  background: #3b7afe;
  box-shadow: 0px 11px 20px rgba(59, 122, 254, 0.16);
  border-radius: 100px;
  width: 70%;
  height: 52px;
  font-size: 16px;
}
.onboarding-container .signin-container .onboarding-btn:hover {
  transition: 0.5s;
  background: white;
  color: #3b7afe;
}
.onboarding-container .woman-img {
  max-width: 85%;
}

.otp .otp-input input {
  width: 55px !important;
  height: 50px;
  background: #ffffff;
  border: 1px solid #cfcdd6;
  border-radius: 20px;
  margin: 0 7px !important;
}
.otp .otp-input input:focus {
  outline: none;
  border: 3px solid #5fbf24 !important;
}

.resend-code {
  font-size: 16px;
  font-weight: 600;
  padding-top: 20px;
  color: #899ca8;
  margin-bottom: 20px;
}

nav a {
  color: red !important;
}

.validation-error {
  color: red;
}

.authenticationLayout {
  display: flex;
  align-items: center;
  justify-content: center;
  width: 100%;
  height: 100%;
  height: 100vh;
}
.authenticationLayout .wdth_1366 {
  width: 100%;
  height: 90%;
  max-width: 95%;
  margin: auto;
  overflow: scroll;
  background-size: contain;
  background-repeat: no-repeat;
  background-position: right center;
}
.authenticationLayout .wdth_1366 .container {
  max-width: 1196px;
}
.authenticationLayout .authLeftBox {
  display: flex;
  justify-content: flex-start;
  align-items: flex-start;
  padding: 6%;
}
@media (max-width: 1366px) {
  .authenticationLayout .authLeftBox {
    padding: 58px 95px;
  }
}

.logo-wrapper {
  margin-bottom: 60px;
}
@media (max-width: 1366px) {
  .logo-wrapper {
    margin-bottom: 50px;
  }
}

.bg_color_img {
  background: #ffffff;
  border: 1px solid #f5f1fe;
  border-radius: 24px;
}
.bg_color_img .mt_50 {
  margin-top: 50px;
}
.bg_color_img .main_form h1 {
  margin-bottom: 10px;
  /* Sign in */
  font-family: "Inter";
  font-style: normal;
  font-weight: 600;
  font-size: 24px;
  line-height: 29px;
  color: #363636;
}
.bg_color_img .main_form p {
  margin-bottom: 30px;
  font-family: "Inter";
  font-style: normal;
  font-weight: 500;
  font-size: 16px;
  color: #899ca8;
}
.bg_color_img .input_main {
  margin-bottom: 30px;
}
.bg_color_img .input_main .form-label {
  margin-bottom: 7px;
  font-family: "Inter";
  font-style: normal;
  font-weight: 400;
  font-size: 16px;
  color: #899ca8;
  line-height: 19px;
}
.bg_color_img .input_main span#basic-addon2 {
  background: white;
  border: 1px solid #f5f1fe;
  border-left: none !important;
  border-top-right-radius: 20px;
  border-bottom-right-radius: 20px;
  cursor: pointer;
}
.bg_color_img .input_main input {
  height: 52px;
  background: #ffffff;
  border: 1px solid #f5f1fe;
  border-radius: 20px;
  font-family: "Inter";
  font-style: normal;
  font-weight: 400;
  font-size: 14px;
  line-height: 17px;
  color: #363636;
  border-right: none;
}
.bg_color_img .input_main input:focus {
  box-shadow: none;
}
.bg_color_img .input_main input.hasError {
  border-color: red;
  border: 1px solid red;
}
.bg_color_img a {
  text-decoration: none;
}
.bg_color_img .forgot_pass {
  margin-bottom: 28px;
  font-weight: 500;
  font-size: 16px;
  color: #363636;
  text-decoration: none;
  display: inline-block;
}
.bg_color_img .forgot_pass:hover {
  color: #3b7afe;
}
.bg_color_img .main_btn button.main_form_btn {
  width: 100%;
  height: 52px;
  background: #3b7afe;
  box-shadow: 0px 11px 20px rgba(59, 122, 254, 0.16);
  border-radius: 100px;
  font-family: "Inter";
  font-style: normal;
  font-weight: 500;
  font-size: 18px;
  line-height: 22px;
  text-align: center;
  color: #ffffff;
}
.bg_color_img .main_btn button.main_form_btn:focus {
  box-shadow: none;
}

.form_star {
  color: red;
}

@media (max-width: 1200px) {
  .bg_color_img .input_main {
    margin-bottom: 18px;
  }
  .bg_color_img a {
    text-decoration: none;
  }
  .bg_color_img .main_btn button.main_form_btn {
    width: 100%;
    height: 52px;
  }
}
.mb_20 {
  margin-bottom: 30px !important;
}

.signupHere {
  padding-top: 20px;
}
.signupHere p {
  font-weight: 400;
  font-size: 14px;
  line-height: 17px;
  text-align: center;
  color: #899CA8;
  margin: 0;
}
.signupHere h4 {
  font-weight: 500;
  font-size: 16px;
  line-height: 26px;
  text-align: center;
  color: #5FBF24;
  margin: 0;
}

.Responsive_Navbar .navbar {
  background-color: #FFFFFF !important;
  padding: 15px 0;
}
.Responsive_Navbar .navbar .res-img {
  width: 48px;
  height: 48px;
}
.Responsive_Navbar .navbar .notification-box {
  background: #ffffff;
  border: 1px solid #f5f1fe;
  border-radius: 26px;
  width: 48px;
  height: 48px;
  cursor: pointer;
}
.Responsive_Navbar .navbar .notification-box svg {
  margin: 12px 12px 0 0;
}
.Responsive_Navbar .navbar .notification-box:hover {
  background-color: #3b7afe;
  transition: 0.5s;
}
.Responsive_Navbar .navbar .notification-box:hover path {
  fill: white;
}
.Responsive_Navbar .navbar .navbar-toggler {
  text-decoration: none;
  background-color: white;
  border-radius: 10px;
  border: 1px solid #F5F1FE;
}
.Responsive_Navbar .navbar .navbar-toggler:focus {
  box-shadow: none;
  outline: none;
  border: none;
}

.navbar-nav a {
  text-decoration: none;
  font-family: "Inter";
  font-style: normal;
  font-weight: 400;
  font-size: 18px;
  line-height: 15px;
  color: #899ca8;
  cursor: pointer;
  padding-left: 10px;
  margin-top: 20px;
}
.navbar-nav a path {
  fill: #899ca8;
}
.navbar-nav a:hover {
  color: #3b7afe;
  transition: 0.3s;
}
.navbar-nav a:hover path {
  fill: #3b7afe;
}
.navbar-nav a.active {
  background-color: #3B7AFE;
  padding: 10px 10px;
  border-radius: 12px;
  color: #F5F1FE;
}
.navbar-nav a.active path {
  fill: #F5F1FE;
}

@media (max-width: 1200px) {
  .responsive-hide {
    display: none;
  }
}
.logout-modal {
  border-radius: 10px;
}
.logout-modal .modal-header {
  font-family: "Inter";
  font-style: normal;
  font-weight: 600;
  font-size: 22px;
  line-height: 27px;
  color: #363636;
  border: none;
}
.logout-modal .modal-header .modal-content .modal_main_content {
  padding: 27px !important;
}
.logout-modal .modal-body {
  /* Are you sure you want to log out? */
  font-family: "Nunito Sans", "Inter";
  font-style: normal;
  font-weight: 400;
  font-size: 20px;
  line-height: 141.5%;
  /* or 28px */
  /* 3 */
  color: #899ca8;
  padding: 0 16px;
}
.logout-modal .modal-footer {
  border: none;
  padding: 50px 12px;
}

.modal_blue_btn {
  background: #3b7afe;
  box-shadow: 0px 11px 20px rgba(59, 122, 254, 0.16);
  border-radius: 100px;
  width: 167px;
  height: 52px;
  font-family: "Inter";
  font-style: normal;
  font-weight: 500;
  font-size: 18px;
  line-height: 22px;
  text-align: center;
  color: #ffffff;
}
.modal_blue_btn:hover {
  background: #ffffff;
  color: #3b7afe;
  transition: 0.2s ease-in;
}

.modal_gray_btn {
  /* Cancel */
  font-family: "Inter";
  font-style: normal;
  font-weight: 500;
  font-size: 18px;
  line-height: 22px;
  text-align: center;
  color: #899ca8;
  background: none;
  border: none;
}
.modal_gray_btn:focus, .modal_gray_btn:hover {
  background: none;
  border: none;
}

.modal-content {
  width: 460px;
  border-radius: 10px;
}

.modal_main_content {
  padding: 25px;
}

.ezfi-modal .modal-title {
  font-family: "Inter";
  font-style: normal;
  font-weight: 600;
  font-size: 22px;
  line-height: 27px;
  color: #363636;
  margin-bottom: 25px;
}
.ezfi-modal .modal-header {
  padding: 0;
  border-bottom: none;
}
.ezfi-modal .modal-body {
  padding: 0;
}
.ezfi-modal .modal-footer {
  padding: 0;
  border: none;
}
.ezfi-modal .btn-close {
  margin-bottom: 20px;
}

.fade.customModal.undefined.modal.show {
  padding-left: 0 !important;
}

.filter-txt {
  /* Evan Yates */
  font-family: "Inter";
  font-style: normal;
  font-weight: 500;
  font-size: 14px;
  line-height: 24px;
  color: #899ca8;
  display: inline-block;
  padding-left: 9px;
  margin-right: 10px;
}

.dropdown-menu.show {
  background: #ffffff;
  border: 1px solid #f5f1fe;
  border-radius: 20px;
  font-family: "Nunito Sans", "Inter";
  font-style: normal;
  font-weight: 400;
  font-size: 14px;
  line-height: 19px;
  color: #363636;
  height: 103px;
  width: 157px;
  align-items: center;
  padding: 20px 10px;
}

.sure {
  /* Are you sure you want to log out? */
  font-family: "Nunito Sans", "Inter";
  font-style: normal;
  font-weight: 400;
  font-size: 20px;
  line-height: 141.5%;
  /* or 28px */
  /* 3 */
  color: #899ca8;
}

.delete-modal-btns {
  margin-top: 58px;
}

/* request modal */
.main-request-detail .image-name-div {
  display: flex;
  align-items: center;
}
.main-request-detail .image-name-div .img-div {
  max-width: 64px;
  height: 64px;
  border-radius: 50%;
  border: 2px solid #ffffff;
  overflow: hidden;
}
.main-request-detail .image-name-div .img-div img {
  width: 100%;
  height: 100%;
  -o-object-fit: cover;
     object-fit: cover;
}
.main-request-detail .image-name-div span {
  font-weight: 700;
  font-size: 16px;
  color: #121212;
  margin-left: 10px;
}
.main-request-detail .request-content-div {
  margin-bottom: 47px;
}

.greyBlackList {
  margin-top: 32px;
}
.greyBlackList li p {
  font-weight: 400;
  font-size: 14px;
  color: #363636;
  display: flex;
  align-items: flex-start;
}
.greyBlackList li .title {
  font-weight: 700;
  font-size: 14px;
  line-height: 17px;
  color: #899ca8;
  min-width: 122px;
  display: inline-block;
}

.request-btn-div {
  display: flex;
  justify-content: end;
  align-items: center;
}

.settings-container {
  background: #ffffff;
  border: 1px solid #f5f1fe;
  border-radius: 20px;
}

.settings-body {
  margin: 30px 0;
}
.settings-body .settings-heading {
  font-family: "Inter";
  font-style: normal;
  font-weight: 700;
  font-size: 36px;
  line-height: 44px;
  color: #363636;
  margin-bottom: 30px;
}
.settings-body .settings-bar {
  background: #ffffff;
  border: 1px solid #f5f1fe;
  border-radius: 20px;
  height: 750px;
  border: 1px solid #f5f1fe;
  border-radius: 20px;
}
.settings-body .settings-bar .settings-navigation {
  margin-top: 30px;
}
.settings-body .settings-bar .settings-navigation ul {
  text-decoration: none;
  list-style: none;
  padding-left: 20px;
}
.settings-body .settings-bar .settings-navigation ul li {
  margin-top: 22px;
}
.settings-body .settings-bar .settings-navigation ul li a {
  text-decoration: none;
  color: #899ca8;
  font-family: "Inter";
  font-style: normal;
  font-weight: 400;
  font-size: 14px;
  line-height: 17px;
  padding-left: 7px;
  /* identical to box height */
  /* 3 */
}
.settings-body .settings-bar .settings-navigation ul li a .content-nav-bg {
  padding-left: 7px;
}
.settings-body .settings-bar .settings-navigation ul li a .settings-icon {
  padding-right: 10px;
}
.settings-body .settings-bar .settings-navigation ul li a.active {
  color: #3b7afe;
  font-weight: 700;
  transition: 0.2s ease-out;
  text-align: center;
}
.settings-body .settings-bar .settings-navigation ul li a.active .content-nav-bg {
  background-color: rgba(59, 121, 254, 0.1450980392);
  width: 220px;
  height: 44px;
  display: flex;
  align-items: center;
  border-radius: 10px;
  padding-left: 7px;
}
.settings-body .settings-bar .settings-navigation ul li a.active path {
  fill: #3b7afe;
}

.profile-component {
  margin: 30px 35px;
}
.profile-component .edit-icon svg {
  cursor: pointer;
}
.profile-component .profile-title {
  margin-bottom: 32px;
}
.profile-component .profile-title .profile-name {
  /* Alex Joe */
  font-family: "Inter";
  font-style: normal;
  font-weight: 700;
  font-size: 16px;
  line-height: 19px;
  color: #121212;
  margin-left: 10px;
}
.profile-component .profile-details .role {
  /* Role: */
  font-family: "Inter";
  font-style: normal;
  font-weight: 700;
  font-size: 14px;
  line-height: 17px;
  color: #899ca8;
  width: 100px;
}
.profile-component .profile-details .admin {
  /* Admin */
  font-family: "Inter";
  font-style: normal;
  font-weight: 400;
  font-size: 14px;
  line-height: 17px;
  /* identical to box height */
  /* 4 */
  color: #363636;
}
.profile-component .profile-details .mt_17 {
  margin-top: 17px;
}

.changepassword-component {
  margin: 30px;
}
.changepassword-component h2 {
  /* Change Password */
  font-family: "Nunito Sans", "Inter";
  font-style: normal;
  font-weight: 700;
  font-size: 22px;
  line-height: 30px;
  color: #121212;
  margin-bottom: 30px;
}
.changepassword-component .input_setting {
  margin-bottom: 18px;
}
.changepassword-component .input_setting .form-label {
  margin-bottom: 7px;
  font-family: "Inter";
  font-style: normal;
  font-weight: 400;
  font-size: 16px;
  color: #899ca8;
  line-height: 19px;
}
.changepassword-component .input_setting span#basic-addon2 {
  background: #ffffff;
  border: 1px solid #d8e0f0;
  border-radius: 7px;
  cursor: pointer;
  border-top-left-radius: 0;
  border-bottom-left-radius: 0;
}
.changepassword-component .input_setting input {
  box-sizing: border-box;
  background: #ffffff;
  border: 1px solid #d8e0f0;
  border-radius: 7px;
  height: 50px;
  border-right: none;
}
.changepassword-component .input_setting input:focus {
  box-shadow: none;
}
.changepassword-component .save_btn {
  width: 167px;
  height: 52px;
  background: #3b7afe;
  box-shadow: 0px 11px 20px rgba(59, 122, 254, 0.16);
  border-radius: 100px;
}

.deleteaccount-component {
  margin: 30px;
}
.deleteaccount-component .deleteaccount-heading {
  /* Delete Account */
  font-family: "Nunito Sans", "Inter";
  font-style: normal;
  font-weight: 700;
  font-size: 22px;
  line-height: 30px;
  color: #121212;
}
.deleteaccount-component p {
  font-family: "Nunito Sans", "Inter";
  font-style: normal;
  font-weight: 400;
  font-size: 14px;
  line-height: 24px;
  color: #989898;
  margin: 20px 0 25px 0;
}
.deleteaccount-component .input_setting {
  margin-bottom: 18px;
}
.deleteaccount-component .input_setting .form-label {
  margin-bottom: 7px;
  font-family: "Inter";
  font-style: normal;
  font-weight: 400;
  font-size: 16px;
  color: #899ca8;
  line-height: 19px;
}
.deleteaccount-component .input_setting span#basic-addon2 {
  background: #ffffff;
  border: 1px solid #d8e0f0;
  border-radius: 7px;
  cursor: pointer;
  border-top-left-radius: 0;
  border-bottom-left-radius: 0;
}
.deleteaccount-component .input_setting input {
  box-sizing: border-box;
  background: #ffffff;
  border: 1px solid #d8e0f0;
  border-radius: 7px;
  height: 50px;
  border-right: none;
}
.deleteaccount-component .input_setting input:focus {
  box-shadow: none;
}
.deleteaccount-component .save_btn {
  width: 167px;
  height: 52px;
  background: #3b7afe;
  box-shadow: 0px 11px 20px rgba(59, 122, 254, 0.16);
  border-radius: 100px;
}

.ez-statistics-container {
  background: #ffffff;
  border: 1px solid #f5f1fe;
  border-radius: 20px;
  padding: 22px 30px;
}
.ez-statistics-container img {
  min-width: 48px;
}
.ez-statistics-container .heading {
  font-family: "Inter";
  font-style: normal;
  font-weight: 600;
  font-size: 18px;
  line-height: 22px;
  color: #363636;
  margin-bottom: 32px;
}
.ez-statistics-container h3 {
  font-family: "Inter";
  font-style: normal;
  font-weight: 600;
  font-size: 18px;
  line-height: 22px;
  color: #363636;
  margin-bottom: 0;
}
.ez-statistics-container p {
  font-family: "Inter";
  font-style: normal;
  font-weight: 400;
  font-size: 12px;
  line-height: 18px;
  color: #899ca8;
}
.ez-statistics-container .ml_20 {
  margin-left: 20px;
}

.ez-graph-container {
  background: #ffffff;
  border: 1px solid #f5f1fe;
  border-radius: 20px;
  padding: 30px;
  margin-top: 25px;
}
.ez-graph-container h3 {
  background: #ffffff;
  border: 1px solid #f5f1fe;
  border-radius: 20px;
}
.ez-graph-container .graph-section {
  padding: 30px;
}

.ez-pay-dashboard button:not(:disabled) {
  cursor: pointer;
  background: #ffffff;
  border: none;
  text-align: right;
  height: 48px;
  padding: 0 10px;
  border-radius: 14px;
  font-family: "Inter";
  font-style: normal;
  font-weight: 400;
  font-size: 15px;
  line-height: 24px;
  color: #363636;
}
.ez-pay-dashboard .gross-sales-bg {
  background: #ffffff;
  border: 1px solid #f5f1fe;
  border-radius: 20px;
  min-height: 217px;
}
.ez-pay-dashboard .gross-sales-bg h3 {
  font-family: "Inter";
  font-style: normal;
  font-weight: 400;
  font-size: 14px;
  line-height: 23px;
  color: #899ca8;
  margin: 16px 0 7px 19px;
}
.ez-pay-dashboard .gross-sales-bg h2 {
  font-family: "Inter";
  font-style: normal;
  font-weight: 600;
  font-size: 20px;
  line-height: 24px;
  color: #363636;
  margin-left: 20px;
}
.ez-pay-dashboard .gross-sales-bg canvas {
  padding: 0 10px;
}
.ez-pay-dashboard .revenue-bg {
  background: #ffffff;
  border: 1px solid #f5f1fe;
  border-radius: 20px;
  min-height: 377px;
  padding: 30px;
}
.ez-pay-dashboard .revenue-bg .form-select {
  background-color: #f6f8fa;
  box-shadow: 0px 6px 58px rgba(196, 203, 214, 0.103611);
  border-radius: 10px;
  font-family: "Inter";
  font-style: normal;
  font-weight: 600;
  font-size: 12px;
  line-height: 24px;
  color: #363636;
  margin-bottom: 10px;
}
.ez-pay-dashboard .revenue-bg .form-select:focus {
  border: none;
}
.ez-pay-dashboard .revenue-bg h2 {
  font-family: "Inter";
  font-style: normal;
  font-weight: 700;
  font-size: 22px;
  line-height: 30px;
  color: #0a1629;
  margin-bottom: 30px;
}
.ez-pay-dashboard .earning-bg {
  background: #ffffff;
  border: 1px solid #f5f1fe;
  border-radius: 20px;
  min-height: 163px;
  margin-top: 32px;
  padding: 25px;
}
.ez-pay-dashboard .earning-bg h2 {
  font-family: "Inter";
  font-style: normal;
  font-weight: 600;
  font-size: 15.2528px;
  line-height: 24px;
  color: #363636;
  margin-bottom: 0;
}
.ez-pay-dashboard .earning-bg p {
  font-family: "Inter";
  font-style: normal;
  font-weight: 400;
  font-size: 12.2022px;
  line-height: 18px;
  color: #899ca8;
  margin-top: 12px;
  margin-bottom: 0;
}
.ez-pay-dashboard .plans-graph-container {
  background: #ffffff;
  border: 1px solid #f5f1fe;
  border-radius: 20px;
  margin-top: 30px;
  min-height: 354px;
  padding: 32px;
}
.ez-pay-dashboard .plans-graph-container .stacked-chart {
  margin-top: 30px;
}
.ez-pay-dashboard .plans-graph-container .form-select {
  background-color: #f6f8fa;
  box-shadow: 0px 6px 58px rgba(196, 203, 214, 0.103611);
  border-radius: 10px;
  font-family: "Inter";
  font-style: normal;
  font-weight: 600;
  font-size: 12px;
  line-height: 24px;
  color: #363636;
  margin-bottom: 10px;
}
.ez-pay-dashboard .plans-graph-container .form-select:focus {
  border: none;
}
.ez-pay-dashboard .plans {
  font-family: "Inter";
  font-style: normal;
  font-weight: 600;
  font-size: 22px;
  line-height: 27px;
  color: #363636;
  margin-bottom: 30px;
}
.ez-pay-dashboard .ezpay-table {
  background: #ffffff;
  border: 1px solid #f5f1fe;
  border-radius: 20px;
  min-height: 355px;
  margin-top: 30px;
  padding-top: 30px;
}
.ez-pay-dashboard .ezpay-table .table-padding {
  border: none;
  padding: 0 30px 0 30px;
}
.ez-pay-dashboard .ezpay-table .table-padding .membership-plan {
  font-family: "Inter";
  font-style: normal;
  font-weight: 600;
  font-size: 24px;
  line-height: 29px;
  color: #363636;
}
.ez-pay-dashboard .ezpay-table .table-padding .view-membership-btn {
  background: #ffffff;
  border: 0.5px solid #5fbf24;
  box-shadow: 0px 6px 58px rgba(196, 203, 214, 0.3036);
  border-radius: 14px;
  color: #5fbf24;
  padding: 13px;
}
.ez-pay-dashboard .ezpay-table .table-padding .view-membership-btn:focus {
  box-shadow: 0px 6px 58px rgba(196, 203, 214, 0.3036);
  background: #5fbf24;
  color: white;
}
.ez-pay-dashboard .ezpay-table .table-padding .view-membership-btn:hover {
  box-shadow: 0px 6px 58px rgba(196, 203, 214, 0.3036);
  background: #5fbf24;
  color: white;
  transition: 0.3s;
}

.proposal-profile .default-heading {
  font-family: "Inter";
  font-style: normal;
  font-weight: 700;
  font-size: 20px;
  line-height: 24px;
  color: #363636;
}
.proposal-profile .add-new-btn {
  padding: 15px 41px;
}
.proposal-profile .contract {
  background: #ffffff;
  border: 1px solid #f5f1fe;
  border-radius: 20px;
  padding: 20px;
  min-height: 129px;
}
.proposal-profile .contract .contract-heading {
  font-family: "Inter";
  font-style: normal;
  font-weight: 700;
  font-size: 20px;
  line-height: 24px;
  color: #363636;
}
.proposal-profile .status-container .status {
  font-family: "Inter";
  font-style: normal;
  font-weight: 400;
  font-size: 14px;
  line-height: 17px;
  color: #899ca8;
  margin-top: 20px;
  margin-bottom: 27px;
}
.proposal-profile .status-container .status-response {
  font-family: "Inter";
  font-style: normal;
  font-weight: 400;
  font-size: 14px;
  line-height: 17px;
  color: #363636;
}
.proposal-profile .proposal-payment-plan .plan-no {
  font-family: "Inter";
  font-style: normal;
  font-weight: 500;
  font-size: 14px;
  line-height: 17px;
  color: #363636;
  margin-top: 5px;
}
.proposal-profile .proposal-payment-plan .plan-desc {
  font-family: "Inter";
  font-style: normal;
  font-weight: 400;
  font-size: 14px;
  line-height: 24px;
  color: #899ca8;
  margin-top: 10px;
}
.proposal-profile .proposal-payment-plan .plan-conditions {
  /* Payment Source: Split */
  font-family: "Inter";
  font-style: normal;
  font-weight: 400;
  font-size: 14px;
  line-height: 24px;
  color: #363636;
  padding-left: 12px;
}
.proposal-profile .proposal-payment-plan .payment-condition-answer {
  font-family: "Inter";
  font-style: normal;
  font-weight: 600;
  font-size: 14px;
  line-height: 24px;
  color: #3b7afe;
  padding-left: 8px;
}
.proposal-profile .proposal-payment-plan .mt_10 {
  margin-top: 10px;
}
.proposal-profile .cost-of-service {
  /* Cost of Services */
  font-family: "Inter";
  font-style: normal;
  font-weight: 700;
  font-size: 20px;
  line-height: 24px;
  color: #363636;
  margin-top: 30px;
  margin-bottom: 20px;
}
.proposal-profile .cost-of-service-container {
  background: #ffffff;
  border: 1px solid #f5f1fe;
  border-radius: 20px;
  min-height: 627px;
}
.proposal-profile .financing-plan-container .plan-no {
  font-family: "Inter";
  font-style: normal;
  font-weight: 500;
  font-size: 14px;
  line-height: 17px;
  color: #363636;
  margin-top: 5px;
}
.proposal-profile .financing-plan-container .plan-desc {
  font-family: "Inter";
  font-style: normal;
  font-weight: 400;
  font-size: 14px;
  line-height: 24px;
  color: #899ca8;
  margin-top: 10px;
}
.proposal-profile .financing-plan-container .plan-conditions {
  /* Payment Source: Split */
  font-family: "Inter";
  font-style: normal;
  font-weight: 400;
  font-size: 14px;
  line-height: 24px;
  color: #363636;
}
.proposal-profile .financing-plan-container .payment-condition-answer {
  font-family: "Inter";
  font-style: normal;
  font-weight: 600;
  font-size: 14px;
  line-height: 24px;
  color: #3b7afe;
  padding-left: 8px;
}
.proposal-profile .financing-plan-container .mt_10 {
  margin-top: 10px;
}
.proposal-profile .financing-plan-container .pt_12 {
  padding-left: 12px;
}

.fee-schedule-upload-btn {
  border: 1px solid #3b7afe;
  filter: drop-shadow(0px 11px 20px rgba(59, 122, 254, 0.16));
  border-radius: 100px;
  background: #f5f1fe;
  color: #3b7afe;
  padding: 13px 19px;
  font-size: 18px;
  text-align: center;
  font-weight: 500;
}
.fee-schedule-upload-btn:hover, .fee-schedule-upload-btn:focus {
  background: #3b7afe;
  border: 1px solid #f5f1fe;
  color: #ffffff;
  outline: none;
  box-shadow: none;
}
.fee-schedule-upload-btn:hover path, .fee-schedule-upload-btn:focus path {
  fill: #f5f1fe;
}

.add-new-btn {
  background: #3b7afe;
  box-shadow: 0px 11px 20px rgba(59, 122, 254, 0.16);
  border-radius: 100px;
  /* Add New */
  font-family: "Inter";
  font-style: normal;
  font-weight: 500;
  font-size: 18px;
  line-height: 22px;
  text-align: center;
  color: #ffffff;
  min-width: 167px;
  min-height: 52px;
}
.add-new-btn.active {
  background: #3b7afe;
  outline: none;
  box-shadow: none;
  border: none;
}
.add-new-btn:focus {
  background: #3b7afe !important;
  outline: none;
  box-shadow: none !important;
  border: none;
}
.add-new-btn:hover {
  background: white !important;
  color: #3b7afe;
  outline: none;
  box-shadow: none !important;
  border: 1px solid #3b7afe;
  transition: 0.2s ease-in;
}

.filter-btn {
  background: #ffffff;
  box-shadow: 0px 6px 58px rgba(196, 203, 214, 0.103611);
  border-radius: 14px;
  padding: 15px 27px;
  border: none;
  font-family: "Inter";
  font-style: normal;
  font-weight: 500;
  font-size: 14px;
  line-height: 24px;
  color: #899ca8;
}

.datagrid-table {
  border: none !important;
}

.css-1e2bxag-MuiDataGrid-root .MuiDataGrid-iconSeparator,
.css-ptiqhd-MuiSvgIcon-root {
  display: block !important;
}

.css-1e2bxag-MuiDataGrid-root .MuiDataGrid-iconSeparator {
  color: inherit;
  display: none !important;
}

.css-1e2bxag-MuiDataGrid-root .MuiDataGrid-columnHeader:hover .MuiDataGrid-menuIcon {
  display: none !important;
}

.css-1jbbcbn-MuiDataGrid-columnHeaderTitle {
  color: #a1a0a0;
}

.table-container {
  background: #ffffff;
  border: 1px solid #f5f1fe;
  border-radius: 20px;
  min-height: 725px;
}

.MuiDataGrid-root .MuiDataGrid-cell {
  border-bottom: none !important;
}

.breadcrumbs ol.breadcrumb {
  margin: 0;
}
.breadcrumbs nav a {
  font-family: "Inter";
  font-style: normal;
  font-weight: 400;
  font-size: 16px;
  color: #899ca8 !important;
  text-decoration: none;
  margin: 0 !important;
}

.eyehide-bg {
  background: #ffffff;
  box-shadow: 0px 6px 58px rgba(196, 203, 214, 0.103611);
  border-radius: 14px;
  width: 52px;
  height: 52px;
  border: none !important;
}
.eyehide-bg:focus {
  box-shadow: none;
  outline: none;
  background: #3b7afe;
}
.eyehide-bg:focus path {
  fill: white !important;
}
.eyehide-bg:hover {
  background: #3b7afe;
  transition: 0.3s;
  border: #3b7afe;
}
.eyehide-bg:hover path {
  fill: white !important;
}

.payment-card-bg {
  background: #ffffff;
  border: 1px solid #f5f1fe;
  border-radius: 20px;
  width: 362px;
  height: 321px;
  margin: auto;
  margin-top: 20px;
}
.payment-card-bg .card-container {
  padding: 20px;
}
.payment-card-bg .card-container h2 {
  /* Orthodontic Treatment */
  font-family: "Inter";
  font-style: normal;
  font-weight: 700;
  font-size: 18px;
  line-height: 22px;
  color: #363636;
  margin-bottom: 10px;
}
.payment-card-bg .card-container p {
  font-family: "Inter";
  font-style: normal;
  font-weight: 400;
  font-size: 14px;
  line-height: 24px;
  color: #899ca8;
  margin-bottom: 26px;
}
.payment-card-bg .card-container hr {
  opacity: 0.1;
  /* 3 */
  border: 1px solid #899ca8;
}
.payment-card-bg .card-container .three-dots-bg {
  background: #fafafb;
  box-shadow: 0px 6px 58px rgba(196, 203, 214, 0.103611);
  border-radius: 8px;
  height: 30px;
  width: 30px;
}
.payment-card-bg .card-container .payment-source {
  /* Payment Source: Third Party */
  font-family: "Inter";
  font-style: normal;
  font-weight: 400;
  font-size: 14px;
  line-height: 24px;
  margin-left: 12px;
  color: #363636;
}
.payment-card-bg .card-container .third-party {
  color: #3b7afe;
  font-size: 14px;
  font-weight: 600;
  font-family: "Inter";
  line-height: 24px;
  margin-left: 12px;
}
.payment-card-bg .card-container .mb_10 {
  margin-bottom: 10px;
}
.payment-card-bg .card-container .mt_20 {
  margin-top: 20px;
}

.patient-card-bg {
  background: #ffffff;
  border: 1px solid #f5f1fe;
  border-radius: 20px;
  width: 100%;
  height: auto;
  margin: auto;
  margin-top: 20px;
}
.patient-card-bg .three-dots-bg {
  background: #fafafb;
  box-shadow: 0px 6px 58px rgba(196, 203, 214, 0.103611);
  border-radius: 8px;
  height: 30px;
  width: 30px;
  cursor: pointer;
}
.patient-card-bg .patient-card-container {
  padding: 22px 30px;
}
.patient-card-bg .patient-title {
  /* Peter Paul */
  font-family: "Inter";
  font-style: normal;
  font-weight: 500;
  font-size: 18px;
  line-height: 22px;
  color: #0a1629;
  padding-left: 10px;
}
.patient-card-bg .patient-data {
  /* Amount Received: */
  font-family: "Inter";
  font-style: normal;
  font-weight: 400;
  font-size: 14px;
  line-height: 17px;
  /* identical to box height */
  /* 3 */
  color: #899ca8;
}
.patient-card-bg .patient-data-info {
  font-family: "Inter";
  font-style: normal;
  font-weight: 700;
  font-size: 14px;
  line-height: 17px;
  /* identical to box height */
  text-align: right;
  /* 4 */
  color: #363636;
}
.patient-card-bg .mt_10 {
  margin-top: 10px;
}
.patient-card-bg .mt_15 {
  margin-top: 15px;
}
.patient-card-bg hr {
  opacity: 0.1;
  /* 3 */
  border: 1px solid rgba(137, 156, 168, 0.9254901961);
}
.patient-card-bg .icon-text {
  font-family: "Inter";
  font-style: normal;
  font-weight: 400;
  font-size: 13px;
  line-height: 17px;
  /* identical to box height */
  /* 3 */
  color: #899ca8;
  margin-left: 15px;
}
.patient-card-bg .pt_20 {
  padding-top: 20px;
}

.corporate-clients-bg {
  background: #ffffff;
  border: 1px solid #f5f1fe;
  border-radius: 20px;
}
.corporate-clients-bg .corporate-clients-container {
  padding: 28px 22px;
}
.corporate-clients-bg .corporate-clients-container h2 {
  /* KFC */
  font-family: "Inter";
  font-style: normal;
  font-weight: 500;
  font-size: 18px;
  line-height: 22px;
  color: #0a1629;
  padding-left: 10px;
}
.corporate-clients-bg .corporate-clients-container .three-dots-bg {
  background: #fafafb;
  box-shadow: 0px 6px 58px rgba(196, 203, 214, 0.103611);
  border-radius: 8px;
  height: 30px;
  width: 30px;
  cursor: pointer;
}
.corporate-clients-bg .corporate-clients-container .client-data {
  /* Amount Received: */
  font-family: "Inter";
  font-style: normal;
  font-weight: 400;
  font-size: 14px;
  line-height: 17px;
  /* identical to box height */
  /* 3 */
  color: #899ca8;
}
.corporate-clients-bg .corporate-clients-container .client-data-info {
  font-family: "Inter";
  font-style: normal;
  font-weight: 700;
  font-size: 14px;
  line-height: 17px;
  /* identical to box height */
  text-align: right;
  /* 4 */
  color: #363636;
}
.corporate-clients-bg .corporate-clients-container .mt_10 {
  margin-top: 10px;
}
.corporate-clients-bg .corporate-clients-container .mt_15 {
  margin-top: 15px;
}
.corporate-clients-bg .corporate-clients-container hr {
  opacity: 0.1;
  /* 3 */
  border: 1px solid rgba(137, 156, 168, 0.9254901961);
}
.corporate-clients-bg .corporate-clients-container .icon-text {
  font-family: "Inter";
  font-style: normal;
  font-weight: 400;
  font-size: 13px;
  line-height: 17px;
  /* identical to box height */
  /* 3 */
  color: #899ca8;
  margin-left: 15px;
}
.corporate-clients-bg .corporate-clients-container .pt_20 {
  padding-top: 20px;
}

.view-iconlist {
  width: 100%;
  height: 100%;
  display: flex;
  align-items: center;
  justify-content: flex-end;
}
.view-iconlist .view-iconItem:hover {
  cursor: pointer;
}
.view-iconlist .view-iconItem:hover svg > *:not(:first-child) {
  fill: #3B7AFE;
}
.view-iconlist .view-iconItem.active svg > *:not(:first-child) {
  fill: #3B7AFE;
}
.view-iconlist .view-iconItem:first-child {
  margin-right: 20px;
}

.content-management {
  padding: 37px 30px;
  text-align: left;
}
.content-management h2 {
  font-family: "Inter";
  font-style: normal;
  font-weight: 600;
  font-size: 22px;
  line-height: 27px;
  color: #121212;
  padding-top: 10px;
}
.content-management .terms {
  font-family: "Inter";
  font-style: normal;
  font-weight: 400;
  font-size: 14px;
  line-height: 24px;
  color: #121212;
}
.content-management .mt_10 {
  margin-top: 10px;
}
.content-management .mt_30 {
  margin-top: 25px;
}

.faq-accordion {
  margin-top: 20px;
}
.faq-accordion .accordion {
  border: none;
}
.faq-accordion .accordion button {
  background: none;
  border: none;
  font-family: "Inter";
  font-style: normal;
  font-weight: 500;
  font-size: 16px;
  line-height: 19px;
  color: #121212;
  border-bottom: 0;
}
.faq-accordion .accordion button:focus,
.faq-accordion .accordion button .active {
  border: none;
  box-shadow: none;
  outline: none;
}
.faq-accordion .accordion .accordion-item {
  border: none;
}
.faq-accordion .accordion .accordion-body {
  font-family: "Inter";
  font-style: normal;
  font-weight: 400;
  font-size: 14px;
  line-height: 24px;
  color: #989898;
  border: none;
}
.faq-accordion .accordion .accordion-button::after {
  background-image: url("/src/assets/images/arrow.png");
  background-size: 80% !important;
}

.membership-bg {
  background: #ffffff;
  border: 1px solid #f5f1fe;
  border-radius: 20px;
}
.membership-bg .membership-container {
  padding: 12px 30px;
}
.membership-bg .membership-container .membership-label {
  font-family: "Inter";
  font-style: normal;
  font-weight: 400;
  font-size: 16px;
  line-height: 19px;
  margin-top: 18px;
  margin-bottom: 8px;
  /* body copy */
  color: #899ca8;
}
.membership-bg .membership-container label {
  font-family: "Inter";
  font-style: normal;
  font-weight: 400;
  font-size: 14px;
  line-height: 17px;
  color: #363636;
}
.membership-bg .membership-container .form-check-inline {
  margin-right: 2rem;
  margin-top: 10px;
}
.membership-bg .membership-container select.form-select {
  background: #ffffff;
  border: 1px solid #f5f1fe;
  border-radius: 20px;
}
.membership-bg .membership-container select.form-select:focus {
  box-shadow: none !important;
}
.membership-bg .membership-container .input-plan input {
  height: 52px;
  background: #ffffff;
  border: 1px solid #f5f1fe;
  border-radius: 20px;
  padding: 15px;
}
.membership-bg .membership-container .input-plan input:focus {
  box-shadow: none;
}
.membership-bg .membership-container textarea {
  height: 83px;
  background: #ffffff;
  border: 1px solid #f5f1fe;
  border-radius: 20px;
  padding: 15px;
}
.membership-bg .membership-container textarea:focus {
  box-shadow: none;
}
.membership-bg .membership-container .appointment-btn {
  box-sizing: border-box;
  height: 52px;
  border: 1px solid #3b7afe;
  filter: drop-shadow(0px 11px 20px rgba(59, 122, 254, 0.16));
  border-radius: 100px;
  background: white;
  color: #3b7afe;
  margin-bottom: 20px;
  margin-top: 40px;
}
.membership-bg .membership-container .appointment-container {
  background: #ffffff;
  border: 1px solid #f5f1fe;
  border-radius: 20px;
  padding: 29px 28px;
  width: 100%;
}
.membership-bg .membership-container .appointment-container h3 {
  font-family: "Inter";
  font-style: normal;
  font-weight: 600;
  font-size: 18px;
  line-height: 22px;
  color: #363636;
}
.membership-bg .membership-container .appointment-container .week-title {
  font-family: "Inter";
  font-style: normal;
  font-weight: 400;
  font-size: 14px;
  line-height: 24px;
  color: #899ca8;
  margin-top: 20px;
  margin-bottom: 7px;
}
.membership-bg .membership-container .appointment-container .week-description {
  /* lorem ipsum dolor sit amet, lorem ipsum dolor sit amet, consectetur */
  font-family: "Inter";
  font-style: normal;
  font-weight: 400;
  font-size: 14px;
  line-height: 17px;
  color: #899ca8;
  margin-bottom: 20px;
}
.membership-bg .membership-container .create-plan-btn {
  background: #3b7afe;
  box-shadow: 0px 11px 20px rgba(59, 122, 254, 0.16);
  border-radius: 100px;
  padding: 15px 50px;
  margin-bottom: 44px;
}

.fees-container {
  background: #ffffff;
  border: 1px solid #f5f1fe;
  border-radius: 20px;
  height: 740px;
}
.fees-container .membership-label {
  font-family: "Inter";
  font-style: normal;
  font-weight: 400;
  font-size: 16px;
  line-height: 19px;
  color: #899ca8;
}
.fees-container .fee-desc {
  font-family: "Inter";
  font-style: normal;
  font-weight: 400;
  font-size: 14px;
  line-height: 17px;
  /* identical to box height */
  color: #363636;
}
.fees-container .fee-padding {
  margin: 30px 30px 0 30px;
}
.fees-container .fee-padding input {
  height: 52px;
  background: #ffffff;
  border: 1px solid #f5f1fe;
  border-radius: 20px;
}
.fees-container .fee-padding input:focus {
  box-shadow: none;
}
.fees-container .fee-padding .mt_plan {
  margin-top: 20px;
}
.fees-container .savings {
  border: 1px solid rgba(151, 151, 151, 0.1529411765);
  border-radius: 10px;
  padding: 13px 18px;
  margin: 14px;
}
.fees-container .savings-text {
  font-family: "Inter";
  font-style: normal;
  font-weight: 500;
  font-size: 16px;
  line-height: 19px;
  color: #363636;
}
.fees-container .savings-answer {
  color: #3b7afe;
  font-family: "Inter";
  font-style: normal;
  font-weight: 500;
  font-size: 16px;
  line-height: 19px;
  padding-left: 30px;
}

.featured-plans {
  font-family: "Inter";
  font-style: normal;
  font-weight: 600;
  font-size: 24px;
  line-height: 29px;
  color: #363636;
  margin-top: 10px;
}

.featured-plan-cards {
  background: #ffffff;
  border-radius: 20px;
  padding: 20px;
  margin-top: 30px;
}
.featured-plan-cards h3 {
  font-family: "Inter";
  font-style: normal;
  font-weight: 600;
  font-size: 18px;
  line-height: 22px;
  color: #363636;
  margin-top: 10px;
}
.featured-plan-cards p {
  /* Retail */
  font-family: "Inter";
  font-style: normal;
  font-weight: 500;
  font-size: 14px;
  line-height: 24px;
  color: #899ca8;
}
.featured-plan-cards .patient-saving {
  background: rgba(59, 121, 254, 0.2039215686);
  border-radius: 10px;
  padding: 7px 18px;
}
.featured-plan-cards .patient-saving .patient-saving-text {
  /* Patient Saving: $100 */
  font-family: "Inter";
  font-style: normal;
  font-weight: 500;
  font-size: 16px;
  line-height: 19px;
  align-items: center;
  color: #363636;
}
.featured-plan-cards .patient-saving .hundred {
  color: #3b7afe;
  font-size: 24px;
  font-weight: 600;
  font-family: "Inter";
}
.featured-plan-cards .patient-saving:hover {
  background: #3b7afe;
  transition: 0.3s ease-in;
}
.featured-plan-cards .patient-saving:hover .patient-saving-text {
  color: white;
}
.featured-plan-cards .patient-saving:hover .hundred {
  color: white;
}
.featured-plan-cards hr {
  border: 1px solid #dadada;
  margin: 0;
  margin-bottom: 20px;
}
.featured-plan-cards .card-data-text {
  /* Total Appointments : */
  font-family: "Inter";
  font-style: normal;
  font-weight: 400;
  font-size: 14px;
  line-height: 24px;
  color: #363636;
}
.featured-plan-cards .card-data-ans {
  /* 12 */
  font-family: "Inter";
  font-style: normal;
  font-weight: 600;
  font-size: 14px;
  line-height: 24px;
  color: #3b7afe;
  padding-left: 5px;
}
.featured-plan-cards .mt_15 {
  margin-top: 15px;
}

.table-padding {
  padding: 30px;
  margin-top: 30px;
}
.table-padding .membership-plan {
  font-family: "Inter";
  font-style: normal;
  font-weight: 600;
  font-size: 24px;
  line-height: 29px;
  color: #363636;
}
.table-padding .view-membership-btn {
  background: #ffffff;
  border: 0.5px solid #5fbf24;
  box-shadow: 0px 6px 58px rgba(196, 203, 214, 0.3036);
  border-radius: 14px;
  color: #5fbf24;
  padding: 13px;
}
.table-padding .view-membership-btn:focus {
  box-shadow: 0px 6px 58px rgba(196, 203, 214, 0.3036);
  background: #5fbf24;
  color: white;
}
.table-padding .view-membership-btn:hover {
  box-shadow: 0px 6px 58px rgba(196, 203, 214, 0.3036);
  background: #5fbf24;
  color: white;
  transition: 0.3s;
}

.renewal-tracking {
  background: #ffffff;
  border: 1px solid #f5f1fe;
  border-radius: 20px;
  padding: 22px 30px 42px 30px;
}
.renewal-tracking h4 {
  font-family: "Inter";
  font-style: normal;
  font-weight: 600;
  font-size: 18px;
  line-height: 22px;
  color: #363636;
  margin-bottom: 30px;
}
.renewal-tracking .stats-heading {
  font-family: "Inter";
  font-style: normal;
  font-weight: 600;
  font-size: 18px;
  line-height: 22px;
  color: #363636;
}
.renewal-tracking .stats-desc {
  width: 93px;
  font-family: "Inter";
  font-style: normal;
  font-weight: 400;
  font-size: 12px;
  line-height: 18px;
  color: #899ca8;
}
.renewal-tracking img {
  margin-right: 20px;
}

.delete-bg {
  width: 48px;
  height: 48px;
  background: #FFFFFF;
  box-shadow: 0px 6px 58px rgba(196, 203, 214, 0.103611);
  border-radius: 14px;
  display: flex;
  align-items: center;
  justify-content: center;
  cursor: pointer;
}
.delete-bg:hover {
  background: #ff5a4e;
  transition: 0.1s ease-in;
}
.delete-bg:hover path {
  fill: #f5f1fe;
}

.alertModal p {
  font-style: normal;
  font-weight: 500;
  font-size: 20px;
  color: #899CA8;
}

.modal-header {
  border: 0;
}

.modal-title {
  font-family: "Inter";
  font-style: normal;
  font-weight: 600;
  font-size: 22px;
  line-height: 27px;
  color: #363636;
}

.css-1e2bxag-MuiDataGrid-root .MuiDataGrid-row:not(.MuiDataGrid-row--dynamicHeight) > .MuiDataGrid-cell {
  overflow: visible !important;
}

.user-button button#dropdown-basic-button {
  width: 30px;
  height: 30px;
  background: #fafafb;
  box-shadow: 0px 6px 58px rgba(196, 203, 214, 0.103611);
  border-radius: 8px;
  height: 30px;
  width: 30px;
  border: none;
  display: flex;
  align-items: center;
  cursor: pointer;
}
.user-button button#dropdown-basic-button:focus {
  border: none;
}
.user-button .dropdown-toggle::after {
  display: none;
}

.table-status {
  /* Status */
  display: flex;
  flex-direction: row;
  justify-content: center;
  align-items: center;
  background: #e4f9e0;
  border-radius: 8px;
  width: 63px;
  height: 30px;
  font-family: "Inter";
  font-style: normal;
  font-weight: 700;
  font-size: 12px;
  line-height: 16px;
  text-align: center;
  color: #5fbf24;
}

.content-management-modal textarea {
  background: #ffffff;
  border: 1px solid #f5f1fe;
  border-radius: 20px;
}
.content-management-modal textarea:focus {
  box-shadow: none;
  border: 1px solid #3b7afe;
}
.content-management-modal input {
  width: 100%;
  height: 52px;
  background: #ffffff;
  border: 1px solid #f5f1fe;
  border-radius: 20px;
}
.content-management-modal input:focus {
  border: 1px solid #3b7afe !important;
}
.content-management-modal select.form-select {
  background: #ffffff;
  border: 1px solid #f5f1fe;
  border-radius: 20px;
  width: 100%;
  height: 52px;
}
.content-management-modal select.form-select:focus {
  border: 1px solid #3b7afe !important;
}
.content-management-modal label {
  font-family: "Inter";
  font-style: normal;
  font-weight: 400;
  font-size: 16px;
  line-height: 19px;
  color: #899ca8;
  margin-bottom: 7px !important;
}
.content-management-modal input {
  font-family: "Inter";
  font-style: normal;
  font-weight: 400;
  font-size: 14px;
  line-height: 17px;
  color: #363636;
  margin-bottom: 18px;
}
.content-management-modal input:focus {
  box-shadow: none;
  outline: none;
  border: none;
}
.content-management-modal .form-select {
  font-family: "Inter";
  font-style: normal;
  font-weight: 400;
  font-size: 14px;
  line-height: 17px;
  color: #363636;
  margin-bottom: 18px;
}
.content-management-modal .form-select:focus {
  box-shadow: none;
}
.content-management-modal p {
  /* Are you sure you want to deactivate these record? */
  font-family: "Inter";
  font-style: normal;
  font-weight: 500;
  font-size: 20px;
  line-height: 141.5%;
  /* or 28px */
  /* 3 */
  color: #899ca8;
}
.content-management-modal .modal_cancel_btn {
  /* Cancel */
  font-family: "Inter";
  font-style: normal;
  font-weight: 500;
  font-size: 18px;
  line-height: 22px;
  text-align: center;
  color: #899ca8;
  background: none;
  border: none;
  margin-left: 22px;
}
.content-management-modal .modal_cancel_btn:focus {
  box-shadow: none;
}
.content-management-modal .modal_submit_btn {
  background: #3b7afe;
  box-shadow: 0px 11px 20px rgba(59, 122, 254, 0.16);
  border-radius: 100px;
  width: 167px;
  height: 52px;
  /* Add */
  font-family: "Inter";
  font-style: normal;
  font-weight: 500;
  font-size: 18px;
  line-height: 22px;
  text-align: center;
  color: #ffffff;
  margin-top: 19px;
}
.content-management-modal .modal_submit_btn:hover {
  border: 1px solid #3b7afe;
  background: white;
  color: #3b7afe;
}

button#dropdown-nav-basic-button {
  background: none;
  padding: 0;
  margin: 0;
  border: none;
  height: 44px;
}
button#dropdown-nav-basic-button .nav-ez-plan .dropdown-menu.show {
  position: absolute;
  inset: 0px auto auto 0px;
  transform: translate3d(195px, 34px, 0px) !important;
}
button#dropdown-nav-basic-button .nav-ez-plan .dashboard-sidebar ul li a.active ::after {
  right: -37px;
}

.has-children .nav-child-list {
  background: #ffffff;
  border: 1px solid #f5f1fe;
  border-radius: 20px;
  position: absolute;
  top: 0;
  left: 100%;
  opacity: 0;
  visibility: visible;
  height: 307px;
  width: 213px;
  transition: 0.3s ease;
  z-index: 9;
  display: none;
  padding: 28px;
  font-family: "Inter";
  font-style: normal;
  font-weight: 400;
  font-size: 14px;
  line-height: 17px;
  color: #899ca8;
}
.has-children .nav-child-list .p_28 {
  padding-top: 28px;
}
.has-children .nav-child-list:after {
  content: normal !important;
}
.has-children .nav-child-list .nav-child-item:after {
  content: normal;
}
.has-children .nav-child-list a:after {
  content: normal !important;
}

.has-children:hover ul.nav-child-list {
  position: absolute;
  top: 0;
  left: 100%;
  background: white;
  opacity: 1;
  visibility: visible;
  display: block;
  transition: 0.6s ease-in;
}

.stepsFormContainer {
  display: flex;
  align-items: center;
  justify-content: center;
  height: 100vh;
  background-color: #fcfcfc;
}
.stepsFormContainer .stepsFormWrapper {
  max-width: 95%;
  width: 95%;
  height: 95%;
}
.stepsFormContainer .whiteLogo {
  margin-bottom: 50px;
}
.stepsFormContainer .sidebarHeading {
  margin-bottom: 55px;
}
.stepsFormContainer .stepsBody {
  height: 100%;
  background: white;
  display: flex;
  flex-flow: column;
  align-items: center;
  justify-content: space-between;
  border-radius: 24px;
  overflow: hidden;
}
.stepsFormContainer .stepsBody .stepsContent {
  display: flex;
  align-items: center;
  justify-content: flex-start;
  flex-flow: column;
  padding-top: 60px;
  width: 100%;
  height: 100%;
}
.stepsFormContainer .stepsBody .stepsContent .stepContentHead {
  display: flex;
  flex-flow: column;
  align-items: center;
}
.stepsFormContainer .stepsFooter {
  width: 100%;
  border-top: 1px solid #E4E6E8;
  display: flex;
  align-items: center;
  justify-content: space-between;
  padding: 12px 50px;
}
.stepsFormContainer .stepsFooter .signupHere {
  display: flex;
  align-items: center;
}
.stepsFormContainer .stepsFooter .signupHere p,
.stepsFormContainer .stepsFooter .signupHere h4 {
  line-height: 1;
  margin: 0;
}

.stepsSider {
  padding: 60px 34px 60px 32%;
  background: #3B7AFE;
  border-radius: 24px;
  height: 100%;
}
@media (max-width: 1900px) {
  .stepsSider {
    padding: 60px 34px 60px 20%;
  }
}
.stepsSider .ant-steps .ant-steps-item {
  padding-bottom: 36px;
  opacity: 0.5;
}
.stepsSider .ant-steps .ant-steps-item .ant-steps-item-icon {
  background-color: rgba(255, 255, 255, 0.3);
  border-color: #ffffff;
  border: 2px solid white;
}
.stepsSider .ant-steps .ant-steps-item .ant-steps-item-icon .ant-steps-icon {
  display: none;
}
.stepsSider .ant-steps .ant-steps-item.ant-steps-item-wait .ant-steps-item-icon {
  background-color: transparent;
}
.stepsSider .ant-steps .ant-steps-item.ant-steps-item-active {
  opacity: 1;
}
.stepsSider .ant-steps .ant-steps-item.ant-steps-item-active .ant-steps-item-title {
  font-weight: 700;
}
.stepsSider .ant-steps .ant-steps-item .ant-steps-item-title {
  font-weight: 400;
  font-size: 20px;
  line-height: 26px;
  color: white !important;
}
@media (max-width: 1366px) {
  .stepsSider .ant-steps .ant-steps-item .ant-steps-item-title {
    font-size: 15px;
  }
}
.stepsSider .ant-steps .ant-steps-item .ant-steps-item-wait > .ant-steps-item-container > .ant-steps-item-content > .ant-steps-item-title {
  color: #FFFFFF;
  mix-blend-mode: normal;
  opacity: 0.6;
}
.stepsSider .ant-steps .ant-steps-item-process > .ant-steps-item-container > .ant-steps-item-tail::after {
  background-color: white;
}
.stepsSider .ant-steps .ant-steps-item-finish > .ant-steps-item-container > .ant-steps-item-tail::after {
  background-color: white;
}
.stepsSider .ant-steps .ant-steps-vertical {
  width: -moz-max-content;
  width: max-content;
  margin-left: auto;
}

@media (max-width: 1200px) {
  .ez-pay-dashboard .earning-bg h2 {
    font-size: 24px;
    margin-top: 20px;
  }
  .ez-pay-dashboard .earning-bg p {
    font-size: 16px;
  }
  .donutchart-container {
    width: 50%;
    margin: auto;
  }
  .revenue-bg {
    margin-top: 32px;
  }
  .settings-body .content-nav-bg {
    width: 200px !important;
  }
  .settings-body .settings-bar .settings-navigation {
    margin-top: 30px;
  }
  .settings-body .settings-bar .settings-navigation ul {
    text-decoration: none;
    list-style: none;
    padding-left: 10px;
  }
}
@media (max-width: 768px) {
  .LineGraph button:not(:disabled) {
    font-size: 12px;
  }
  .care-section canvas {
    width: auto !important;
    height: auto !important;
    margin: auto;
    margin-top: 50px;
  }
  .donutchart-container {
    width: 60%;
    margin: auto;
    margin-top: 20px;
  }
  .ez-pay-dashboard .earning-bg h2 {
    font-family: "Inter";
    font-style: normal;
    font-weight: 600;
    font-size: 15.2528px;
    line-height: 24px;
    color: #363636;
    margin-bottom: 0;
  }
  .ez-pay-dashboard .earning-bg p {
    font-family: "Inter";
    font-style: normal;
    font-weight: 400;
    font-size: 12.2022px;
    line-height: 18px;
    color: #899ca8;
    margin-top: 12px;
    margin-bottom: 0;
  }
}
@media (max-width: 992px) {
  .settings-body .settings-bar {
    height: auto;
    margin-bottom: 20px;
    padding-bottom: 20px;
    text-align: center;
  }
  .settings-body .settings-bar .content-nav-bg {
    width: 200px !important;
    text-align: left !important;
    padding-right: 7px;
  }
  .settings-body .settings-bar ul {
    text-decoration: none;
    list-style: none;
    padding-left: 10px;
  }
  .settings-body .settings-bar ul li {
    display: inline-block !important;
    margin-left: 10px;
  }
}
@media (max-width: 375px) {
  .content-management {
    padding: 20px !important;
  }
  .content-management .user-button {
    padding-top: 10px !important;
  }
  .content-management h2 {
    font-size: 19px;
  }
  .user-management h2 {
    font-size: 32px;
    margin-bottom: 20px;
  }
  .user-management .add-new-btn {
    font-size: 16px;
    min-width: 120px;
    min-height: 42px;
    margin-bottom: 20px;
  }
  .user-filter-btn {
    color: black;
  }
  .user-filter-btn button#dropdown-basic-button {
    min-width: 120px;
    min-height: 42px;
  }
  .eyehide-bg {
    background: #ffffff;
    box-shadow: 0px 6px 58px rgba(196, 203, 214, 0.103611);
    border-radius: 14px;
    width: 42px;
    height: 42px;
    border: none !important;
    padding: 0;
  }
  .payment-card-bg {
    background: #ffffff;
    border: 1px solid #f5f1fe;
    border-radius: 20px;
    margin: auto;
    margin-top: 20px;
    width: 100%;
    min-height: 360px;
  }
  .financing-card-bg {
    width: 100%;
    height: auto;
  }
  .financing-card-bg h3 {
    font-size: 16px;
  }
  .financing-card-bg .mb_10 {
    margin-bottom: 10px;
  }
  .financing-card-bg .payment-source {
    font-size: 12px;
    margin-left: 6px;
  }
  .financing-card-bg .third-party {
    font-size: 12px;
  }
  .ez-pay-dashboard .revenue-bg {
    padding: 30px 10px 0 10px;
  }
  .ez-pay-dashboard .plans-graph-container {
    padding: 30px 10px 0 10px;
  }
  .ez-pay-dashboard .ezpay-table .table-padding {
    padding: 0 10px;
  }
  .date-range-picker button:not(:disabled) {
    font-size: 12px;
  }
}
@media (max-width: 700px) {
  .settings-body .settings-bar {
    height: auto;
    margin-bottom: 20px;
    padding-bottom: 20px;
    text-align: center;
  }
  .settings-body .settings-bar .content-nav-bg {
    width: 230px !important;
    text-align: left !important;
    padding-right: 7px;
  }
  .settings-body .settings-bar ul {
    text-decoration: none;
    list-style: none;
    padding-left: 10px;
  }
  .settings-body .settings-bar ul li {
    display: block !important;
    margin-left: 10px;
  }
}
@media (max-width: 425px) {
  .dashboard-heading {
    font-size: 24px !important;
  }
}
@media (max-width: 575px) {
  .user-management h2 {
    font-size: 32px;
    margin-bottom: 20px;
  }
  .user-management .add-new-btn {
    margin-bottom: 20px;
  }
  .modal-content {
    min-width: 100%;
  }
}
.patient-detail-box .detail-heading {
  font-weight: 500;
  font-size: 14px;
  color: #363636;
}

.patient-detail-list .patient-item {
  margin-bottom: 15px;
  display: flex;
  align-items: center;
}
.patient-detail-list .patient-item:last-child {
  margin-bottom: 0;
}
.patient-detail-list .patient-item .patient-icon {
  margin-right: 10px;
}
.patient-detail-list .patient-item .patient-item-value {
  color: #899CA8;
  font-weight: 400;
  font-size: 14px;
}

.square-edit-btn {
  position: absolute;
  top: 24px;
  right: 30px;
  transition: 0.5s ease;
}
.square-edit-btn:hover {
  cursor: pointer;
}

.billingProgressWrapper {
  display: flex;
  justify-content: space-between;
  width: 60%;
  margin-bottom: 45px;
}
.billingProgressWrapper .billingHeading {
  color: #899CA8;
  font-weight: 400;
  font-size: 16px;
}
.billingProgressWrapper .billingHeading span {
  margin-top: 14px;
  font-size: 24px;
  color: #363636;
  display: block;
  font-weight: 600;
}

.ant-progress-text {
  font-weight: 500;
  font-size: 18px;
  color: #363636;
}

.ant-progress.ant-progress-show-info .ant-progress-outer {
  -webkit-margin-end: calc(-2em - 18px);
          margin-inline-end: calc(-2em - 18px);
  -webkit-padding-end: calc(2em + 28px);
          padding-inline-end: calc(2em + 28px);
}

.billingProgressText {
  margin-bottom: 26px;
}
.billingProgressText p {
  font-weight: 400;
  font-size: 16px;
  color: #899CA8;
  margin: 0;
}
.billingProgressText p span {
  color: #363636;
}

.offerList p {
  color: #899CA8;
}
.offerList strong {
  font-weight: 600;
  font-size: 14px;
  color: #363636;
  width: 13%;
  display: block;
}

.file-upload-container {
  position: relative;
}

.file-upload-input {
  position: absolute;
  top: 0;
  left: 0;
  width: 100%;
  height: 100%;
  opacity: 0;
  cursor: pointer;
}

.upload-img-div {
  width: 236px;
  height: 106px;
  -o-object-fit: cover;
     object-fit: cover;
  overflow: hidden;
  border-radius: 15px;
}
.upload-img-div img {
  width: 100%;
  height: 100%;
}

.file-upload-label {
  display: flex;
  flex-direction: row-reverse;
  justify-content: flex-end;
  font-family: "Inter";
  font-style: normal;
  font-weight: 400;
  font-size: 16px !important;
  line-height: 19px;
  color: #899ca8;
  height: auto !important;
  margin-bottom: 15px;
}
.file-upload-label::before {
  display: inline-block;
  margin-left: 4px;
  color: #f30003;
  font-size: 14px;
  font-family: SimSun, sans-serif;
  line-height: 1;
  font-weight: bold;
  content: "*";
}

.file-upload-button {
  position: absolute;
  top: 0;
  left: 0;
  width: 100%;
  height: 100%;
  display: flex;
  justify-content: center;
  align-items: center;
  font-weight: bold;
  cursor: pointer;
}

.form-btn-div {
  display: flex;
  justify-content: end;
  gap: 10px;
  align-items: center;
}

.thankyouPageLayout {
  display: flex;
  justify-content: center;
  align-items: center;
  height: 100vh;
  width: 100%;
}
.thankyouPageLayout .thankyoubox {
  display: flex;
  flex-flow: column;
  align-items: center;
  justify-content: center;
}
.thankyouPageLayout .thankyouImage {
  margin-bottom: 40px;
}
.thankyouPageLayout .thankyouTitle {
  margin-bottom: 15px;
}
.thankyouPageLayout .thankyouTitle h4 {
  font-weight: 700;
  font-size: 24px;
  line-height: 29px;
  text-align: center;
  color: #0A1629;
}
.thankyouPageLayout .signupHere p, .thankyouPageLayout .signupHere h4 {
  line-height: 1;
}
.thankyouPageLayout .thankyouPara {
  margin-bottom: 30px;
}
.thankyouPageLayout .thankyouPara p {
  margin: 0;
  font-weight: 400;
  font-size: 14px;
  line-height: 17px;
  text-align: center;
  /* 4 */
  color: #363636;
}

.custom-margin {
  margin-left: 35px !important;
}

.custom-grey-modal-text {
  font-family: "Nunito Sans";
  font-style: normal;
  font-weight: 600;
  font-size: 20px;
  line-height: 141.5%;
  color: #899ca8;
}

.membershipPlanDetail {
  height: 80%;
}

.membershipDetailContentBox .ant-btn {
  min-width: 116px;
  min-height: 52px;
}
.membershipDetailContentBox .btn-wrapper {
  display: flex;
  justify-content: flex-end;
}
.membershipDetailContentBox .btn-wrapper button {
  margin-right: 20px;
}/*# sourceMappingURL=Style.css.map */