.ant-breadcrumb {
    .ant-breadcrumb-separator {
        margin-inline: 2px;
        font-weight: 400;
        font-size: 16px;
        line-height: 19px;
        color: #899CA8;
    }

    .ant-breadcrumb-link {
        font-weight: 400;
        font-size: 16px;
        line-height: 19px;
        color: #899CA8;


        a {
            font-weight: 400;
            font-size: 16px;
            line-height: 19px;
            color: #899CA8 !important;

            &:hover {
                background-color: transparent;
                color: $greyColor;
            }
        }
    }
}