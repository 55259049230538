.container,
.container-fluid {
  max-width: 1366px;
}

.Links {
  text-decoration: none !important;
}

.onboarding-container {
  background: #ffffff;
  border: 1px solid #f5f1fe;
  border-radius: 24px;
  margin: 20px 40px;
  overflow: hidden !important;

  .signin-container {
    padding-left: 95px;

    .ezfi-logo {
      padding: 50px 0 60px 0;
    }

    .onboarding-heading {
      font-family: "Inter";
      font-style: normal;
      font-weight: 600;
      font-size: 24px;
      line-height: 29px;
      color: #363636;
    }

    .onboarding-text {
      font-family: "Inter";
      font-style: normal;
      font-weight: 500;
      font-size: 16px;
      line-height: 19px;
      color: #899ca8;
      padding-top: 10px;
    }

    .signinform {
      padding: 30px 0 0 0;
    }

    .onboarding-placeholders {
      width: 70%;
      height: 52px;
      background: #ffffff;
      border: 1px solid #f5f1fe;
      border-radius: 20px;

      &:focus {
        box-shadow: none !important;
        border: 1px solid #3b7afe;

      }

      &:hover {
        border: 1px solid #3b7afe;
        transition: ease-in 0.3s;
      }
    }

    .form-labels {
      font-family: "Inter";
      font-style: normal;
      font-weight: 400;
      font-size: 16px;
      line-height: 19px;
      color: #899ca8;

      span {
        color: red;
      }
    }

    .forgot-password {
      /* Forgot Password? */
      font-family: "Inter";
      font-style: normal;
      font-weight: 500;
      font-size: 16px;
      line-height: 19px;
      color: #363636;
      padding-bottom: 25px;
    }

    .onboarding-btn {
      background: #3b7afe;
      box-shadow: 0px 11px 20px rgba(59, 122, 254, 0.16);
      border-radius: 100px;
      width: 70%;
      height: 52px;
      font-size: 16px;

      &:hover {
        transition: 0.5s;
        background: white;
        color: #3b7afe;
      }
    }
  }

  .woman-img {
    max-width: 85%;
  }
}

.otp {

  .otp-input {

    input {
      width: 55px !important;
      height: 50px;
      background: #ffffff;
      border: 1px solid #cfcdd6;
      border-radius: 20px;
      margin: 0 7px !important;

      &:focus {
        outline: none;
        border: 3px solid #5fbf24 !important;
        // background-color: red;
      }
    }
  }


}

.resend-code {
  font-size: 16px;
  font-weight: 600;
  padding-top: 20px;
  color: #899ca8;
  margin-bottom: 20px;
}

nav {
  a {
    color: red !important;
  }
}

.validation-error {
  color: red;
}



// @media (max-width:1200px){

//     .onboarding-container{
//         margin: 40px 0;
//         .signin-container{
//             padding:0 20px;

//                     .onboarding-btn{
//             margin-bottom: 50px!important;
//         }
//         }



//     }

// }




// button#button-addon2 {
//   background: #3b7afe!important;
//   border: none;
//   box-shadow: none;
//   color: white!important;
// }


// .input-group{
//   width: 70%;
//   height: 52px;
//   background: #ffffff;
//   border: 1px solid #f5f1fe;
//   border-radius: 20px!important;

// }

// button#button-addon2 {
//   background: #3b7afe !important;
//   border: none;
//   box-shadow: none;
//   color: white !important;
//   border-top-right-radius: 20px;
//   border-bottom-right-radius: 20px;
// }

// input#formBasicPassword0{
//   border-radius: 20px;

//   background: #ffffff;
//   border: 1px solid #f5f1fe;

//   &:focus{
//     box-shadow: none;
//     border: 1px solid #3b7afe;
//   }
//   &:hover{
//     border: 1px solid #3b7afe;
//   }
// }

// input#formBasicPassword2{
//   border-radius: 20px;
//   border-top-right-radius: 0;
//   border-bottom-right-radius: 0;

//   &:focus{
//     box-shadow: none;
//   }
// }


// @media (max-width:550px){

//   .onboarding-container{
//       margin: 40px 0;
//       .signin-container{
//           padding:0 20px;

//                   .onboarding-btn{
//           margin-bottom: 50px!important;
//           width: 100%;
//       }

//       .onboarding-placeholders{
//           width: 100%;
//       }
//       }



//   }

//   .input-group{
//     width: 100%;

//   }


// }

// .input_name{
//   position: relative;
//   .icon {
//     position: absolute;
//   top:18px;
//   right: 18px;
//     svg{
//       path{
//         fill:#5FBF24;
//       }
//     }
//   }
// }