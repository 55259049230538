.authenticationLayout {
  display: flex;
  align-items: center;
  justify-content: center;
  width: 100%;
  height: 100%;
  height: 100vh;

  .wdth_1366 {
    width: 100%;
    height: 90%;
    max-width: 95%;
    margin: auto;
    overflow: scroll;
    background-size: contain;
    background-repeat: no-repeat;
    background-position: right center;

    .container {
      max-width: 1196px;
    }
  }

  .authLeftBox {
    display: flex;
    justify-content: flex-start;
    align-items: flex-start;
    padding: 6%;

    @media (max-width: 1366px) {
      padding: 58px 95px;
    }
  }
}

.logo-wrapper {
  margin-bottom: 60px;

  @media (max-width: 1366px) {
    margin-bottom: 50px;
  }
}

.bg_color_img {
  background: #ffffff;
  border: 1px solid #f5f1fe;
  border-radius: 24px;

  .mt_50 {
    margin-top: 50px;
  }

  .main_form {
    h1 {
      margin-bottom: 10px;
      /* Sign in */
      font-family: "Inter";
      font-style: normal;
      font-weight: 600;
      font-size: 24px;
      line-height: 29px;
      color: #363636;
    }

    p {
      margin-bottom: 30px;
      font-family: "Inter";
      font-style: normal;
      font-weight: 500;
      font-size: 16px;
      color: #899ca8;
    }
  }

  .input_main {
    margin-bottom: 30px;

    .form-label {
      margin-bottom: 7px;
      font-family: "Inter";
      font-style: normal;
      font-weight: 400;
      font-size: 16px;
      color: #899ca8;
      line-height: 19px;
    }

    span#basic-addon2 {
      background: white;
      border: 1px solid #f5f1fe;
      border-left: none !important;
      border-top-right-radius: 20px;
      border-bottom-right-radius: 20px;
      cursor: pointer;
    }

    input {
      height: 52px;
      background: #ffffff;
      border: 1px solid #f5f1fe;
      border-radius: 20px;
      font-family: "Inter";
      font-style: normal;
      font-weight: 400;
      font-size: 14px;
      line-height: 17px;
      color: #363636;
      border-right: none;

      &:focus {
        box-shadow: none;
      }

      &.hasError {
        border-color: red;
        border: 1px solid red;
      }
    }
  }

  a {
    text-decoration: none;
  }

  .forgot_pass {
    margin-bottom: 28px;
    font-weight: 500;
    font-size: 16px;
    color: #363636;
    text-decoration: none;
    display: inline-block;

    &:hover {
      color: #3b7afe;
    }
  }

  .main_btn {
    button.main_form_btn {
      width: 100%;
      height: 52px;
      background: #3b7afe;
      box-shadow: 0px 11px 20px rgba(59, 122, 254, 0.16);
      border-radius: 100px;
      // btn text
      font-family: "Inter";
      font-style: normal;
      font-weight: 500;
      font-size: 18px;
      line-height: 22px;
      text-align: center;

      color: #ffffff;

      &:focus {
        box-shadow: none;
      }
    }
  }
}

.form_star {
  color: red;
}



@media (max-width: 1200px) {
  .bg_color_img {
    .input_main {
      margin-bottom: 18px;


    }

    a {
      text-decoration: none;
    }

    .main_btn {
      button.main_form_btn {
        width: 100%;
        height: 52px;
      }
    }
  }



}



.mb_20 {
  margin-bottom: 30px !important;
}


.signupHere {
  padding-top: 20px;

  p {
    font-weight: 400;
    font-size: 14px;
    line-height: 17px;
    text-align: center;
    color: #899CA8;
    margin: 0;
  }

  h4 {
    font-weight: 500;
    font-size: 16px;
    line-height: 26px;
    text-align: center;
    color: #5FBF24;
    margin: 0;
  }
}