.greyColor {
    color: $greyColor !important;
}

.blueColor {
    color: $primaryColor;
}

.darkGrey {
    color: $secondaryColor;
}

.greenColor {
    color: #5FBF24;
}

.lightGrey {
    color: #899CA8;
}

.boldGrey {
    font-weight: 700;
    font-size: 14px;
    color: #899CA8;
}

.black {
    color: $black;

    &:hover {
        color: $black;
    }
}