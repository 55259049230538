.membershipPlanDetail {
    height: 80%;
}

.membershipDetailContentBox {
    .ant-btn {
        min-width: 116px;
        min-height: 52px;
    }

    .btn-wrapper {
        display: flex;
        justify-content: flex-end;

        button {
            margin-right: 20px;
        }
    }
}