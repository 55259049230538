:where(.css-dev-only-do-not-override-ph9edi).ant-badge.ant-badge-status .ant-badge-status-text {
    color: $greyColor;
}

.badges-list {
    display: flex;
    align-items: center;

    .badges-item {
        padding-right: 30px;

        &:last-child {
            padding-right: 0;
        }
    }
}