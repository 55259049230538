.custom-margin {
  margin-left: 35px !important;
}

.custom-grey-modal-text {
  font-family: "Nunito Sans";
  font-style: normal;
  font-weight: 600;
  font-size: 20px;
  line-height: 141.5%;
  color: #899ca8;
}
