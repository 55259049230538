/**********************************
* Search bar container - top bar
***********************************/
.topBarWrapper {
  @media (max-width: 767px) {
    display: flex;
    justify-content: space-between;
    margin-bottom: 15px;
  }


}

.topBar-left {
  @media (max-width: 767px) {
    display: flex;
    align-content: center;
    flex-wrap: nowrap;
    justify-content: space-evenly;
  }

  .list-inline-item {
    margin-right: 15px;

    &:last-child {
      margin-right: 0;
    }
  }
}

.searchBarContainer {
  display: flex;
  align-items: center;
  // background: white;
  border-radius: 10px;
  box-shadow: 2px -3px 12px 3px rgb(196 203 214 / 10%);
  position: relative;
  z-index: 20;
  width: max-content;

  @media (max-width:1024px) {
    width: auto;
  }

  @media (max-width: 767px) {
    width: 90%;
  }

  .crossIcon {
    position: absolute;
    top: 50%;
    right: 12px;
    transform: translate(0, -50%);
  }

  .searchBar {
    .searchBar__menu {
      border: 0;
      border-radius: 14px;
      box-shadow: 0px 15px 20px 3px hsl(0deg 0% 0% / 10%), 0 4px 11px hsl(0deg 0% 0% / 10%);
    }

    .menuSearchHead {
      display: flex;
      justify-content: space-between;
      align-items: center;

      .menu-heading {
        color: $theme-black;
        font-weight: 700;
        font-size: 16px;
        letter-spacing: -0.02em;

        @media (max-width: 992px) {
          font-size: 14px;
        }
      }

      .clearText {
        color: $text-grey;
        font-weight: 600;
        font-size: 14px;

        &:hover {
          cursor: pointer;
          color: $theme-blue;
        }

        @media (max-width: 992px) {
          font-size: 12px;
        }
      }
    }

    .search-wrapper {
      margin-bottom: 16px;

      svg {
        width: 20px;
        height: 20px;
        margin-right: 10px;

        @media (max-width: 992px) {
          width: 15px;
          height: 15px;
        }
      }

      @media (max-width: 992px) {
        margin-bottom: 14px;
      }
    }

    .searchBar__menu-list {
      padding: 24px 39px;
      border: 0;
      border-radius: 14px;

      @media (max-width: 992px) {
        padding: 16px;
      }
    }

    .searchBar__control {
      height: 48px;
      width: 300px;
      background-color: $theme-white;
      border-color: $theme-white;
      border-radius: 14px;
      transition: all 0.5s ease;
      padding-left: 19px;
      text-align: left;
      min-width: 300px;
      max-width: 300px;
      width: 25em;
      display: flex;
      align-items: center;

      @media (max-width: 992px) {
        width: 18em;
      }

      @media (max-width: 767px) {
        width: 14em;
        padding-left: 5px;
      }

      @media (max-width: 575px) {
        width: 11em;
      }

      @media (max-width: 375px) {
        width: 10em;
      }

      &:focus {
        border-color: $theme-blue;
        width: 100%;
      }
    }

    .searchBar__option {
      font-weight: 400;
      font-size: 16px;
      line-height: 1;
      display: flex;
      align-items: center;
      padding: 0;

      @media (max-width: 992px) {
        font-size: 12px;
      }

      &:active,
      &:focus {
        background-color: white !important;
        box-shadow: unset !important;
      }

      &:hover {
        color: $theme-blue;
      }

    }
  }

  .crossIcon {
    display: none;
  }

  .searchBar__input {

    &:focus,
    &:active {
      .searchBarContainer {
        .crossIcon {
          display: block;
        }
      }

    }
  }
}

.nosearchResult {
  display: flex;
  flex-flow: column;
  align-items: center;
  justify-content: center;
  width: 100%;
  height: 100%;

  .noSearchImg {
    width: 250px;
    height: auto;

    @media (max-width: 1366px) {
      width: 170px;
    }

    svg,
    img {
      height: 100%;
      width: 100%;
    }
  }
}

/**************************************
  * notification Icon - Top bar
  ****************************************/
.notificationContainer {
  background: $theme-white;
  border: 1px solid $theme-white;
  box-shadow: 0px 6px 58px rgba(196, 203, 214, 0.103611);
  padding: 12px;
  border-radius: 14px;
  transition: all 0.5s ease;
  cursor: pointer;

  &:hover,
  &:focus {
    border: 1px solid $theme-blue;

    svg {
      path {
        fill: $theme-blue;
      }
    }
  }

  @media (max-width: 1366px) {
    width: 40px;
    height: 38px;
    display: flex;
    align-items: center;
    justify-content: center;
  }

  @media (max-width: 1024px) {
    padding: 9px;
  }

  @media (max-width: 767px) {
    width: 35px;
    height: 35px;
    padding: 6px;
  }
}


/**************************************
  * profile box dropdown - Top bar
  ****************************************/
.topBar {
  margin-bottom: 25px;

  .list-inline {
    display: flex;
    justify-content: flex-end;
    align-items: center;
    margin: 0;
  }
}

.profileBtn {
  .dropdown-toggle {
    background: $theme-white;
    border: 1px solid $theme-white;
    //max-height: 40px;
    padding: 9px 18px 9px 11px;
    border-radius: 14px;

    @media (max-width: 1366px) {
      padding: 4px 11px;
    }

    @media (max-width: 767px) {
      padding: 0;
    }

    &:after {
      content: unset;
    }

    &:focus {
      box-shadow: none;
      outline: none;
    }

    &:hover {
      background: $theme-white;
      border: 1px solid $theme-blue;

      svg {

        path {
          fill: $theme-blue;
        }
      }
    }
  }

  &.show {
    .profileBox {
      transition: all 0.5s ease;

      svg {
        transform: rotate(180deg);
        transform-origin: center;
      }
    }
  }

  .dropdown-menu {
    padding: 15px 12px;
    border: 1px solid $theme-white;
    box-shadow: 0px 15px 20px 3px hsl(0deg 0% 0% / 10%), 0 4px 11px hsl(0deg 0% 0% / 10%);
    margin-top: 8px;

    .dropdown-item {
      text-decoration: none;
      font-family: $baseFont;
      font-style: normal;
      font-weight: 400;
      font-size: 14px;
      line-height: 19px;
      padding: 0;
      background: $theme-white;
      color: $text-grey;
      transition: font-weight 0.1s ease-in-out;

      &:not(:last-child) {
        margin-bottom: 20px;
      }

      &:hover,
      &:focus {
        color: $theme-blue;
        font-weight: 700;

        svg {
          path {
            fill: $theme-blue;
          }
        }
      }
    }
  }
}

.profileBox {
  cursor: pointer;

  .profileImg {
    width: 30px;
    height: 30px;
    object-fit: cover;
    object-position: center top;
    margin-right: 11px;
  }

  .profileName {
    font-family: $baseFont;
    font-style: normal;
    font-weight: 700;
    font-size: 16px;
    line-height: 24px;
    color: $theme-black;
    margin-right: 18px;

    @media (max-width: 1366px) {
      font-size: 14px;
      line-height: 1;
    }

    @media (max-width: 767px) {
      display: none;
    }
  }
}

/**************************************
* Humburger
****************************************/
.hamburger-menu {
  display: none;

  @media (max-width: 767px) {
    display: flex;
    position: relative;
    flex-flow: column;
    justify-content: center;
  }
}

#menu__toggle {
  opacity: 0;
}


.menu__btn {
  display: flex;
  align-items: center;
  position: relative;
  width: 26px;
  height: auto;
  cursor: pointer;
  z-index: 1;

  @media (max-width: 767px) {
    width: 19px;
  }
}

.menu__btn>span,
.menu__btn>span::before,
.menu__btn>span::after {
  display: block;
  position: absolute;

  width: 100%;
  height: 2px;

  background-color: #616161;

  transition-duration: .25s;
}

.menu__btn>span::before {
  content: '';
  top: -8px;
}

.menu__btn>span::after {
  content: '';
  top: 8px;
}

// Menu Topbar Right wrapper  
.rightTopbarHolder {
  display: flex;
  align-items: center;
  justify-content: space-evenly;
}