.alertModal {
  p {
    font-style: normal;
    font-weight: 500;
    font-size: 20px;
    color: #899CA8;
  }
}

.modal-header {
  border: 0
}

.modal-title {
  font-family: 'Inter';
  font-style: normal;
  font-weight: 600;
  font-size: 22px;
  line-height: 27px;
  color: #363636;
}

// Tables inside Css Fields Starts

.css-1e2bxag-MuiDataGrid-root .MuiDataGrid-row:not(.MuiDataGrid-row--dynamicHeight)>.MuiDataGrid-cell {
  overflow: visible !important;
}

.user-button {
  button#dropdown-basic-button {
    width: 30px;
    height: 30px;
    background: #fafafb;
    box-shadow: 0px 6px 58px rgba(196, 203, 214, 0.103611);
    border-radius: 8px;
    height: 30px;
    width: 30px;
    border: none;
    display: flex;
    align-items: center;
    cursor: pointer;

    &:focus {
      border: none;
    }
  }

  .dropdown-toggle::after {
    display: none;
  }
}

.table-status {
  /* Status */

  display: flex;
  flex-direction: row;
  justify-content: center;
  align-items: center;
  background: #e4f9e0;
  border-radius: 8px;
  width: 63px;
  height: 30px;
  font-family: "Inter";
  font-style: normal;
  font-weight: 700;
  font-size: 12px;
  line-height: 16px;
  text-align: center;
  color: #5fbf24;
}

// Tables inside Css Fields Ends

// User Management

//  Add User Modal Css


.content-management-modal {
  textarea {
    background: #ffffff;
    border: 1px solid #f5f1fe;
    border-radius: 20px;

    &:focus {
      box-shadow: none;
      border: 1px solid #3b7afe;
    }
  }

  input {
    width: 100%;
    height: 52px;
    background: #ffffff;
    border: 1px solid #f5f1fe;
    border-radius: 20px;

    &:focus {
      border: 1px solid #3b7afe !important;
    }
  }

  select.form-select {
    background: #ffffff;
    border: 1px solid #f5f1fe;
    border-radius: 20px;
    width: 100%;
    height: 52px;

    &:focus {
      border: 1px solid #3b7afe !important;
    }
  }

  label {
    font-family: "Inter";
    font-style: normal;
    font-weight: 400;
    font-size: 16px;
    line-height: 19px;
    color: #899ca8;
    margin-bottom: 7px !important;
  }

  input {
    font-family: "Inter";
    font-style: normal;
    font-weight: 400;
    font-size: 14px;
    line-height: 17px;
    color: #363636;
    margin-bottom: 18px;

    &:focus {
      box-shadow: none;
      outline: none;
      border: none;
    }
  }

  .form-select {
    font-family: "Inter";
    font-style: normal;
    font-weight: 400;
    font-size: 14px;
    line-height: 17px;
    color: #363636;
    margin-bottom: 18px;

    &:focus {
      box-shadow: none;
    }
  }

  p {
    /* Are you sure you want to deactivate these record? */
    font-family: "Inter";
    font-style: normal;
    font-weight: 500;
    font-size: 20px;
    line-height: 141.5%;
    /* or 28px */

    /* 3 */
    color: #899ca8;
  }

  .modal_cancel_btn {
    /* Cancel */
    font-family: "Inter";
    font-style: normal;
    font-weight: 500;
    font-size: 18px;
    line-height: 22px;
    text-align: center;
    color: #899ca8;
    background: none;
    border: none;
    margin-left: 22px;

    &:focus {
      box-shadow: none;
    }
  }

  .modal_submit_btn {
    background: #3b7afe;
    box-shadow: 0px 11px 20px rgba(59, 122, 254, 0.16);
    border-radius: 100px;
    width: 167px;
    height: 52px;

    // text of button
    /* Add */
    font-family: "Inter";
    font-style: normal;
    font-weight: 500;
    font-size: 18px;
    line-height: 22px;
    text-align: center;
    color: #ffffff;
    margin-top: 19px;

    // margin-bottom: 25px;
    &:hover {
      border: 1px solid #3b7afe;
      background: white;
      color: #3b7afe;
    }
  }
}


button#dropdown-nav-basic-button {
  background: none;
  padding: 0;
  margin: 0;
  border: none;
  height: 44px;

  .nav-ez-plan {
    .dropdown-menu.show {
      position: absolute;
      inset: 0px auto auto 0px;
      transform: translate3d(195px, 34px, 0px) !important;
    }

    .dashboard-sidebar ul li a.active ::after {
      right: -37px;
    }
  }
}

.has-children {
  .nav-child-list {
    background: #ffffff;
    border: 1px solid #f5f1fe;
    border-radius: 20px;
    position: absolute;
    top: 0;
    left: 100%;
    opacity: 0;
    visibility: visible;
    height: 307px;
    width: 213px;
    transition: 0.3s ease;
    z-index: 9;
    display: none;

    // styling of inside
    padding: 28px;
    font-family: "Inter";
    font-style: normal;
    font-weight: 400;
    font-size: 14px;
    line-height: 17px;
    color: #899ca8;

    .p_28 {
      padding-top: 28px;
    }

    &:after {
      content: normal !important;
    }

    .nav-child-item {
      &:after {
        content: normal;
      }
    }

    a:after {
      content: normal !important;
    }
  }
}

.has-children:hover {
  ul.nav-child-list {
    position: absolute;
    top: 0;
    left: 100%;
    background: white;
    opacity: 1;
    visibility: visible;
    display: block;
    transition: 0.6s ease-in;
  }
}