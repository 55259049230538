.thankyouPageLayout {
    display: flex;
    justify-content: center;
    align-items: center;
    height: 100vh;
    width: 100%;

    .thankyoubox {
        display: flex;
        flex-flow: column;
        align-items: center;
        justify-content: center;
    }

    .thankyouImage{
        margin-bottom: 40px;
    }

    .thankyouTitle {
        margin-bottom: 15px;

        h4 {
            font-weight: 700;
            font-size: 24px;
            line-height: 29px;
            text-align: center;
            color: #0A1629;
        }
    }

    .signupHere{
        p,h4{
            line-height: 1;
            
        }
    }

    .thankyouPara {
        margin-bottom: 30px;

        p {
            margin: 0;
            font-weight: 400;
            font-size: 14px;
            line-height: 17px;
            text-align: center;

            /* 4 */

            color: #363636;
        }
    }
}