@media (max-width: 1200px) {
  .ez-pay-dashboard {
    .earning-bg {
      h2 {
        font-size: 24px;
        margin-top: 20px;
      }

      p {
        font-size: 16px;
      }
    }
  }
  .donutchart-container {
    width: 50%;
    margin: auto;
  }

  .revenue-bg {
    margin-top: 32px;
  }

  .settings-body {
    .content-nav-bg {
      width: 200px !important;
    }

    .settings-bar {
      .settings-navigation {
        margin-top: 30px;
        ul {
          text-decoration: none;
          list-style: none;
          padding-left: 10px;
        }
      }
    }
  }
}

@media (max-width: 768px) {
  .LineGraph {
    button:not(:disabled) {
      font-size: 12px;
    }
  }

  .care-section {
    canvas {
      width: auto !important;
      height: auto !important;
      margin: auto;
      margin-top: 50px;
    }
  }

  .donutchart-container {
    width: 60%;
    margin: auto;
    margin-top: 20px;
  }

  .ez-pay-dashboard {
    .earning-bg {
      h2 {
        font-family: "Inter";
        font-style: normal;
        font-weight: 600;
        font-size: 15.2528px;
        line-height: 24px;
        color: #363636;
        margin-bottom: 0;
      }
      p {
        font-family: "Inter";
        font-style: normal;
        font-weight: 400;
        font-size: 12.2022px;
        line-height: 18px;
        color: #899ca8;
        margin-top: 12px;
        margin-bottom: 0;
      }
    }
  }
}

@media (max-width: 992px) {
  // .financing-card-bg{
  //     min-height: 600px;
  // }

  .settings-body {
    .settings-bar {
      height: auto;
      margin-bottom: 20px;
      padding-bottom: 20px;
      text-align: center;

      .content-nav-bg {
        width: 200px !important;
        text-align: left !important;
        padding-right: 7px;
      }
      ul {
        text-decoration: none;
        list-style: none;
        padding-left: 10px;

        li {
          display: inline-block !important;
          margin-left: 10px;
        }
      }
    }
  }
}

@media (max-width: 375px) {
    .content-management {
        padding: 20px!important;

        .user-button{
            padding-top: 10px!important;
        }

    h2 {
      font-size: 19px;
    }
  }
  .user-management {
    h2 {
      font-size: 32px;
      margin-bottom: 20px;
    }
    .add-new-btn {
      font-size: 16px;
      min-width: 120px;
      min-height: 42px;
      margin-bottom: 20px;
    }
  }

  .user-filter-btn {
    color: black;
    button#dropdown-basic-button {
      min-width: 120px;
      min-height: 42px;
    }
  }

  .eyehide-bg {
    background: #ffffff;
    box-shadow: 0px 6px 58px rgba(196, 203, 214, 0.103611);
    border-radius: 14px;
    width: 42px;
    height: 42px;
    border: none !important;
    padding: 0;
  }

  .payment-card-bg {
    background: #ffffff;
    border: 1px solid #f5f1fe;
    border-radius: 20px;
    margin: auto;
    margin-top: 20px;
    width: 100%;
    min-height: 360px;
  }

  .financing-card-bg {
    width: 100%;
    height: auto;

    h3 {
      font-size: 16px;
    }
    .mb_10 {
      margin-bottom: 10px;
    }
    .payment-source {
      font-size: 12px;
      margin-left: 6px;
    }
    .third-party {
      font-size: 12px;
    }
  }

  .ez-pay-dashboard {
    .revenue-bg {
      padding: 30px 10px 0 10px;
    }

    .plans-graph-container {
      padding: 30px 10px 0 10px;
    }
    .ezpay-table {
      .table-padding {
        padding: 0 10px;
      }
    }
  }

  .date-range-picker {
    button:not(:disabled) {
      font-size: 12px;
    }
  }
}

@media (max-width: 700px) {
  .settings-body {
    .settings-bar {
      height: auto;
      margin-bottom: 20px;
      padding-bottom: 20px;
      text-align: center;

      .content-nav-bg {
        width: 230px !important;
        text-align: left !important;
        padding-right: 7px;
      }
      ul {
        text-decoration: none;
        list-style: none;
        padding-left: 10px;

        li {
          display: block !important;
          margin-left: 10px;
        }
      }
    }
  }
}

@media (max-width: 425px){
    .dashboard-heading{
        font-size: 24px!important;
    }
}

@media (max-width:575px){
    .user-management {
        h2 {
          font-size: 32px;
          margin-bottom: 20px;
        }
        .add-new-btn {
          margin-bottom: 20px;
        }
      }

      .modal-content {
        min-width: 100%;
      }
}
