.greenTag {
    background: #E4F9E0;
    color: #5FBF24;
    border-color: #E4F9E0;

}

.yellowTag {
    background: #FFF6E8;
    color: #FF9900;
    border-color: #FFF6E8;
}

.ant-tag {
    padding: 5px 16px;
    border-radius: 8px;
    min-width: 80px;
    min-height: 30px;
    font-weight: 700;
    text-align: center;
}