.searchFoundWrapper {
    height: 555px;
    overflow: scroll;

    .SearchFoundList {
        .searchFounditem {
            border-bottom: 1px solid #CFCFCF;
            padding: 15px 0;

            .searhPageTitle {
                font-weight: 600;
                font-size: 16px;
                color: $theme-black;
                padding: 8px 0 12px 0;
                transition: 0.5s ease;
            }

            .searchPagePara {
                font-weight: 400;
                font-size: 12px;
                line-height: 16px;
                color: $text-grey;
            }

            a {
                position: relative;

                &:hover {
                    text-decoration: none;

                    .arrowRightSvg {
                        margin-right: 10px;
                    }

                    .searhPageTitle {
                        color: $theme-blue;
                    }
                }

                .arrowRightSvg {
                    position: absolute;
                    top: 50%;
                    right: 0;
                    transform: translate(0, -50%);
                    transition: 0.5s ease;
                    transition-property: margin;
                }
            }

            &:first-child {
                padding-top: 0;
            }

            &:last-child {
                border-bottom: 0;
            }

            p {
                margin: 0;
                padding: 0;
            }

            .breadcrumb {
                margin: 0;

                .breadcrumb-item {
                    color: $text-grey;
                    font-size: 14px;
                    padding-left: 3px;

                    &::before {
                        padding-right: 3px;
                    }
                }
            }
        }
    }

    .nosearchResult {
        height: 100%;
        display: flex;
        align-items: center;
    }
}

.searchOverlay {
    position: absolute;
    top: 0;
    left: 0;
    width: 100%;
    height: 100%;
    z-index: 11;
    background: rgba($color: #000000, $alpha: 0.2);
}