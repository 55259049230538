.patient-detail-box {
    .detail-heading {
        font-weight: 500;
        font-size: 14px;
        color: #363636;
    }
}


.patient-detail-list {
    .patient-item {
        margin-bottom: 15px;
        display: flex;
        align-items: center;

        &:last-child {
            margin-bottom: 0;
        }

        .patient-icon {
            margin-right: 10px;
        }

        .patient-item-value {
            color: #899CA8;
            font-weight: 400;
            font-size: 14px;
        }
    }
}

.square-edit-btn {
    position: absolute;
    top: 24px;
    right: 30px;
    transition: 0.5s ease;

    &:hover {
        cursor: pointer;
    }
}

.billingProgressWrapper {
    display: flex;
    justify-content: space-between;
    width: 60%;
    margin-bottom: 45px;

    .billingHeading {
        color: #899CA8;
        font-weight: 400;
        font-size: 16px;

        span {
            margin-top: 14px;
            font-size: 24px;
            color: #363636;
            display: block;
            font-weight: 600;
        }
    }
}

.ant-progress-text {
    font-weight: 500;
    font-size: 18px;
    color: #363636;
}

.ant-progress.ant-progress-show-info .ant-progress-outer {
    margin-inline-end: calc(-2em - 18px);
    padding-inline-end: calc(2em + 28px);
}

.billingProgressText {
    margin-bottom: 26px;

    p {
        font-weight: 400;
        font-size: 16px;
        color: #899CA8;
        margin: 0;

        span {
            color: #363636;
        }
    }
}