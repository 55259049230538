$greyColor: #899CA8;
$primaryColor: #3B7AFE;
$secondaryColor: #363636;
$theme-white: #ffffff;
$theme-black: #000;
$text-grey: #363636;
$theme-blue: #3B7AFE;
$baseFont: "Inter";
$light-grey: #F9FAFA;
$plane-grey: #E4E6E8;
$plane-black: #0A1629;
$black: #000;
$red-error: red;