h1 {
    font-size: 36px; //Main Heading
}

h2 {
    font-size: 22px;
    font-weight: 600;
}

h3 {
    font-size: 18px;
    font-weight: 600;
}

p {
    font-size: 14px;
}

