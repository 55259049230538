* {
    padding: 0;
    margin: 0;
    box-sizing: border-box;
}

body {
    background: #fbfbfd;
    font-family: "Inter";
    font-style: normal;
    font-size: 16px;
}

ul,
li {
    margin: 0;
    padding: 0;
    list-style: none;
}

a {
    text-decoration: none;
}

.cursor-pointer {
    &:hover {
        cursor: pointer;
    }
}

.ant-table-body {
    max-height: 709px !important;
}

.ant-table-body::-webkit-scrollbar {
    width: 4px;
    height: 4px;
    transition: 0.5s ease;
    display: block;
    /* width of the entire scrollbar */
}

.ant-table-body::-webkit-scrollbar-track {
    background: #f3f5f6;
    /* color of the tracking area */
    height: 1px;
    padding: 1px;
    width: 5px;
    border-radius: 20px;
}

.ant-table-body::-webkit-scrollbar-thumb {
    background-color: #899CAB;
    /* color of the scroll thumb */
    border-radius: 20px;
    /* roundness of the scroll thumb */
    border: 1px solid #899CAB;
    height: 2px;
    width: 10px;
    /* creates padding around scroll thumb */
}


/*
**********************************************
* Fullpage Layout
**********************************************
*/

.fullpageLayout {
    .searchBarContainer {
        width: 100%;


    }

    .fullpageWrapper {
        margin-top: 30px;
        background: #FFFFFF;
        box-shadow: 0px 6px 58px rgba(196, 203, 214, 0.103611);
        border-radius: 24px;
        height: 88%;
        padding: 30px;
    }
}

.fullpageWrapper {
    background: #FFFFFF;
    box-shadow: 0px 6px 58px rgba(196, 203, 214, 0.103611);
    border-radius: 24px;
    height: 88%;
    padding: 30px;
}

body::-webkit-scrollbar {
    // width: 5px;
    display: none;
}

body::-webkit-scrollbar-track {
    background: $greyColor;
}

body::-webkit-scrollbar-thumb {
    background: $secondaryColor;
}

body::-webkit-scrollbar-thumb:hover {
    background: $secondaryColor;
}

.btn-close {
    background-color: #f9fafa;
    border-radius: 14px;
    color: #121212;
    padding: 12px;
    transition: .5s ease;
}

::-webkit-scrollbar {
    // width: 5px;
    display: none;
}

.blue-para-list {
    display: flex;
    align-items: center;
    flex-wrap: wrap;

    li {
        p {
            color: #363636;
            font-size: 14px;
        }

        span {
            color: $primaryColor;
            font-weight: 600;
            font-size: 14px;
        }
    }
}

.pdf-download {
    display: flex;
    align-items: center;

    &:hover {
        cursor: pointer;
    }
}

.decoration-underline {
    text-decoration: underline;
}