.ant-tabs-nav {
    padding: 6px;
    border-radius: 20px;
    background: white;

    &:before {
        content: normal !important;
        ;
    }

    .ant-tabs-tab {
        display: flex;
        flex-direction: row;
        justify-content: center;
        align-items: center;
        padding: 0px 26px;
        gap: 10px;
        width: auto;
        height: 45px;
        background: white;
        border-radius: 20px;
        flex: none;
        order: 0;
        flex-grow: 0;
        color: #899CA8;

        &.ant-tabs-tab-active {
            background: #EBF1FF;
            font-weight: 600;
            color: $primaryColor;
        }
    }

    .ant-tabs-ink-bar {
        display: none;
    }
}

:where(.css-dev-only-do-not-override-ph9edi).ant-tabs .ant-tabs-tab-btn:focus:not(:focus-visible),
:where(.css-dev-only-do-not-override-ph9edi).ant-tabs .ant-tabs-tab-remove:focus:not(:focus-visible),
:where(.css-dev-only-do-not-override-ph9edi).ant-tabs .ant-tabs-tab-btn:active,
:where(.css-dev-only-do-not-override-ph9edi).ant-tabs .ant-tabs-tab-remove:active {
    color: $secondaryColor;
}

:where(.css-dev-only-do-not-override-ph9edi).ant-tabs .ant-tabs-tab-btn:focus:not(:focus-visible),
:where(.css-dev-only-do-not-override-ph9edi).ant-tabs .ant-tabs-tab-remove:focus:not(:focus-visible),
:where(.css-dev-only-do-not-override-ph9edi).ant-tabs .ant-tabs-tab-btn:active,
:where(.css-dev-only-do-not-override-ph9edi).ant-tabs .ant-tabs-tab-remove:active {
    color: $primaryColor ;
}