.dropdownboxed {
    background-color: white;
    border-radius: 12px;
    min-width: 166px;
    height: 52px;
    padding: 10px;
    display: flex;
    align-items: center;
    justify-content: space-between;
    border: 1px solid transparent;

    &:hover {
        border-color: $primaryColor;
        cursor: pointer;
    }

    &:focus,
    &:active,
    &:focus-visible {
        border-color: $primaryColor;
    }
}

.general-menu {
    width: 150px;
}