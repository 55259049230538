.Responsive_Navbar{
    .navbar{
        background-color: #FFFFFF!important;
        padding: 15px 0;




        .res-img{
            width: 48px;
            height: 48px;
        }
        .notification-box {
            background: #ffffff;
            border: 1px solid #f5f1fe;
            border-radius: 26px;
            width: 48px;
            height: 48px;
            cursor: pointer;
        
            svg {
              margin: 12px 12px 0 0;
            }
        
            &:hover {
              background-color: #3b7afe;
              transition: 0.5s;
        
              path {
                fill: white;
              }
            }
          }

        .navbar-toggler {
            text-decoration: none;
            background-color: white;
            border-radius: 10px;
            border: 1px solid #F5F1FE;
            
            &:focus{
                box-shadow: none;
                outline: none;
                border: none;
            }
        }

        
    }


}

.navbar-nav{ 
    a {
    text-decoration: none;
    font-family: "Inter";
    font-style: normal;
    font-weight: 400;
    font-size: 18px;
    line-height: 15px;
    color: #899ca8;
    cursor: pointer;
    padding-left: 10px;
    margin-top: 20px;

    path {
        fill: #899ca8; 
    }

        &:hover {
            color: #3b7afe;
            transition: 0.3s;

            path{
                fill: #3b7afe;
            }
        }

        &.active{
        
                background-color: #3B7AFE;
                padding: 10px 10px;
                border-radius: 12px;
                color:#F5F1FE;

                path{
                    fill:#F5F1FE;
                }
        }

        
    }


}


.responsive-hide{}



@media (max-width:1200px){
    .responsive-hide{
        display: none;
    }
}


