.menu-search-bar {
    display: flex;
    align-items: center;
    overflow: hidden;
    padding: 10px 14px;
    border: 1px solid #F5F1FE;
    border-radius: 14px;

    .ant-input {
        border-radius: 0;
        border: 0;

        &::placeholder {
            font-weight: 400;
            font-size: 16px;
            color: #7D8592;
        }

        &:focus,
        &:active {
            outline: none;
            box-shadow: none;
        }
    }
}

.search-menu {
    min-width: 370px !important;

    .ant-dropdown .ant-dropdown-menu .ant-dropdown-menu-item:hover,
    .ant-dropdown-menu-submenu .ant-dropdown-menu .ant-dropdown-menu-item:hover,
    .ant-dropdown .ant-dropdown-menu .ant-dropdown-menu-submenu-title:hover,
    .ant-dropdown-menu-submenu .ant-dropdown-menu .ant-dropdown-menu-submenu-title:hover,
    .ant-dropdown .ant-dropdown-menu .ant-dropdown-menu-item-active,
    .ant-dropdown-menu-submenu .ant-dropdown-menu .ant-dropdown-menu-item-active,
    .ant-dropdown .ant-dropdown-menu .ant-dropdown-menu-submenu-title-active,
    .ant-dropdown-menu-submenu .ant-dropdown-menu .ant-dropdown-menu-submenu-title-active {
        background-color: transparent !important;
    }

    .ant-dropdown-menu {
        .ant-dropdown-menu-item {
            &:first-child {
                margin-bottom: 24px;
            }

            margin-bottom: 14px;
        }
    }
}

.withSearchBar {
    .ant-dropdown-menu-item {


        &:hover {
            background-color: transparent !important;
        }

        &.ant-dropdown-menu-item-active {
            background-color: transparent;
        }
    }
}

.no-hover-effect {
    margin-bottom: 24px;
    background-color: white !important;
    ;

    &:hover {
        background-color: transparent !important;
    }
}


:where(.css-dev-only-do-not-override-ph9edi).ant-dropdown .ant-dropdown-menu .ant-dropdown-menu-item:focus-visible,
:where(.css-dev-only-do-not-override-ph9edi).ant-dropdown-menu-submenu .ant-dropdown-menu .ant-dropdown-menu-item:focus-visible,
:where(.css-dev-only-do-not-override-ph9edi).ant-dropdown .ant-dropdown-menu .ant-dropdown-menu-submenu-title:focus-visible,
:where(.css-dev-only-do-not-override-ph9edi).ant-dropdown-menu-submenu .ant-dropdown-menu .ant-dropdown-menu-submenu-title:focus-visible {
    outline: none
}