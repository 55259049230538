.ant-btn,
.btn-submit {
  min-width: 167px;
  min-height: 52px;
  background: #3b7afe;
  box-shadow: 0px 11px 20px rgba(59, 122, 254, 0.16);
  border-radius: 100px;
  font-weight: 500;
  font-size: 18px;
}

.filter-count {

  font-weight: 400;
  font-size: 14px;

  color: #899ca8;
}

.custom-mb-20 {
  margin-bottom: 20px;
}

.cancelBtn {
  width: auto;
  min-width: auto;
  background: transparent;
  min-height: auto;
  font-size: 18px;
  font-weight: 500;
  font-family: "Inter";
  text-align: center;
}

.dotted-btn {
  display: inline-flex;
  align-items: center;
  justify-content: center;
  padding: 0;
  background: #fafafb;
  // box-shadow: 0px 6px 58px rgba(196, 203, 214, 0.103611);
  border-radius: 8px;
  height: 30px;
  width: 30px;
  border: none;
  cursor: pointer;
  min-width: auto;
  min-height: auto;
  box-shadow: none;
}

.ant-dropdown-menu-title-content {
  &:hover {
    color: $primaryColor;
  }

  a {
    &:hover {
      color: $primaryColor;
    }
  }
}

.border-btn {
  border-color: $primaryColor;
  color: $primaryColor;
  font-weight: 600;
  transition: 0.5s ease;
  background-color: transparent;
  background: transparent;
  box-shadow: none;

  &:hover {
    background-color: $primaryColor;
    color: white !important;

    svg {
      path {
        fill: white;
      }
    }
  }
}

.ant-btn-sm {
  height: 32px;
  min-height: 32px;
  min-width: 133px;
}