.ant-table-tbody {
    padding: 0 !important;
}

thead.ant-table-thead .ant-table-cell {
    background: white !important;

    &::after,
    &::before {
        content: normal !important;
    }
}

.ant-table-tbody>tr[data-row-key]>td {
    padding: 10px !important;
    line-height: 1 !important;
    background: white !important;
}

.ant-table-thead>tr>th {
    padding: 24px 10px !important;
    background: white !important;
}

.ant-table-thead>tr>th>td {
    background: white !important;
}

:where(.css-dev-only-do-not-override-ph9edi).ant-table-wrapper .ant-table-thead>tr>th,
:where(.css-dev-only-do-not-override-ph9edi).ant-table-wrapper .ant-table-thead>tr>td {
    background: white !important;
}

:where(.css-dev-only-do-not-override-ph9edi).ant-table-wrapper .ant-table-thead>tr>th:not(:last-child):not(.ant-table-selection-column):not(.ant-table-row-expand-icon-cell):not([colspan])::before,
:where(.css-dev-only-do-not-override-ph9edi).ant-table-wrapper .ant-table-thead>tr>td:not(:last-child):not(.ant-table-selection-column):not(.ant-table-row-expand-icon-cell):not([colspan])::before {
    content: normal !important;
}

:where(.css-dev-only-do-not-override-ph9edi).ant-table-wrapper .ant-table-ping-right .ant-table-cell-fix-right-first::after,
:where(.css-dev-only-do-not-override-ph9edi).ant-table-wrapper .ant-table-ping-right .ant-table-cell-fix-right-last::after {
    content: normal !important;
}

:where(.css-dev-only-do-not-override-ph9edi).ant-table-wrapper .ant-table-ping-left .ant-table-cell-fix-left-first::after,
:where(.css-dev-only-do-not-override-ph9edi).ant-table-wrapper .ant-table-ping-left .ant-table-cell-fix-left-last::after {
    content: normal !important;
}

:where(.css-dev-only-do-not-override-ph9edi).ant-table-wrapper .ant-table-thead>tr>th,
:where(.css-dev-only-do-not-override-ph9edi).ant-table-wrapper .ant-table-thead>tr>td {
    font-weight: 400;
}

:where(.css-dev-only-do-not-override-ph9edi).ant-checkbox .ant-checkbox-inner {
    border: 2px solid #899CA8;
}

.ant-table-thead>tr>th::before {
    content: normal !important;
}

.ant-table-wrapper {
    background: white !important;
    padding: 0px 30px 24px 30px;
    border-radius: 20px;

    .ant-table-column-sorters {
        justify-content: flex-start;

        .ant-table-column-title {
            flex: unset;
        }
    }
}

.ant-table-tbody {
    .ant-table-cell {
        border: none !important;
    }
}



.ant-table-thead {
    .ant-table-column-title {
        color: #899CA8;
        font-weight: 400;
    }
}

.ant-table-thead>tr>th {
    color: #899CA8 !important;

}

.ant-table-thead>tr {
    border-bottom: 1px solid #d9d9d9 !important;
}

.no-data-icon {
    display: flex;
    align-items: center;
    justify-content: center;
    width: 100%;
    height: 100%;
    padding: 5em;
}


.membershipPlan {
    &.ant-table-wrapper {
        .ant-table-column-sorters {
            justify-content: center !important;
        }
    }
}