/** Notification Modal CSS **/
.notification-modal {
    .modal-header {
        padding: 37px 30px 34px 30px;
        border-bottom: 1px solid $plane-grey;

        @media (max-width: 767px) {
            padding: 20px;
        }
    }

    .modal-body {
        padding: 0;

    }

    .notication-card-container {
        height: calc(100vh - 220px);
        overflow: scroll;
    }
}


/** Notification Card **/
.notificationCard {
    padding: 15px 80px 14px 30px;
    border-bottom: 1px solid $plane-grey;
    transition: 0.5s ease;

    &:hover {
        background: $plane-grey;
        cursor: pointer;
    }

    @media (max-width: 767px) {
        padding: 10px;
    }

    &:last-child {
        border-bottom: 0;
    }

    .notification-img {
        width: 50px;
        height: 50px;
        border-radius: 100%;
        overflow: hidden;
        margin-right: 18px;

        img {
            width: 100%;
            height: auto;
            object-fit: cover;
        }
    }

    p {
        color: $plane-black;
        font-weight: 400;
        font-size: 14px;
        line-height: 24px;
        margin: 0;
    }

    small {
        font-weight: 600;
        font-size: 14px;
        color: $text-grey;
    }
}

.noDetailFoundBox {
    display: flex;
    flex-flow: column;
    justify-content: center;
    align-items: center;
    height: 100%;
    width: 100%;

    .noDetailFoundImage {
        width: 250px;

        img {
            width: 100%;
            height: auto;
        }
    }
}

/**************************************
* Responsive
****************************************/
@media (min-width: 576px) {
    .modal-dialog {
        max-width: 437px
    }
}